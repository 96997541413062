import { Avatar, Popover } from 'antd';
import React from 'react';

const EntityChip = (props: { entityType: string | undefined , onClick: any}) => {
  const { entityType, onClick } = props;
  return <div style={{
    cursor: 'pointer',
    borderRadius: 8,
    marginLeft: 8
  }}
  onClick={onClick}
  >   
  <Popover content={entityType} style={{margin:0}} placement="right">
  <Avatar size={'small'} src={`/images/${entityType}.png`} />

  </Popover>
  </div>;
};

export default EntityChip;