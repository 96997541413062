import React from "react";

const Intro = () => {
  return  <><iframe 
      src="https://player.vimeo.com/video/855948269?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
      width="480" 
      height="300" 
      frameBorder='0' 
      allow="autoplay; fullscreen; picture-in-picture" 
      title="vellby_intro">  
    </iframe>
  </>
};

export default Intro;