import React from "react";
import {Modal, Button, InputNumber, Space, Select, message} from "antd";
import EntitySummary from "../EntitySummary/EntitySummary";
import useGetEntityConnections from "../../hooks/getConnections/useGetEntityConnections";
import addNewDoc from "../../hooks/firestoreFns/addNewDoc";
import { collection } from "firebase/firestore";
import { useAppState } from "../../state";

const { Option } = Select;

const SupportAsYourContextModal = (props:{
    entityID: string
}) => {
    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
      messageApi.open({
        type: 'success',
        content: 'successfully added as your context as a supporter',
      });
    };

    const { db, userEntity} = useAppState();
    const [visible, setVisible] = React.useState(false);
    const [sourceEntity, setSourceEntity] = React.useState<string>();
    const [percentage, setPercentage] = React.useState<number|null>(0);

    const firstDegreeAdminContexts = useGetEntityConnections(userEntity?.id, {
        entityTypes: ['context'],
        adminOnly: true,
        removePending: true,
        removeHidden: false
    });

    const handleChange = (value: string) => {
        setSourceEntity(value);

    }
    return <React.Fragment>
              {contextHolder}

        <Modal open={
            visible
        } onCancel={
            () => setVisible(false)
        }
        closable={false} footer={[]}
        >
            <h4>Pick one of your support enabled entities. </h4>
    <Space>
    
    <Select
        value={sourceEntity}
        style={{ width: 320, margin: 8 }}
        onChange={handleChange} >
            {
                firstDegreeAdminContexts?.map((context:any) => {
                    console.log(context);
                    return <Option  value={context.otherEntityID}> <EntitySummary onlyName entityID={context.otherEntityID} onClick={() => {}}/></Option>
                })
            }
      </Select>    
      <InputNumber
            style={{ width: 80, margin: 8 }}
            min={0}
            max={100}
            value={percentage}
            onChange={(value) => setPercentage(value)}
       formatter={(value) => `${value}%`}/> 
        </Space>
        <br/>
        <Button style={{marginTop: 32}}
            type='default' size="small" 
            onClick={
                () => {
                    if (sourceEntity && percentage){
                        addNewDoc(
                            collection(db, 'support', sourceEntity ,'payment'), 
                            {
                                targetEntity: props.entityID,
                                percentage: percentage
                            })
                        success();
                    }
                    setVisible(false);
    
                }
             
            }>
                Sponsor as your context
        </Button>

        </Modal>
        <Button size='small' type='text' onClick={
   () => setVisible(true)
        } >
            as your context
        </Button>
    </React.Fragment>
}

export default SupportAsYourContextModal;
