import React, { useEffect } from "react";
import { Typography } from "antd";

const { Text } = Typography;

const YellowMessage = (props: { text: string }) => {
    const { text } = props;

    const [showElement, setShowElement] = React.useState(false);
    useEffect(() => {
      setShowElement(true);
      setTimeout(function () {
        setShowElement(false);
      }, 8000);
    }, [text]);

    if (!showElement) {
        return <Text mark style={{
            marginLeft: 8,
            marginRight: 8,
        }}></Text>
    }
    return  <Text mark style={{
        marginLeft: 8,
        marginRight: 8,
    }}>{text}</Text>
}

export default YellowMessage;