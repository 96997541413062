import React from 'react';
import NavGlobal from '../NavGlobal/NavGlobal';
import PendingConnectionList from '../PendingConnections/PendingConnectionList';
import Footer from '../NavGlobal/Footer';
import DynamicCarousel from '../DynamicCarousel/DynamicCarousel';

const LandingPage = () => {
  return ( <React.Fragment>    
    <NavGlobal />
      <div style={{ marginLeft: 32, marginRight: 32, minHeight: 450 }}>
        <DynamicCarousel />
        <PendingConnectionList />
      </div>
      <Footer/>
      </React.Fragment>
  );
};

export default LandingPage;