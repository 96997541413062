import React from 'react';
import useGetEntityConnections from '../../hooks/getConnections/useGetEntityConnections';
import useGetEntityPendingConnections from '../../hooks/getConnections/useGetEntityPendingConnections';
import { useAppState } from '../../state';
import { List, Button, Modal, Typography } from 'antd';
import EntitySummary from '../EntitySummary/EntitySummary';
import { EntityType, FilterConnection } from '../../types';
import useGetEntityDetails from '../../hooks/getDetails/useGetEntityDetails';
import { RightOutlined } from '@ant-design/icons';
import TieTogetherButton from '../TieButtons/TieTogetherButton';
import YellowMessage from '../Info/YellowMessage';

const { Text } = Typography;

const ExistingEntityListItem = (props: {
    connectionID: string;
    sourceID: string | undefined;
    sourceType: EntityType | undefined;
    connected: boolean;
    pending: boolean;
    entityID: string;
    onClick: any;
}) => {

    const { connectionID, sourceID, sourceType, connected, pending, entityID, onClick } = props;
    console.log(connectionID);
    const entityDetails = useGetEntityDetails(entityID);

    const relCondition = !(entityDetails?.entityType === 'relationship' && sourceType === 'relationship');
    const personThingCondition = !(( entityDetails?.entityType === 'person' || entityDetails?.entityType === 'thing')  && ( sourceType === 'person' || sourceType === 'thing' ));
    const sourceNotTheSame = sourceID !== entityID;

    const allowedConnection = relCondition && personThingCondition && sourceNotTheSame;

    if (!allowedConnection) {
        return <></>
    }

    if (connected) {

        return <List.Item 
                    key={entityID}
                    actions={[
                        <Text strong italic> already connected. </Text>
                    ]}
                    
                    style={{
                        borderRadius: 4, 
                        margin: 4}}>
                        <EntitySummary 
                            entityID={entityID} 
                            onClick={
                                onClick
                            } 
                        />
        </List.Item>
    }

    if (pending) {

        return <List.Item 
            key={entityID}
            actions={[
                <Text strong italic>pending.</Text>

            ]}
            style={{
                borderRadius: 4, 
                margin: 4}}>

                <EntitySummary 
                    entityID={entityID} 
                    onClick={ onClick} 
                />

        </List.Item>
}


    return <List.Item
        key={entityID}
        actions={
            [ <TieTogetherButton sourceID={sourceID} targetID={entityID} />]}
        >
    <EntitySummary  entityID={entityID} onClick={ onClick} />
</List.Item>

}


const AddYourExistingEntity = (props: {
    sourceID: string | undefined;
    sourceType: EntityType | undefined;
}) => {

    const { sourceID, sourceType } = props;
    const { userEntity } = useAppState();

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [ secondaryEntity, setSecondaryEntity ] = React.useState<string | undefined>(undefined);
    
    const focusEntity = secondaryEntity !== undefined ? secondaryEntity : userEntity?.id;
    const connections = useGetEntityConnections( focusEntity );
    const pendingSourceConnections = useGetEntityPendingConnections(sourceID);
    const filter : FilterConnection = {
        entityTypes: [],
        adminOnly: false,
        removePending: true,
        removeHidden: true
    }
    const sourceConnections = useGetEntityConnections(sourceID, filter);
    const stateOfConnection = connections?.map((connection) => {

        const pendingConnectionFind = pendingSourceConnections?.find((pendingConnection) => {
            return pendingConnection.otherEntityID === connection.otherEntityID;
        });
        const sourceConnectionFind = sourceConnections?.find((sourceConnection) => {
            return sourceConnection.otherEntityID === connection.otherEntityID;
        });


        return {
            ...connection,
            pending: pendingConnectionFind !== undefined,
            connected: sourceConnectionFind !== undefined,
        };
    });

    if (sourceID === undefined) {
        return <></>
    }

    return <div>
        <Modal 
            open={isModalOpen}
            onCancel={() => {
                    setIsModalOpen(false);
                    setSecondaryEntity(undefined);
                }
            }
            width={"70%"}
            footer={[<Button onClick={() => setIsModalOpen(false)}> close </Button>]}
            title={"Add compatible entities from"}
        >
            {
                /*
                    (sourceType === 'relationship') ? "Note you cannot add relationships here." : ""
                */
             }
             <div style={{ marginLeft: 32, display: 'flex', flexDirection: 'row'}}>
                <EntitySummary entityID={userEntity?.id} onClick={() => setSecondaryEntity(undefined) } lean={true}/> 
               {  secondaryEntity &&  <><RightOutlined style={{ margin: 8, marginTop: 16 }}/>
                <EntitySummary entityID={secondaryEntity} lean={true}/> 
                </>} 
                </div>
                <div style={{
                    marginLeft: 32
                }}>
                {
                    secondaryEntity 
                    ? <YellowMessage text={'you are adding second degree ties'}/> 
                    : <YellowMessage text={'you can also click on a entity below to add second degree ties.'}/> 
                }
                </div>
  
            <List
                pagination={{
                    onChange: (page) => {
                        console.log(page);
                      },
                    pageSize: 3,
                    position: 'top',
                }}
                itemLayout="horizontal"
            >
                { stateOfConnection?.map(
                    (connection: { id:string, connected: boolean, otherEntityID: string, pending: boolean }) => {
                        return <ExistingEntityListItem 
                                    connectionID={connection.id}
                                    key={connection.otherEntityID}
                                    entityID={connection.otherEntityID}
                                    sourceID={sourceID}
                                    sourceType={sourceType}
                                    connected={connection.connected}
                                    pending={connection.pending}
                                    onClick={ () =>{
                                         
                                        if( secondaryEntity === undefined ){
                                            return setSecondaryEntity(connection.otherEntityID)
                                        }
                                        return () => console.log("do nothing");
                                    }
                                }
                                />
                        })
                } 
            </List>
        </Modal>
        <Button
            type={'text'}
           onClick={() => setIsModalOpen(true)}
        > from entities tied to you 
        </Button>

    </div>;
};

export default AddYourExistingEntity;