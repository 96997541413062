import React from "react";
import { Modal } from 'antd';
import SearchEntity from "./SearchEntity";

const SearchModal = (props: {open: boolean, onClose: any}) => {
    const {open, onClose} = props;
    const [searchOpen, setSearchOpen] = React.useState(false);
    React.useEffect(() => {
        setSearchOpen(open);
    }, [open]);

    return <Modal open={searchOpen} destroyOnClose={true} onCancel={onClose} closable={false} width={'80%'} footer={null}>
        <SearchEntity setSearchOpen={setSearchOpen}/>
    </Modal>;
}

export default SearchModal;