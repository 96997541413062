import React from "react";
import { useAppState } from "../../state";
import { Card, Typography } from "antd";
import EntitySummary from "../EntitySummary/EntitySummary";
import { DirectedConnectionInterface } from "../../types";
import AcceptButton from "../TieButtons/AcceptButton";
import SeparateButton from "../TieButtons/SeparateButton";

const PendingConnection = (props: {
    connection: DirectedConnectionInterface;
    yourID: string | undefined;
    onBehalfOf?: boolean;
}) => {
    
    const { connection, yourID, onBehalfOf } = props;
    const { userEntity } = useAppState();
    const userID = userEntity?.id;
    //@ts-ignore
    const sourcePending = !connection[yourID];
    const { Text } = Typography;

   
    let Buttons: any[] = [];
    if (sourcePending && userID) {
        Buttons.push(<AcceptButton connectionID={connection?.id} yourID={userID}/>);
        Buttons.push(<SeparateButton connectionID={connection?.id} yourID={userID} otherID={connection.otherEntityID}/>);
    } 
   
    return <React.Fragment>  
        <Card style={{
            width: 400,
            margin: 4
        }} 
        bordered={false}
        actions={Buttons}
        >

        {/* connection?.id */}
        {   <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
        }}> 
        {
            connection.creatorID && <EntitySummary lean entityID={connection.creatorID}  onlyName/>
        }
        {
            connection.creatorID && <Text italic type={"secondary"}> created a request </Text>
        }
        { onBehalfOf && <> <Text italic type={"secondary"} style={{
            marginLeft: 2
        }}> on behalf of </Text> <EntitySummary entityID={connection.createdOnBehalfID} onlyName/> </>} 
        <Text italic type={"secondary"}> to connect with </Text>
        <EntitySummary entityID={connection.otherEntityID} onlyName /> 

        </div>}
        <EntitySummary entityID={connection.otherEntityID} lean /> 

        {!sourcePending && <><Text italic type={"secondary"}> pending acceptance. </Text> 

        <SeparateButton 
        connectionID={connection?.id}  
        yourID={connection?.createdOnBehalfID}
        otherID={connection.otherEntityID}
        /></>}
        
      </Card>
      </React.Fragment>;
    };

export default PendingConnection;
