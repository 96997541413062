import React, { useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useAppState } from "../../state";
import { EntityInterface } from "../../types";

const useSearchEntity = (qry : string|undefined) => {

    const [result, setResult] = React.useState<EntityInterface[]|undefined>()
    const { db } = useAppState();

    useEffect(() => {

        const searchKeywordsRef = (qStr: string) => {
            const q = qStr.toLowerCase();
            let q_arr = q.split(' ');
            q_arr = q_arr.filter((word) => word.length > 2);
            if (q_arr.length > 0) {
                return query(collection(db, "entities"), where("searchKeywords", "array-contains-any", q_arr));
            }
            return null;
        }

        const searchEntity = async (qRef: any) => {

            const qSnapshot = await  getDocs(qRef);
            let results: EntityInterface[] = [];
            console.log('qsnapshot', qSnapshot);
            qSnapshot.forEach((doc: any) => {

                let rslt: EntityInterface = {
                    id: doc.id,
                    name: doc.data()?.name,
                    entityType: doc.data()?.entityType,
                    description: doc.data()?.description,
                    photoURL: doc.data()?.photoURL,
                    searchKeywords: doc.data()?.searchKeywords,
                }
                results.push(rslt);
            })
            return results;
        }

        if (qry) {

            const queryRef = searchKeywordsRef(qry)
            if (!queryRef) {
                setResult([]);
                return;
            }
            searchEntity(queryRef).then((results) => {
                setResult(results);
            }).catch((error) => {
                console.error('error in useSearchEntity:', error);
            });
            
        } else {
            console.log("no query")
            setResult([]);
        }

    }
    , [qry, db]);

    return result;

}


export default useSearchEntity;