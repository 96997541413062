import React from 'react';
import { query, collection, where, getDocs } from 'firebase/firestore';
import useGetEntityConnectionsSecondDegree from '../getConnections/useGetEntityConnectionsSecondDegree';
import useGetEntityConnections from '../getConnections/useGetEntityConnections';
import useGetEntityDetails from './useGetEntityDetails';


export const getIsAdmin = async (db: any, sourceID: string| undefined, targetID: string | undefined) => {
    if (sourceID === undefined || targetID === undefined) {
        return false;
    }
    if (sourceID === targetID) {
        return true;
    }
    const connectionRef = collection(db, 'connections');
    const q = query(connectionRef , where(sourceID, "==", true), where(targetID, '==', true), where("admin", "==", true));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size === 1) {
        return true;
    } 
    return false;
}

const useGetIsAdmin = (sourceID: any, targetID: any, secondDegree? : boolean) => {
    
    // const {db} = useAppState();
    const [isAdmin, setIsAdmin] = React.useState<boolean>();

    const sourceDetail = useGetEntityDetails(sourceID);
    const targetDetail = useGetEntityDetails(targetID);

    const firstDegreeConnections =  useGetEntityConnections(sourceID, {
        entityTypes: [],
        adminOnly: true,
        removePending: true,
        removeHidden: false,
    }); 

    // console.log("firstDegreeConnections", firstDegreeConnections)
    //return false;



    
    const secondDegreeConnections = useGetEntityConnectionsSecondDegree(sourceID, true);
    // console.log("secondDegreeConnections", secondDegreeConnections);
    React.useEffect(() => {
        if (sourceID && targetID) {

            if (sourceDetail?.entityType === 'person' && targetDetail?.entityType === 'person' && sourceID !== targetID) {
                setIsAdmin(false);
            } else {
                // console.log("second degree", secondDegree, secondDegreeConnections, "first degree", firstDegreeConnections, "source", sourceID, "target", targetID, "")
                let connections = secondDegree ? secondDegreeConnections.concat( firstDegreeConnections) : firstDegreeConnections
                let check =  sourceID === targetID ;
                connections.forEach((connection: any) => {
                    if ( connection.entityIDs.includes(targetID) ) {
                        check = true;
                        // console.log("xxx",sourceID, targetID, check, connection, connection.id);
                    } 
                });

                setIsAdmin(check);
            }
        }
    }
    , [ 
        sourceID, 
        targetID, 
        sourceDetail?.entityType,
        targetDetail?.entityType,
        firstDegreeConnections.length, 
        secondDegreeConnections.length,
        firstDegreeConnections, 
        secondDegree, 
        secondDegreeConnections
    ]);

    return isAdmin; 
}


export default useGetIsAdmin;

/*
        sourceID, 
        targetID, 
        db, 
        sourceDetail?.entityType,
        targetDetail?.entityType,
        firstDegreeConnections.length, 
        secondDegreeConnections.length,
        firstDegreeConnections, 
        secondDegree, 
        secondDegreeConnections
*/