import React, {useState} from "react";
import { useAppState } from "../../state";
import { acceptConnection } from '../../hooks/buildFns/acceptConnection';
import { getIsAdmin } from "../../hooks/getDetails/useGetIsAdmin";
import { doc } from "firebase/firestore";
import ModalConfirm from "./ModalConfirm";
import useGetConnectionDetailsFromId from "../../hooks/getDetails/useGetConnectionDetailsFromId";
import removeStringFromArrayField from "../../hooks/firestoreFns/removeStringFromArrayField";
import addStringToArrayField from "../../hooks/firestoreFns/addStringToArrayField";

const AcceptButton = (props: {
    connectionID: string,
    yourID: string ,
}) => {
    const {connectionID, yourID} = props;
    const { db, userEntity } = useAppState();

    const userID = userEntity?.id;
    const connObj = useGetConnectionDetailsFromId(connectionID)
    const [isAdmin , setIsAdmin ] = useState<Boolean>(yourID === userEntity?.id);    

    if (connObj === undefined) {
        return <></>
    }
    if (connObj[yourID] === true) {
        return <></>
    }

    if (yourID !== userEntity?.id) {
        getIsAdmin(db, userEntity?.id, yourID).then((value) => {
            setIsAdmin(value);
        })
    }

    if (!isAdmin) {
        return <></>
    }


    return <ModalConfirm 
        key={connectionID}
        ButtonContent={`accept`} 
        ModalContent={"You are about to accept this connection."} 
        onConfirm={() => {
            acceptConnection(db, connectionID, yourID )

            if (userID ) {
            let docRef = doc(db, 'userState', userID);
            removeStringFromArrayField(docRef, 'onboardedSteps', 'request');
            addStringToArrayField(docRef, 'onboardedSteps', 'request');

        } }
        } 
    />

}

export default AcceptButton;