import React from "react";
import useGetEntityDetails from "../../hooks/getDetails/useGetEntityDetails";
import { Modal, Button, Typography, Space } from "antd";
import EntitySummary from "./EntitySummary";
import { useNavigate } from "react-router-dom"
import { AppstoreAddOutlined, LoginOutlined } from "@ant-design/icons";
import ButtonGroup from "antd/es/button/button-group";

const ModalEntitySummary = (props: {
    entityID: string| undefined,
    onExpand: () => void,
    setMsg: (msg: string) => void,
}) => {

    const { entityID, setMsg, onExpand } = props;
    const { Text } = Typography;
    const entityDetails = useGetEntityDetails(entityID);

    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = React.useState(false);

    if (!entityID) {
        return <></>
    }
    return <React.Fragment>
    <Modal 
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        closable={false}
        footer={null}
    >
        <Space style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
        }}>
        <Text > in order to center on </Text>
        <EntitySummary onlyName entityID={entityID} />
        <Text >  you will be navigate away. </Text>
        </Space>
        <Space style={{
            marginTop: 32,
        }}>
        <Button size="small" type='primary' onClick={
            () => {
                navigate(`/entity/${entityID}`);
                // window.open( `${window.location.host}/entity/${entityID}`);
                setModalVisible(false);
            }} > understood, change center </Button> 
        
        <Button size="small" type='default' onClick={
            () => {
                navigate(`/entity/${entityID}/list`);
                // window.open( `${window.location.host}/entity/${entityID}`);
                setModalVisible(false);
            }} >  view new center as a list </Button> 

            
        { entityDetails?.webLink ? <Button size="small" type='primary' onClick={
            () => {
                entityDetails?.webLink && window.open(entityDetails?.webLink, '_blank');
                setModalVisible(false);
            }
        }> Go to Link</Button> : <> </> }

        </Space>

    </Modal>
    {entityID && <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginRight: 8
    }}>
        <EntitySummary entityID={entityID} lean onClick={() => {
            let current_href = window.location.href 
            if (!current_href.includes(entityID)){
                setModalVisible(true);
                setMsg('');
            } else {
                setMsg(`already centered on ${entityDetails?.name}`);
            }
             
            }
        }/> 
        <ButtonGroup 
            size="small"
            style={{
            marginLeft: 8
        }}>
        <Button size="small" icon={<AppstoreAddOutlined/>} onClick={onExpand}> expand </Button>
        <Button size="small" icon={<LoginOutlined/>} 
     onClick={() => {
            let current_href = window.location.href 
            if (!current_href.includes(entityID)){
                setModalVisible(true);
                setMsg('');
            } else {
                setMsg(`already centered on ${entityDetails?.name}`);
            }
             
        }}
       >
        center on
        </Button>
        </ButtonGroup>
    </div>
    }
</React.Fragment>


}

export default ModalEntitySummary;