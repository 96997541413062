import React, { useEffect } from "react";
import { useAppState } from "../../state";
import useGetConnectionDetails from "../../hooks/getDetails/useGetConnectionDetails";
import useGetEntityDetails from "../../hooks/getDetails/useGetEntityDetails";
import SeparateButton from "../TieButtons/SeparateButton";
import AcceptButton from "../TieButtons/AcceptButton";
import TieTogetherButton from "../TieButtons/TieTogetherButton";
import useGetIsAdmin from "../../hooks/getDetails/useGetIsAdmin";

const AddYourself = (props: { entityID: string}) => {

    const { entityID } = props;
    const entityDetails = useGetEntityDetails(entityID);
    const { userEntity } = useAppState();
    const connectionDetails = useGetConnectionDetails(entityID, userEntity?.id);
    const [ connectionID, setConnectionID] = React.useState<string | undefined>(undefined);
    const [ targetPending, setTargetPending] = React.useState<boolean | undefined>(undefined);
    const isAdmin = useGetIsAdmin(entityID, userEntity?.id, true);

    useEffect(() => {

        // console.log("connectionDetails", connectionDetails)
        setConnectionID(connectionDetails?.id)
        if (connectionDetails?.id) {
            //@ts-ignore
            setTargetPending(connectionDetails?.[userEntity?.id] === false)
        }
    }, [connectionDetails?.id, entityID, userEntity?.id, connectionDetails])

    const linkable = (
        userEntity?.id !== entityID && 
        entityDetails?.entityType !== undefined &&
        ['context', 'relationship'].includes(entityDetails?.entityType) 
    )

    if (!linkable) return <></>;
    if (connectionID === undefined ){

            return  userEntity?.id  ?  <TieTogetherButton 
            sourceID={entityID} 
            targetID={userEntity?.id} 
            text={ isAdmin ? "tie yourself as a 1° admin": "tie yourself together" }/>  : <></>
     } 

     
    if (targetPending) {
      // If there is a connection pending, and the user is logged in, show the AcceptButton
      return (connectionID &&  userEntity?.id) ? <AcceptButton connectionID={connectionID} yourID={userEntity?.id} /> : <></>
    }
    
    return <SeparateButton
        connectionID={connectionID}
        yourID={userEntity?.id}
        otherID={entityID}
    />

}

export default AddYourself;