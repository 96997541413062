import { 
    collection, 
    addDoc, 
    getDoc, 
    query, 
    doc, 
    getDocs, 
    orderBy,
    deleteDoc
} from "firebase/firestore";
import {createOtherID} from '../getConnections/useGetEntityConnections';
import validateEmail from "../utils/validateEmail";
import getEmailDoc from "../getDetails/getEmailDetails";
//import unlink from "../buildFns/deleteConnection";
//import addConnection from "../buildFns/addConnection";
//import createEntity from "../buildFns/createEntity";

import addNewDoc from "../firestoreFns/addNewDoc";
import updateDoc from "../firestoreFns/updateDoc";

export const setTempEntityID = async (db: any, email: string, tempEntityID: string, selfInvited? : boolean ) => {
    console.log(db, email, tempEntityID);
    if (!validateEmail(email)) {
        return;
    }
    const emailRef = collection(db, "emails");

    let newDoc: any = {
        email,
        tempEntityID
    }

    if (selfInvited){
        newDoc['selfInvited'] = true;
    }

    await addNewDoc(emailRef, newDoc);

}

export const setPermanentEntityID = async (db: any, email:string, permanentEntityID: string) => {

    const emailDoc = await getEmailDoc(db, email);

    if (emailDoc === undefined) {
        return null;
    }

    const emailRef = doc(db, "emails", emailDoc.id);
    await updateDoc(emailRef, {permanentEntityID});


}

export const duplicateEntity = async (db: any, tempEntityID: string, permanentEntityID: string) => {
    const entityRef = collection(db, "entities");
    const entityDoc = await getDoc(doc(entityRef, tempEntityID));
    const entityData = entityDoc.data();

    await updateDoc(doc(entityRef, permanentEntityID), entityData);
}

export const duplicateConnections = async (db: any, tempEntityID: string, permanentEntityID: string) => {
    console.log('duplicate connections', tempEntityID, permanentEntityID);
    const connectionsRef = collection(db, "connections");
    const connectionsQuery = query(connectionsRef, orderBy(tempEntityID));

    const connectionsQuerySnapshot = await getDocs(connectionsQuery);
    const connectionsDocs = connectionsQuerySnapshot.docs;

    connectionsDocs.forEach(async (docu) => {
        let data = docu.data();
        console.log("docu", data);

        let otherID = createOtherID( data['entityIDs'], tempEntityID);
        console.log(otherID);

        let has_temp_accepted = data[tempEntityID];
        let has_other_accepted = data[otherID];

        let new_doc_ref = await addDoc(connectionsRef, {
            [permanentEntityID] : has_temp_accepted,
            [otherID]: has_other_accepted,
            creatorID : data['creatorID'],
            entityIDs : [permanentEntityID, otherID],

        }).catch(err => {
            console.log(err);
        })

        console.log(new_doc_ref);

    });
}

export const deleteConnections = async (db: any, tempEntityID: string) => {
    const connectionsRef = collection(db, "connections");
    const connectionsQuery = query(connectionsRef, orderBy(tempEntityID));

    const connectionsQuerySnapshot = await getDocs(connectionsQuery);
    const connectionsDocs = connectionsQuerySnapshot.docs;

    connectionsDocs.forEach(async (docu) => {
        let data = docu.data();
        console.log("docu", data);

        let otherID = createOtherID( data['entityIDs'], tempEntityID);
        console.log(otherID);
        await deleteDoc(doc(connectionsRef, docu.id));
    });
}
