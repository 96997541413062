import { EntityType } from "../../types";

export const getAllowedEntityTypes = (sourceEntityType: EntityType | undefined) => {

    if (sourceEntityType === 'context') {
        return [
            { 
                label: 'context',
                value: 'context',

             },
             { 
                label: 'relationship',
                value: 'relationship',
                
             },
             { 
                label: 'person',
                value: 'person',
                
             },
             { 
                label: 'thing',
                value: 'thing',
                
             }];
    }
    if (sourceEntityType === 'relationship') {
        return [
            { 
                label: 'context',
                value: 'context',

             },
             { 
                label: 'relationship',
                value: 'relationship',
                disabled: true
                
             },
             { 
                label: 'person',
                value: 'person',
                
             },
             { 
                label: 'thing',
                value: 'thing',
                
             }]
    }
    if (sourceEntityType === 'person') {
       return [
        { 
            label: 'context',
            value: 'context',

         },
         { 
            label: 'relationship',
            value: 'relationship',
            
         },
         { 
            label: 'person',
            value: 'person',
            disabled: true
            
         },
         { 
            label: 'thing',
            value: 'thing',
            disabled: true
         }]
    }
    if (sourceEntityType === 'thing') {
        return [
            { 
                label: 'context',
                value: 'context',

             },
             { 
                label: 'relationship',
                value: 'relationship',
                
             },
             { 
                label: 'person',
                value: 'person',
                disabled: true
                
             },
             { 
                label: 'thing',
                value: 'thing',
                disabled: true
             }]
    }
      return [ ]
    
}

/*
export const linkable = async (db: any, sourceID: string, targetID: string) => {
    console.log('linkable', sourceID, targetID);
    if (sourceID === undefined || targetID === undefined) {
        return false;
    }

    if (sourceID === targetID) {
        return false;
    }

    const sourceDetails = await getEntityDetails(db, sourceID);
    const targetDetails = await getEntityDetails(db, targetID);
    
}

*/