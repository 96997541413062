import getEntityIDfromEmail from "../getDetails/getEntityIDfromEmail";
import { getEntityConnections } from "../getConnections/useGetEntityConnections";
import { DirectedConnectionInterface } from "../../types";

// check firestore for documents with input email as field in connections collection
// then fetch that document.

const emailHasBeenInvitedByExistingUser = async (
    db: any,
    email: string | undefined
) => {

    const entityID = await getEntityIDfromEmail(db, email);
    let emailHasBeenInvited = false;

    if (entityID) {
        const connections = await getEntityConnections(db, entityID);
        connections.forEach(
            (connection: DirectedConnectionInterface) => {
                console.log("connection data", connection);

                let otherID = connection.otherEntityID;

                //@ts-ignore
                if (connection[otherID] === true) {
                    emailHasBeenInvited = true;
                }
            }
        )
    }

    return emailHasBeenInvited;
}

export default emailHasBeenInvitedByExistingUser;

