import React from 'react';
import NavGlobal from '../../components/NavGlobal/NavGlobal';
import { useParams } from 'react-router-dom';
import {  Layout, theme, List,Card } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import NewChat from './NewChat';
import ConnectionList from './ConnectionList';
import PreviousChats from './PreviousChats';
import Footer from '../NavGlobal/Footer';
import EntitySummary from '../EntitySummary/EntitySummary';
import useGetConnectionDetailsFromId from '../../hooks/getDetails/useGetConnectionDetailsFromId';

const ChatPage = () => {
  const { token: { colorBgContainer}}= theme.useToken();
  const { connID } = useParams();
  const connectionDetails = useGetConnectionDetailsFromId(connID );

  console.log("connectionDetails", connectionDetails);
  return (
    <React.Fragment>
        <NavGlobal />
        <Layout style={{ 
          margin: 8, 
          marginLeft: 32,
          marginRight: 32,
          borderRadius: 8, 
          border: '1px solid #e8e8e8',
        }}> 
       <Sider style={{ 
        background: colorBgContainer, 
        borderRadius: 8,
        overflow: 'auto',
        height: '80vh',
         }} width={400}>
         <ConnectionList  selectedID={connID}/>
        </Sider>
        <Content>
          <Card 

            title={<EntitySummary lean entityID={connID} />} 
            actions={[
              <NewChat connID={connID}/>
            ]}
            >
          
          
            <div 
              style={{
                height: '60vh',
              }}
           >           { connID && <React.Fragment >
                          {connectionDetails?.id}
                          <EntitySummary onlyName entityID={connectionDetails?.entityIDs[0]}/>
            <List>
              <PreviousChats connID={connID}/>
            </List>
          </React.Fragment>
        }

           </div>
  
          </Card>

        </Content>
      </Layout>
      <Footer/>

    </React.Fragment>
  );
};

export default ChatPage;
