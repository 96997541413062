import React from "react";
import {Modal, Button, InputNumber, Space, Select, message} from "antd";
import addNewDoc from "../../hooks/firestoreFns/addNewDoc";
import { collection } from "firebase/firestore";
import { useAppState } from "../../state";

const { Option } = Select;

const SupportAsYourselfModal = (props:{
    entityID: string
}) => {
    const [visible, setVisible] = React.useState(false);
    const [currency, setCurrency] = React.useState('SEK');
    const [amount, setAmount] = React.useState<number|null>(0);
    const { db, userEntity} = useAppState();
    const success = () => {
        message.success('you are a supporter');
    };

    const handleChange = (value: string) => {
        setCurrency(value);
    }
    return <React.Fragment>
        <Modal open={
            visible
        } onCancel={
            () => setVisible(false)
        }
        closable={false} footer={[]}
        >
    <Space>
        <InputNumber
            value={amount}
            onChange={(value) => setAmount(value)}
         /> 
        <Select
            value={currency}

            style={{ width: 80, margin: '0 8px' }}
            onChange={handleChange} >
    x        <Option disabled value="euro">euro</Option>
            <Option disabled value="dollar">dollar</Option>
            <Option value="sek">SEK</Option>
            <Option disabled value="inr">INR</Option>
        </Select>    
        montly
    </Space>
        <br/>
        <Button style={{marginTop: 32}}
        type='default' size="small" 
        onClick={
            () => {
                addNewDoc(
                    collection(db, `support/${userEntity?.id}/payment`), 
                    {
                        targetEntity: props.entityID,
                        amount: amount,
                        currency: currency,
                        recurring: true,
                    })
                success();
                setVisible(false);
            }
        }
        >
                Sponsor as yourself 
        </Button>
        </Modal>
            <Button size='small' type='text' onClick={
                () => setVisible(true)
            } >
                as yourself
            </Button>
    </React.Fragment>
}

export default SupportAsYourselfModal;
