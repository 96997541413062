import React, { useState } from 'react'
import { Space, Input, Button } from 'antd'
import { useAppState } from '../../state';
import { addNewChat } from '../../hooks/chat/handleChats';
import { doc } from 'firebase/firestore';
import addStringToArrayField from '../../hooks/firestoreFns/addStringToArrayField';
import removeStringFromArrayField from '../../hooks/firestoreFns/removeStringFromArrayField';


const NewChat = (props: { connID: string|undefined}) => {
    const { connID } = props;
    const [ message, setMessage] = useState<string>();
    const { db, userEntity } = useAppState();
    if (connID === undefined){
      return <></>;
    }
    
    const onNewChat = () => {
      if ( message === undefined ){
        return;
      }
      //@ts-ignore
      addNewChat(db, connID,  message, userEntity?.id).then((docRef) => {
        console.log("Document written with ID: ", docRef.id);

        
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
      setMessage(undefined);
      if (userEntity?.id){
        let docRef = doc(db, 'userState', userEntity?.id);
        removeStringFromArrayField(docRef, 'onboardedSteps', 'chat');
        addStringToArrayField(docRef, 'onboardedSteps', 'chat');
      }
 
    };

    return <Space direction='horizontal'>
        <Input 
            placeholder="" 
            value={message}
            style={{
              width: 320,
            }}
            onChange={
                (e:any) => setMessage(e.target.value)
            }
        /> 
          <Button type='primary' disabled={message === undefined} onClick={onNewChat}>Send</Button> 

    </Space>;
}

export default NewChat;
