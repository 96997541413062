import { collection, query, orderBy } from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";

const getNotification = (db:any, userId:any, setNotification:any) => {
    const q = query(
        collection(db, `notifications/${userId}/messages`),  
        orderBy( "timestamp", "desc")
    );

    console.log("inside getNotification", `notifications/${userId}/messages` )

    onSnapshot(q, (data:any) => {
        console.log("inside onSnapshot", data.docs)
        setNotification(data.docs.map((doc:any) => {
            return { 
                id: doc.id,
                ...doc.data()
            }
        }));
    });

}

export default getNotification;