import React from 'react'
import { query, collection, orderBy, getDocs, onSnapshot, limit } from 'firebase/firestore';
import { useAppState } from '../../state';
import { ChatMessageInterface } from '../../types';



export const getChats = async (db:any, entityID:string ) => {
    console.log(entityID);
    if (!entityID) return;

    
    const q = query(collection(db, `chats/${entityID}/messages`),  orderBy( "timestamp", "asc"), limit(5));
    const querySnapshot = await getDocs(q);


    const data = querySnapshot.docs.map((doc: any) => {
        console.log(doc.data());
        return {
            id: doc.id,
            ...doc.data()
        }
    });
    return data
}
const useGetChats = (entityID: string | undefined) => {

    const [chats, setChats] = React.useState<ChatMessageInterface[]>([]);
    const { db } = useAppState();
    

    React.useEffect(  () => {
        if (!entityID) return;
        console.log("useEffect");
        const q = query(collection(db, `chats/${entityID}/messages`),  orderBy( "timestamp", "asc"));

        const unsub = onSnapshot(q, (data:any) => {
            let newData = data.docs.map((doc:any) => ({ 
                id: doc.id,
                ...doc.data()
            })
            );
    
            setChats(newData);
            console.log("Current data: ", data.docs.map((doc:any) => (doc.data()) ));
        });
        return unsub;

        // getChats(db, entityID).then((chatData) => setChats(chatData));

    }, [entityID, db]);

    return chats;
}

export default useGetChats;