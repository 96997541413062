import React from "react";
import {Modal, Button, InputNumber, Space, Select, Switch, Typography, Card} from "antd";
import { MinusCircleOutlined, PlusCircleOutlined, SettingOutlined } from "@ant-design/icons";

const { Option } = Select;

const SupportContextSettings = (props:{
    entityID: string
}) => {
    const [visible, setVisible] = React.useState(false);
    const [currency, setCurrency] = React.useState('SEK');
    const [supportersChoosePrice, setSupportersChoosePrice] = React.useState<boolean>(true);
    const { Text } = Typography;

    const handleChange = (value: string) => {
        setCurrency(value);
    }
    return <React.Fragment>
        <Modal open={
            visible
        } onCancel={
            () => setVisible(false)
        }

        closable={false} footer={[]}
        >
            <h4>Sponsor settings </h4>

            <h5>Existing sponsor categories. </h5>

            - <Text code> recurring </Text> <Text code> Sponsors choose price </Text> 
            <Button size="small" disabled icon={<MinusCircleOutlined/>} >
                remove
            </Button>

            <h5>Your bank details. </h5>
            <Button type='default' size="small" disabled icon={<PlusCircleOutlined />} > add </Button>


            <h5>Add more sponsor categories. </h5>
            <Card bordered={false}>

          
            <Button.Group>
                <Button > one time </Button>
                <Button > recurring </Button>
            </Button.Group> <br/>
                sponsers choose price
            <Switch 
                size="small"
                onChange={(checked) => setSupportersChoosePrice(checked)}
                defaultChecked
            />    
                <br/>
                <Space>
                {
                    !supportersChoosePrice &&    <Space>
                    <InputNumber /> 
                    <Select
                        value={currency}
                        style={{ width: 80, margin: '0 8px' }}
                        onChange={handleChange} >
                        <Option  value="euro">euro</Option>
                        <Option  value="dollar">dollar</Option>
                        <Option value="sek">SEK</Option>
                        <Option  value="inr">INR</Option>
                    </Select>    
                                </Space>
                }
                <Button disabled> add </Button>
                </Space>

        </Card>



        </Modal>
            <Button 
            size='small' 
            type='text' 
            onClick={
                () => setVisible(true)
            } 
            icon={<SettingOutlined />}
            >
               Sponsor settings
            </Button>
    </React.Fragment>
}
export default SupportContextSettings;
