import React from "react";
import MakeAdminButton from "../TieButtons/MakeAdminButton";
import RevokveAdminButton from "../TieButtons/RevokeAdminButton";
import useGetConnectionDetailsFromId from "../../hooks/getDetails/useGetConnectionDetailsFromId";
import useGetIsAdmin from "../../hooks/getDetails/useGetIsAdmin";
import { useAppState } from "../../state";
const AdminToggleButton = (props: {
    sourceID: string | undefined;
    targetID: string | undefined;
    connectionID: string;
}) => {

    const { connectionID,sourceID, targetID } = props;
    const { userEntity } = useAppState();

    const userID = userEntity?.id;
    const connObj = useGetConnectionDetailsFromId(connectionID)
    const sourceAdmin = useGetIsAdmin(userID, sourceID, true);
    const targetAdmin = useGetIsAdmin(userID, targetID, true);
    
    if (!sourceAdmin || !targetAdmin) {
        return <></>
    }

    if (connObj?.admin) {
        return <RevokveAdminButton 
            connectionID={props.connectionID}
        />
    } 
    if (!connObj?.admin) {
        return <MakeAdminButton 
            connectionID={props.connectionID}
        />
    }
    return <React.Fragment>
        <MakeAdminButton 
            connectionID={props.connectionID}
        />
    </React.Fragment>
}

export default AdminToggleButton;