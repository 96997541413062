import React from "react";
import { useAppState } from "../../state";
import { DirectedConnectionInterface } from "../../types";
import { collection, query, orderBy, where, onSnapshot  } from "firebase/firestore";

import { createOtherID } from "./useGetEntityConnections";

export default function useGetEntityPendingConnections(entityID: string| undefined, incoming?: boolean ) {
    
    const { db } = useAppState();
    const [entityConnections, setEntityConnections] = React.useState<DirectedConnectionInterface[]|undefined>();
    React.useEffect(() => {
        if  (entityID === undefined ){
            return undefined;
        }
        const q_incoming_only = query(collection(db, "connections"), where( entityID, "==", false));
        const q_both_incoming_and_outgoing = query(collection(db, "connections"),  orderBy( entityID, "desc"));

        const q = incoming ? q_incoming_only : q_both_incoming_and_outgoing;
        const unsub = onSnapshot(q, (data:any) => {
            let newData = data.docs.filter((doc:any) => {
                const docData = doc.data()
                if (docData === undefined){
                    return false;
                }
                const entityIDs = docData.entityIDs;

                if (entityIDs.length !== 2){
                    return false;
                }

                return ((docData[entityIDs[0]] === false) || (docData[entityIDs[1]] === false));

            }).map((doc:any) => ({ 
                id: doc.id,
                entityIDs: doc.data()?.enitityIDs,
                otherEntityID: createOtherID(doc.data()?.entityIDs, entityID),
                ...doc.data()
            } ) );
            setEntityConnections(newData);
        });

        return unsub;
        
    }, [entityID, db, incoming]);

    return entityConnections;
}