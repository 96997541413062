import {doc, setDoc } from 'firebase/firestore';


const updateConnectionDoc = async (db:any, connectionID : string, data : any) => {
       // TODO - check if user is admin on both ends
    const connectionRef = doc(db, 'connections', connectionID);
    await setDoc(connectionRef, data, {merge: true}).catch((e:any) => {
        console.error("Error updating document: ", e);
    });
}



export const makeAdmin = async (db:any, connectionID : string) => {
    updateConnectionDoc(db, connectionID, {
        admin: true
    });
}


export const revokeAdmin = async (db:any, connectionID : string) => {
    updateConnectionDoc(db, connectionID, {
        admin: false
    });
}