import React from "react";
import NavGlobal from "../NavGlobal/NavGlobal";

const InsightPage = () => {
    return <React.Fragment> 
        <NavGlobal />
        Insights Page
    </React.Fragment>
}

export default InsightPage;