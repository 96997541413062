import React, { CSSProperties } from 'react';
import { useCSVReader } from 'react-papaparse';
import { Button, Card, Collapse, Modal, Typography } from 'antd';

import { useAppState } from '../../state';
import { ImportOutlined } from '@ant-design/icons';
import ListAddedEntities from './ListAddedEntities';
import { parse_uploaded_csv_data } from './parse_csv';
import ListParsedEntities from './ListParsedEntities';
import useGetEntityDetails from '../../hooks/getDetails/useGetEntityDetails';
import { getAllowedEntityTypes } from '../../hooks/getDetails/linkable';

const styles = {
  csvReader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  } as CSSProperties,
  browseFile: {
    width: '20%',
  } as CSSProperties,
  acceptedFile: {
    border: '1px solid #ccc',
    borderRadius: 8,
    height: 30,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: '80%',
    marginLeft: 8,
    marginRight: 8
  } as CSSProperties,
  remove: {
    borderRadius: 0,
    padding: '0 20px',
  } as CSSProperties,
  progressBarBackgroundColor: {
    backgroundColor: 'red',
  } as CSSProperties,
};

const ImportCSV = (props: {  
  sourceID: string| undefined,
  setIsImport?: any
}) => {

  // const { setImportedEntities, setIsImport } = props;

  const { CSVReader } = useCSVReader();
  const [data, setData] = React.useState<any>([]);
  const { Text } = Typography;

  const { userEntity} = useAppState();
  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);

  const {sourceID} = props;

  const sourceDetails = useGetEntityDetails(sourceID);
  const allowedEntityTypes = getAllowedEntityTypes( sourceDetails?.entityType)
  
  const [addedEntities, setAddedEntities] = React.useState<{
    entityID: string, 
    entityExists: boolean ,
    connectionExists: boolean 
  }[]>([]);

  const afterAdding = (newID: string, entityExists: boolean, connectionExists: boolean ) => {

    setAddedEntities([{ 
      entityID: newID, 
      entityExists: entityExists,
      connectionExists: connectionExists,
    }, 
      ...addedEntities
    ])

  }
  
  return (<React.Fragment >
  <Modal 
  key={sourceID}
    width={"70%"}
    open={isAddModalOpen}
    onCancel={() => setIsAddModalOpen(false)}
    footer={[ 
      <Button 
        onClick={() => setIsAddModalOpen(false)}
        type='text'
        > close </Button>]}
        >
    <Card
      style={{
        margin: 16,
        padding: 16,
      }}
    >
      <Collapse   bordered={false} defaultActiveKey={[]} style={{
        marginBottom: 16,
      }}>
      <Collapse.Panel header="Instructions" key="1">
        <p style={{
          margin: 8
        }}>
          Following column names are parsed from your csv file - they are case sensitive. <br/><br/>
          <Text code type="danger">entityType</Text> <Text> required for all entity types. [thing / person / relationship / context]</Text>  <br/>
          <Text code type="danger">name</Text> <Text> provide a name for the entity - required for all entity types.  </Text> <br/>
          <Text code type="danger">description</Text> <Text>  provide a description for the entity - required for all entity types. </Text>   <br/>
          <Text code type="warning">email</Text><Text> only required for  <Text code>person</Text> entities </Text><br/>
          <Text code type="warning">link</Text><Text> only required for  <Text code>thing</Text> entities </Text> <br/>
          <Text code type="success">contextTags</Text> optional field of comma separated contexts - that will be tied between the new entity and the target entity the csv file is being uploaded. If unspecified - the new entity will be directly ties to the target entity.
        </p>
  
      </Collapse.Panel>
      <Collapse.Panel header="Template" key="2">
        <div>
          <a href="/csv/template_01.csv" download="template_01.csv">Download Template</a>
        </div>
      </Collapse.Panel>
        
      </Collapse>


    <CSVReader
        onUploadAccepted={(results: { data: any[]}) => { 
          let parsedResult : { 
            importedData :any, 
            error : {
              error: boolean,
              message: string
            } 
          } = parse_uploaded_csv_data( results.data);

          if (parsedResult.error.error === false) {
            setData(parsedResult.importedData);
          }
          
          console.log("imported data", parsedResult.importedData);
          console.log(parsedResult.error);

        }}
      >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
      }: any) => (
        <>
          <div style={styles.csvReader}>
            <Button {...getRootProps()} >
              Browse file
            </Button>
            <div style={styles.acceptedFile}>
              {acceptedFile && acceptedFile.name}
            </div>
            <Button {...getRemoveFileProps()} >
              Remove
            </Button>
          </div>
          <ProgressBar style={styles.progressBarBackgroundColor} />
        </>
      )}
    </CSVReader>

    <ListAddedEntities addedEntities={addedEntities} />
     
      {
        /*
        addedEntities?.map((item: any) => {
            console.log('item', item)
            return <>
             { item.entityExists && <p> Entity already exists </p> }
             { item.connectionExists && <p> Connection already exists </p> } 
            <EntitySummary entityID={item.entityID} onClick={() => {
                setIsAddModalOpen(false);
                nav(`/entity/${item.entityID}`);
            } }/></>
        
        })
        */
      } 
      {
        sourceID && userEntity?.id && <ListParsedEntities 
        parsedEntities={data}
        afterAdding={afterAdding}
        sourceID={sourceID}
        allowedEntityTypes={allowedEntityTypes}
      />
      }
  
  </Card>
  </Modal>
    <Button 
      type='text'
      onClick={() => setIsAddModalOpen(true)} 
      icon={<ImportOutlined />}> 
      import .csv 
    </Button>

    </React.Fragment>
  );
};

export default ImportCSV;
