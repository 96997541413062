import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";

const InfoIconTooltip = (props: { text: string }) => {
    const { text } = props;
    return  <Tooltip placement="topLeft" title={ <> {text} </>}>
        <InfoCircleOutlined size={16} style={{
            marginRight: 8,
            marginLeft: 8,
            marginTop: 8
        }} />
    </Tooltip>

    }   

export default InfoIconTooltip;