import React from 'react';
import { Steps } from 'antd';


const countCompletedItems = (items: any) => {
  let completed = 0;
  items.forEach((item: any) => {
    if (item.completed) completed++;
  })
  return completed;
}

const StepsTimeline = (props:{
  IsCollapsed: boolean,
  items: any
}) => { 

  const { IsCollapsed, items } = props;
  const completed = countCompletedItems(items);
  if (IsCollapsed) return <Steps
  style={{
    margin: 16
  }}
  direction="vertical"
  size="small"
  current={completed}
  items={[
    {},{},{},{},{},{},{},{},{}
  ]}
/>

  return <React.Fragment>
  <Steps
    style={{
      margin: 16
    }}
    direction="vertical"
    size="small"
    current={completed}
    items={items}
  />
</React.Fragment>

}


export default StepsTimeline;