import React from "react";
import { Tabs } from "antd"

const onChange = (key: string) => {
  console.log(key);
};


const tabChildren = {
  "vitality": <img 
  width={280}
  src={"/images/vitality.png"} 
  alt="vitality"
  />,
  "logical_types": <img 
  width={280}
  src={"/images/logical_types.png"} 
  alt="logical types"
  />,
  "discovery_matrix": <img
  width={280}
  src={"/images/discovery_matrix.png"}
  alt="discovery matrix"
  />,
  "language": <img
  width={280}
  src={"/images/language.png"}
  alt="language"
  />,
"insights": <img
width={280}
src={"/images/insights.png"}
alt="Insights"
/>,
"search": <img
width={280}
src={"/images/search.png"}
alt="Search"
/>,
"graphs": <img
width={280}
src={"/images/graph_view.png"}
alt="graph view"
/>
}



const Stochasticity = () => {

  return  <>
    <Tabs
    onChange={onChange}
    tabPosition='left'
    items={[
      {"label" : "vitality for life", "key": "vitality", "children": tabChildren["vitality"]},
      {"label" : "helpful abstractions", "key": "logical_types", "children": tabChildren["logical_types"]},
      {"label" : "discovery matrix", "key": "discovery_matrix", "children": tabChildren["discovery_matrix"]},
      {"label" : "search engine", "key": "search", "children": tabChildren["search"]},
      {"label" : "insight engine", "key": "insights", "children": tabChildren["insights"]},
      {"label" : "graphs engine", "key": "graph", "children": tabChildren["graphs"]},
      {"label" : "language", "key": "language", "children": tabChildren["language"]},
    ]
    }>
</Tabs>

  </>
};

export default Stochasticity;