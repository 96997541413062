import React from "react";
import useGetConnectionDetails from "./useGetConnectionDetails";
import { isPending } from "./../getConnections/useGetEntityConnections"

const useGetIsMember = (sourceID: string|undefined, targetID: string|undefined) => {

    const connDetails = useGetConnectionDetails(sourceID, targetID);
    const [isMember, setIsMember] = React.useState<boolean>(false);
    // console.log("isMember", sourceID, targetID, connDetails);
    React.useEffect(() => {
        // console.log("connDetails", connDetails);

        if (connDetails) {
            const pending  =  isPending(connDetails);         
            setIsMember(!pending);

        } else {
            if (sourceID === targetID){
                setIsMember(true);
            }
            else {
                setIsMember(false);

            }
        }
    }
    , [connDetails, sourceID, targetID])


    return isMember;
}

export default useGetIsMember;