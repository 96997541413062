import React, { useEffect } from "react";
import useGetEntityConnections from "../../hooks/getConnections/useGetEntityConnections";
import { List, Switch, Typography, message } from "antd";
import {DirectedConnectionInterface, FilterConnection} from '../../types';
import { useAppState } from "../../state";
import useGetIsAdmin from "../../hooks/getDetails/useGetIsAdmin";
import ConnectionSummary from "../ConnectionSummary/ConnectionSummary";
import EditConnection from "../EditConnection/EditConnection";
import InfoIconTooltip from "../Info/InfoIconTooltip";
import YellowMessage from "../Info/YellowMessage";

const ConnectionList = (props: { 
    entityID: string | undefined,
    filter: FilterConnection
  
}) => {

    const [messageApi, contextHolder] = message.useMessage();
    const error = () => {
      messageApi.open({
        type: 'error',
        content: 'disable edit mode before being able to navigate to away to another entity page.',
      });
    };
    const { entityID, filter } = props;
    const { Text } = Typography;
    const { userEntity } = useAppState();
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const sourceAdmin =   useGetIsAdmin(entityID, userEntity?.id, true);
    // const allConnections: any[] = [];
    const allConnections = useGetEntityConnections(entityID, filter); 
            
    useEffect(() => {
      setEditMode(false);
    }, [entityID]);
  
    return  <React.Fragment>
      {contextHolder}

        <List 
          size='small'
          itemLayout="vertical"
          >
            { sourceAdmin && <> 
              <InfoIconTooltip text="edit ties to the entity"/> 
            <Switch size={'small'} onChange= {
              (checked: boolean) => {
                console.log('checked', checked);
                setEditMode(checked);
              }
            }/> 
              <Text> Edit Mode </Text> 

              { 
                editMode 
                && <YellowMessage 
                      text="navigating away to entities below is disabled while in edit mode"
                    /> 
              }
            
            </>
            }


            
            {
              allConnections.map( (conn: DirectedConnectionInterface) =>
              <React.Fragment>
                <List.Item>
                  <ConnectionSummary
                    connID={conn.id}
                    sourceID={entityID}
                    targetID={conn.otherEntityID}
                    onClick={editMode ?  () => {
                      console.log('clicked')
                      error();
                    
                    } : undefined}

                    />
                  {
                    editMode && 
                    <EditConnection 
                      connectionID={conn.id}
                      sourceID={entityID}
                      targetID={conn.otherEntityID}
                    />
                  }
                </List.Item>
              </React.Fragment>
              )
            }
        </List>
</React.Fragment>
}

export default ConnectionList;
