import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useAppState } from '../../state';
import { Card, Row, Col, Spin } from 'antd';
import NavGlobal from '../NavGlobal/NavGlobal';
import { setNewUserState } from '../../hooks/user/handleUserState';
import getEntityIDfromEmail from '../../hooks/getDetails/getEntityIDfromEmail';
import { duplicateConnections, deleteConnections, duplicateEntity, setPermanentEntityID } from '../../hooks/email/linkEmailAndEntityID';
import emailHasBeenInvitedByExistingUser from '../../hooks/email/emailHasBeenInvitedByExistingUser';
import Footer from '../NavGlobal/Footer';
import useSendMessage from '../../hooks/monitoring/useSendMessage';
const SearchGalaxyImg = "/images/alone_galaxy.png";

const SignupPage: React.FC = () => {

  const { signUpEmail, db } = useAppState();
  const [loading, setLoading] = useState<boolean>(false);
  const [authError, setAuthError] = useState<any>(null);
  useSendMessage(db, {"message": "signup page loaded"});
  const onFinish = async (values: any) => {

    console.log('Success:', values);
    setLoading(true);
    setAuthError(null);

    const InvitedEntityID = await getEntityIDfromEmail(db, values.username);
    console.log("InvitedEntityID", InvitedEntityID);

    if (InvitedEntityID === null) {
      console.log("user has not been invited");
      setAuthError(
        {
          message: "the email you have used has not been invited to join Vellby :("
        }
      );
      return ;
    } 
    

    const emailHasBeenInvites = await emailHasBeenInvitedByExistingUser(db, values.username);
    console.log("emailHasBeenInvites", emailHasBeenInvites);

    if (!emailHasBeenInvites) {
      console.log("user has not been invited");
      setAuthError(
        {
          message: "the email you have used has not been invited to join Vellby :("
        }
      );
      return ;

    }

    signUpEmail?.(values.username, values.password, values.remember)
      .then(async (userCredential:any) => {
        
        console.log("push to homepage")
        // history.replace(location?.state?.from || { pathname: '/' });
        const newUser = userCredential.user;
        console.log("user.uid", newUser.uid);
        console.log("InvitedEntityID", InvitedEntityID);

        
        await setPermanentEntityID(db, values.username, newUser.uid);
        await duplicateEntity(db, InvitedEntityID, newUser.uid);
        await duplicateConnections(db, InvitedEntityID, newUser.uid);
        await deleteConnections(db, InvitedEntityID);
        await setNewUserState(db, newUser.uid);
        
        setTimeout( () => { window.location.href = "/" } , 2000);
       
      })
      .catch((err: React.SetStateAction<Error | null>) => {

        console.log('error in login page', err);
        setAuthError(err);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  
  /*
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);
  */

  return (<div style={{
    margin: 8,
    height: '100vh',
  }}>
            
  <NavGlobal />
    <Card style={{
            marginTop: 8,
            marginRight:  32,
            marginLeft: 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}
        bordered={false}
    >
      <Row>
      <Col span={300} style={{
        margin: 24
      }}>
      <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[{ 
          required: true, 
          message: 'Please input your username!' 
        }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>


      {
        authError && <p>
          {authError.message}
        </p>
      }

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" disabled={loading}>
         { loading ? <Spin size="small" /> : 

          "Signup" }
        </Button>

      </Form.Item>

    </Form>
      </Col>
      <Col span={300}>
      <img
        src={SearchGalaxyImg}
        alt="Thing"
        width={300}
        style={{
            cursor: 'pointer',
            margin: 4,
            borderRadius: 4
        }}
        />
      </Col>
    </Row>
    </Card>
    <Footer/>

    </div>
  );
};




export default SignupPage;

