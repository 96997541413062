import React from "react";
import { getDocDetails } from "../../hooks/firestoreFns/getDocDetails";
import { useAppState } from "../../state";
import EntitySummary from "../EntitySummary/EntitySummary";

const PaymentSummary = (props: {
    paymentID: string,
    sourceEntity: string,
}) => {

    const { db } = useAppState();
    
    const [paymentDetails, setPaymentDetails] = React.useState<any>();
    React.useEffect(() => {
        getDocDetails(db, `support/${props.sourceEntity}/payment/${props.paymentID}`).then((doc) => {
            setPaymentDetails(doc);
        }).catch((error) => {
            console.log(error);
        });
    }, [db, props.paymentID, props.sourceEntity]);
    if (paymentDetails === undefined) {
        return <div> Loading ... </div>
    }
    return <div style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 8,
    }}> 
        {
            paymentDetails?.amount &&  <>
                <EntitySummary entityID={props.sourceEntity} onlyName/> pays {paymentDetails?.amount} {paymentDetails?.currency} monthly to <EntitySummary entityID={paymentDetails.targetEntity} onlyName/> 
            </>
        }
        {
            paymentDetails?.percentage &&  <>
                <EntitySummary entityID={props.sourceEntity} onlyName/> passes on {paymentDetails?.percentage} % to <EntitySummary entityID={paymentDetails.targetEntity} onlyName/>
            </>
        }
    </div>
}

export default PaymentSummary;

