import React from "react";
import { Card, Typography } from "antd";

const Footer = () => {
    const {Text } = Typography;
    return (
        <React.Fragment>
            <Card style={{
                    margin: 32,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                bordered={false}>
                <Text type="secondary">
                    WellByCare Nordic AB. 2023. help@vellby.com.
                </Text> 
            </Card>
        </React.Fragment>
    )
};

export default Footer;