import React from "react";
import EntitySummary from "../EntitySummary/EntitySummary";
import { useAppState } from "../../state";
import { Divider } from "antd";
import { Card } from "antd";

const DynamicCarousel = () => {
    
    // TODO: get entities from the backend - with some adaptive logic.
     const { userEntity } = useAppState();
     return <React.Fragment>
        <Divider
            orientation="left"
            plain
        >
            your recent entities
        </Divider>
        <Card style={{
            width: 320,
            margin: 4
        }} >
            <EntitySummary entityID={userEntity?.id} lean />
        </Card>
    </React.Fragment>

}

export default DynamicCarousel;