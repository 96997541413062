import React, { useEffect } from "react";
import { Button, Card, Modal, Space, Tabs } from "antd";
import NavGlobal from "../NavGlobal/NavGlobal";
import { useParams } from "react-router-dom";
import EntitySummary from "../EntitySummary/EntitySummary";
import EditEntity from "../EditEntity/EditEntity";
import Graph from "../Graph/Graph";
import ConnectionList from "../Connection/ConnectionList";
import type { TabsProps } from 'antd';
import useGetEntityDetails from "../../hooks/getDetails/useGetEntityDetails";
import { useAppState } from "../../state";
//import useGetConnectionDetails from "../../hooks/getDetails/useGetConnectionDetails";
import BuildEntity from "../BuildTies/BuildEntity";
import Footer from "../NavGlobal/Footer";
import { NodeIndexOutlined, ShareAltOutlined, UnorderedListOutlined } from "@ant-design/icons";
import useGetIsAdmin from "../../hooks/getDetails/useGetIsAdmin";
import AdminStatus from "./AdminStatus";
import SupportComponent from "../Sponsor/SupportComponent";
// import PinnedConnections from "../Pinned/PinnedConnectionsList";
import useGetIsMember from "../../hooks/getDetails/useGetIsMember";
import { useNavigate } from "react-router-dom";
import Comms from "../Chat/Comms";
//import { Collapse, theme } from 'antd';


export default function EntityProfilePage() {
  const nav = useNavigate();

    let { entityID, view } = useParams();
    const [ EID, setEID ] = React.useState(entityID);
    const [entityView, setView] = React.useState(view ? view : 'graph');
 

    /*

    const { Panel } = Collapse;
    const { token } = theme.useToken();
    const panelStyle = {
      background: token.colorFillAlter,
      borderRadius: token.borderRadiusLG,
      border: 'solid 1px ' + token.colorBorder,
    };
    
    */

    useEffect(() => {
      
          setEID(entityID);
    }, [entityID]);

    useEffect(() => {
        if (view) {
            setView(view);
        }
    }, [view]);

    const entityDetails= useGetEntityDetails(EID);
    const { userEntity } = useAppState();
    //const isAdmin = false; 
    const isAdmin = useGetIsAdmin(userEntity?.id, EID, true);
    const isFirstDegreeAdmin = useGetIsAdmin(userEntity?.id, EID, false);
    //const isMember = useGetIsMember(userEntity?.id, EID);
    const isMember = useGetIsMember(userEntity?.id, EID);
    // REFACTOR - useGetConnectionStatus ...
    // const [ isFirstDegreeAdmin, setIsFirstDegreeAdmin] = React.useState(false);
    // const connectioisnDetails = useGetConnectionDetails(EID, userEntity?.id);
    
    /*useEffect (() => {
        
        if (connectionDetails?.id) {
            if(connectionDetails?.admin) {
                setIsFirstDegreeAdmin(true);
            }
        }
        else {
            if (userEntity?.id === EID) { 
              setIsFirstDegreeAdmin(true);
            }
            else { 
              setIsFirstDegreeAdmin(false);
            }
        }

    //console.log("connectionDetails", connectionDetails)
    }, [connectionDetails?.id, connectionDetails?.admin, userEntity?.id, EID])
    */
 
    const items: TabsProps['items'] = [
        {
          key: '1',
          label:  <h3 > <ShareAltOutlined />graph view</h3> ,
          children: (EID && <Graph
            setEntityID={setEID} 
            entityID={EID} 
            filter={{
              entityTypes: [],
              adminOnly: false,
              removePending: true,
              removeHidden: true
            } }
            /> ),
        },
        {
          key: '2',
          label: <h3> <UnorderedListOutlined />list view</h3>,
          children: <ConnectionList 
            entityID={EID} 
            filter={{
              entityTypes: [],
              adminOnly: false,
              removePending: isAdmin ? false : true,
              removeHidden:isAdmin ? false : true
            } }
          />,
        }
      ];


    const onChange = (key: string) => {
    console.log(key);
      if (key === '1') {
        nav(`/entity/${EID}/graph`) ;
      }
      else {
        nav(`/entity/${EID}/list`) ;
      }
    };
      

    const [editModal, setEditModal] = React.useState(false);

    const EditModalButton = () => <Button 
          type={'dashed'}
          onClick={ () => setEditModal(true) }
        >
          Edit
    </Button>


    return <React.Fragment>
        <NavGlobal />
        <Modal 
          open={editModal} 
          onCancel={()=> setEditModal(false)} 
          closable={false} footer={[]}>
          <EditEntity 
            entityID={EID} 
            setIsOpen={setEditModal} 
          />
        </Modal>
        <Card
              style={{ marginLeft: 32, marginRight: 32, marginTop: 8 }}
              bordered={false}
            >
            <EntitySummary entityID={EID} extended />  
            { isFirstDegreeAdmin  ? <div style={{
              marginBottom: 32,
              marginLeft: 16
              }}>
                  <EditModalButton />
              </div> : <></>
            } 
            <AdminStatus 
                  isAdmin={isAdmin}
                  isFirstDegreeAdmin={isFirstDegreeAdmin}
            />
    </Card>   
    <BuildEntity 
          entityID={EID}
          userID={userEntity?.id} 
          entityType={entityDetails?.entityType} 
        />
        {isMember && 
    <Comms
      entityDetails={entityDetails}
      entityID={EID} 
      admin={isFirstDegreeAdmin}
    /> }

        {isMember && EID && <SupportComponent entityID={EID} />  }
        <Card
          style={{ 
            marginLeft: 32, 
            marginRight: 32,
            marginTop: 4
          }}>
            <Space>
            <NodeIndexOutlined /> Ties in <EntitySummary entityID={entityID} onlyName/>
            </Space>
        <Tabs 
          style={{
              marginLeft: 32,
              marginRight: 32
          }}
          activeKey= {entityView ? entityView === 'list' ? '2': '1' : '1'}
          items={items} 
          onChange={onChange} 
        />
        </Card>
        <Footer />
    </React.Fragment>
}

/*
        <Collapse
          key={EID}
          bordered={false}
          expandIcon={({ isActive }) =>  
           { return isActive ?  <Button style={{
            marginTop: 16,
            marginBottom: 0,
            marginRight: 16
           }}> close </Button> : <Button style={{
            marginTop: 16,
            marginBottom: 0,
            marginRight: 16
           }}>  details </Button>} 
         }
          style={{ background: token.colorBgContainer,
          marginTop: 8, marginLeft:8, marginRight:8, marginBottom: 0 }}
          expandIconPosition={'end'}
        >
          <Panel header={ <><div style={{
            display: 'flex',
            flexDirection: 'row',
          }}> 
          <EntitySummary entityID={EID } lean /> 
          <div 
            style={{
                    margin: 8,
            }}>
            <AdminStatus isAdmin={isAdmin} isFirstDegreeAdmin={isFirstDegreeAdmin}/>
          </div>
          </div>
          </>
          } key="1" style={panelStyle}>
          <Card
              style={{ marginTop: 8 }}
              bordered={false}
            >
            <EntitySummary entityID={EID} extended />
            { isAdmin  ? <div style={{
              marginBottom: 32,
              marginLeft: 16
              }}>
                  <EditModalButton />
              </div> : <></>
            } 
            </Card>
          </Panel>
        </Collapse>  
        */