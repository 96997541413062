import React, {useEffect} from "react";
import { Form, Input, Button, Card, Typography } from "antd";
import EntitySummary from "../EntitySummary/EntitySummary";
import { CheckCircleFilled } from "@ant-design/icons";
import { useAppState } from "../../state";
import { EntityInterface } from "../../types";
import applyAndWaitToJoin from "../../hooks/buildRecipies/applyAndWaitToJoin";

const ApplyToJoin = (props: { 
        entityID: string | undefined,
     }) => {
    const { entityID } = props;

    const { db } = useAppState();
 

    const applytojoin = async (values: any) => {
        console.log("apply to join");
        console.log(values);
        
        const entityDetails: EntityInterface = {
            name: values.name,
            description: " ",
            entityType: "person",
            email: values.email,
            photoURL: "unassigned",
            id: "unassigned",
            creatorID: values.email
        }

        console.log(entityDetails);

        applyAndWaitToJoin(values.email, values.name, entityID, db, afterAdding);
        setApplied(true);

    }

    const [email, setEmail] = React.useState("");
    const [name, setName] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const [ applied, setApplied ] = React.useState(false);
    const { Text } = Typography;
    const afterAdding = (connID: string, newlyApplied: boolean, newlyCreated: boolean ) => {
        if (!newlyApplied){
            setSuccessMessage(  "you have previously applied to this entity with this email.")
        } else {
            setSuccessMessage(  "you have successfully applied !" )
        }
    }

    useEffect(() => {
        setApplied(false);
    }, [entityID]); 

    return  <React.Fragment> 
        <Card bordered={false}> 
        <EntitySummary entityID={entityID} onClick={() => {
                console.log("do nothing");
            }}/>

            {
                  applied ?
                    <div style={{ margin:32, }}>
                  <Text style={{
                   

                  }}> 
                      <CheckCircleFilled color={'#050'} style={{
                          color: '#5AD8A6',
                          marginRight: 8
                      }}/> 
                      {successMessage}
                      </Text> </div>: 

            <Form 
            style={{margin: 32}}
            layout="inline"
            onFinish={applytojoin}>
            <Form.Item  
                name="name"  
                rules={[{ required: true, message: 'Please input your username!' }]}>
                <Input 
                    value={name}
                placeholder="name"
                    onChange={(e)=> setName(e.target.value)}
                 />
            </Form.Item>
            <Form.Item 
                name="email" 
                
                rules={[{ required: true, message: 'Please input your email!' }]}

            >
                <Input 
                    value={email}
                placeholder="email" name="email" onChange={(e)=> setEmail(e.target.value)}/>
            </Form.Item>
            <Form.Item>
                <Button type="text" htmlType="submit">
                    Apply to join
                </Button>     
            </Form.Item>
        </Form>
        }

        </Card>

    </React.Fragment>
}

export default ApplyToJoin;