export const ColorExp :Record<string, string>  = {
    'person': '#5B8FF9',
    'context': '#5AD8A6',
    'thing': '#5D7092',
    'relationship': '#F6BD16',
  }
  
export const SizeExp = {
    'person': 15,
    'context': 30,
    'thing': 10,
    'relationship': 20,
    'center': 60
  }