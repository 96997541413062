import React from "react";
import { useAppState } from "./state";
import EntityProfilePage from "./components/Entity/EntityProfilePage";
import EntitySignupPage from "./components/EntitySignupPage/EntitySignupPage"


const ProtectedRouteEntityPage = () => {

    const { isAuthReady, user } = useAppState();
    if (!isAuthReady ) {
        console.log('auth not ready')
        return null;
    }
    if (!user) {
        console.log("user not logged in")
        return <EntitySignupPage />
    }
    return <EntityProfilePage />

};

export default ProtectedRouteEntityPage;