import React from "react";
import { Button, Modal } from "antd";

const ModalConfirm = (props: {
    ButtonContent: any;
    ModalContent: any;
    onConfirm: () => void;
}) => {
    const { ButtonContent, ModalContent, onConfirm } = props;
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    return <React.Fragment>

        <Button
            type={'text'}
            onClick={ () => 
                setIsModalOpen(true)
            }
        > 
            {ButtonContent}
        </Button>
        <Modal 
            title={  "Are you sure ? "}
            onCancel={() => setIsModalOpen(false)}
            footer={[
                <Button key="back" onClick={() => setIsModalOpen(false)}>
                    No.
                </Button>,
                <Button key="submit" type="primary" onClick={onConfirm}>
                    Yes, I am sure !
                </Button>,
            ]}
            open={isModalOpen}
        >
            {ModalContent}

        </Modal>
    </React.Fragment>

    ;
};

export default ModalConfirm;