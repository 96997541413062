import {setDoc, deleteDoc, doc, getDoc} from 'firebase/firestore';



const unlink =  async (db:any, connectionID: string| undefined) => {

    if (connectionID) {
      const connDoc = await getDoc(doc(db, `connections/${connectionID}`));
      const connData = connDoc.data();

      console.log(connData);
      if (connData) {

        await setDoc(doc(db, 'archivedConnections', connectionID), {
          entityIDs: connData?.entityIDs,
          id: connectionID,
          admin : connData?.admin ? true: false
        });

        await deleteDoc( doc(db, 'connections', connectionID ))
      }


    }

  };

  export default unlink;
