import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { useAppState } from '../../state';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col } from 'antd';
import NavGlobal from '../NavGlobal/NavGlobal';
import { Typography } from 'antd';
import Footer from '../NavGlobal/Footer';
import useSendMessage from '../../hooks/monitoring/useSendMessage';
const SearchGalaxyImg = "./images/search_galaxy.png";

const { Link } = Typography;

const LoginPage: React.FC = () => {

  const { signInEmail, user, db } = useAppState();
  let navigate = useNavigate();
  const [authError, setAuthError] = useState<Error | null>(null);
  console.log("user ", user);

  useSendMessage(db, {"message": "login page loaded"});

  const onFinish = (values: any) => {
    console.log('Success:', values);
    setAuthError(null);
    signInEmail?.(values.username, values.password, values.remember)
      .then(() => {
        console.log("push to homepage")
       //  history.replace(location?.state?.from || { pathname: '/' });
       navigate("/");
       
      })
      .catch((err: React.SetStateAction<Error | null>) => {
        console.log('error in login page', err);
        setAuthError(err);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);


  return (<div style={{
    margin: 8,
    height: '100vh',
  }}>
    <NavGlobal />
    <Card style={{
        marginTop: 8,
        marginRight:  32,
        marginLeft: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      bordered={false}
      >
      <Row>
      <Col span={300} style={{
        margin: 24
      }}>
      <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[{ 
          required: true, 
          message: 'Please input your username!' 
        }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />

      </Form.Item>


      <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
        <Checkbox>Remember me</Checkbox>
      </Form.Item>
      {
        authError && <p>
          {authError.message}
        </p>
      }
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Signin
        </Button>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Link onClick={()=> { navigate("/signup")}}>
Signup if you have an invite
      </Link>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
      <Link onClick={()=>  navigate("/forgot")} >
Forgot password    
</Link>
      </Form.Item>
    </Form>
      </Col>
      <Col span={300}>
      <img
        src={SearchGalaxyImg}
        alt="Thing"
        width={300}
        style={{
            cursor: 'pointer',
            margin: 4,
            borderRadius: 4
        }}
        />
      </Col>
    </Row>
    </Card>
        <Footer/>
    </div>
  );
};



export default LoginPage;

