import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppState } from '../../state';
import {doc} from 'firebase/firestore';
import { Button, Avatar, Badge} from 'antd';
import {  CompassOutlined, GoldOutlined, MessageOutlined, NotificationOutlined, PoweroffOutlined, SearchOutlined } from '@ant-design/icons';
import ConceptModal from '../Concepts/ConceptsModal';
import SearchModal from '../SearchEntity/SearchModal';
import { setSeenOnboarding, getUserState } from '../../hooks/user/handleUserState';
import addStringToArrayField from '../../hooks/firestoreFns/addStringToArrayField';
import removeStringFromArrayField from '../../hooks/firestoreFns/removeStringFromArrayField';
const VellbyLogo = '/images/vellby_logo.svg'

const NavGlobal = () => {

  // TODO Messages Notifications.
  const { signOut, userEntity, db, notifications } = useAppState();
  const notificationCount = notifications.filter((n:any) => !n.clicked).length;
  const userId = userEntity?.id; 
  let navigate = useNavigate();

  // console.log('userEntity', userEntity)
  const [openConcept, setOpenConcept] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const [firstTimeUser, setFirstTimeUser] = React.useState(false);

  useEffect(()=> {

    if (userId){
      getUserState(db, userId).then((res:any) => {
        // console.log('res', res)
        setFirstTimeUser(!res.seenOnboarding);
      }).catch((err:any) => {
        console.log('err', err)
      })
    }
  }, [userId, db])

  useEffect(() => {
    
    if (firstTimeUser) {
      console.log("first time user", firstTimeUser)
      setOpenConcept(true);
    }
  }, [firstTimeUser])

  const handleSignOut = () => {
    signOut?.();
    console.log('clicked navigate to signin')
    navigate('/signin');
  };

  if (userEntity === null) {
    return <div style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid rgba(0, 0, 0, 0.05)',
      borderRadius: 8,
      backgroundColor: '#fff',
  }}>
      <img
          src={VellbyLogo}
          alt="Vellby"
          width={32}
          style={{
            cursor: 'pointer',
            margin: 16
          }}
          onClick={() => navigate('/')}
        />
    </div>
  }

  else {
    // console.log(userEntity);
  }

  return (<React.Fragment>
      <ConceptModal
        open={openConcept}
        firstTimeUser={firstTimeUser}
        onClose={() => {
          if (firstTimeUser) {
            setSeenOnboarding(db, userEntity?.id);
            setFirstTimeUser(false);
          }
          setOpenConcept(false);
        }}
      />
      <SearchModal open={openSearch} onClose={() => {
        setOpenSearch(false);
      }}
      />
      <div style={{
        padding: 4
      }}>

<div
      style={{
        backgroundColor: '#fff',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 8,
      }}>

        <img
          src={VellbyLogo}
          alt="Vellby"
          width={32}
          style={{
            cursor: 'pointer',
            margin: 16
          }}
          onClick={() => navigate('/')}
        />

        <Button 
          style={
            {
              margin: 20,
            }
          }
          onClick={() => {
            console.log('clicked search')

            setOpenSearch(true);
            if (userId) {
              let docRef = doc(db, 'userState', userId);
              removeStringFromArrayField(docRef, 'onboardedSteps', 'search');
              addStringToArrayField(docRef, 'onboardedSteps', 'search');
            }
          }
          }
          icon={<SearchOutlined />}
          type='text'

        >  search </Button>
   
        <Button 
            style={
              {
                margin:16,
                marginLeft: 'auto'
              }
            }
            onClick={()=> {
              setOpenConcept(true);
              if (userId) {
                let docRef = doc(db, 'userState', userId);
                removeStringFromArrayField(docRef, 'onboardedSteps', 'concept');
                addStringToArrayField(docRef, 'onboardedSteps', 'concept');
              }
            }} 
            icon={<CompassOutlined />} 
          />
        <Button.Group 
          style={{
            marginTop: 16,            

          }}
        >


            <Button 
              onClick={() => navigate(`/entity/${userEntity?.id}`)} 
              icon={<Avatar size={20} src={userEntity?.photoURL}>  </Avatar>}
            />
            <Button 
              onClick={() =>{
                navigate('/sponsor')
              }}
              icon={<GoldOutlined />}
            />
            <Button 
              onClick={() =>{
                navigate('/chat')
              }} 
              icon={<MessageOutlined />}
            /> 
            <Button 
              onClick={() =>{
                navigate('/notifications')
              }} 
              icon={<Badge count={notificationCount} size='small'> <NotificationOutlined /></Badge>}
            /> 
  
        </Button.Group>
        <Button
            style={
              {
                margin: 16,
              }
            }
              onClick={handleSignOut} 
              icon={<PoweroffOutlined />}
            />
      </div>
      </div>

      </React.Fragment>
  );
};


export default NavGlobal;

/*

                        <Button 
              onClick={() =>{
                navigate('/insights')
              }} 
              icon={<BulbOutlined />}
            /> 
            
                        <Button 
              onClick={() =>{
                navigate('/ties')
              }} 
              icon={<BuildOutlined />}
            /> 

*/