import G6 from '@antv/g6';
import {  EntityType } from '../../types';
import { ColorExp, SizeExp } from './settings';
import { insertCss } from 'insert-css';
insertCss(`
  .g6-tooltip {
    border-radius: 4px;
    font-size: 12px;
    color: #000;
    padding: 10px 8px;
    margin: 0;

  }
`);
export const createGraph = (
      containerName: string, 
      centerNode: string, 
      centerNodeName: string,
      centerEntityType: EntityType,
      centerPhotoURL: string,
      setSelectedNode: any,
      setGraph: any
    ) => {


    /*const toolbar = new G6.ToolBar({
        offsetX: 10,
        offsetY: 10,
    }); */

    const graph  = new G6.Graph({
        container: containerName, // String | HTMLElement, required, the id of DOM element or an HTML node
        width: 800, // Number, required, the width of the graph
        height: 420, // Number, required, the height of the graph
        //plugins: [toolbar],
        layout: {
            type: 'force',
            preventOverlap: true,
            linkDistance: 100,
            nodeStrength: 2,
            edgeStrength: 3,
            collideStrength: 0.8,
            nodeSize: 30
        },
        animate: true,
        modes: {
          default: [ 
            'click-select',
            'drag-canvas', 
            'drag-node',
            {
              type: 'tooltip',
              formatText(model) {
                const text = `${model.name}`;
                // console.log("tooltip", model)
                return text;
              },
              shouldUpdate: (e) => true,
            },
           ]
        }
    });

    function refreshDragedNodePosition(e:any) {
        const model = e.item.get('model');
        model.fx = e.x;
        model.fy = e.y;
      }

      /*
        graph.on('aftergraphrefresh', (e:any) => {
          graph.updateLayout();
        })
      */

      graph.on('node:', (e:any) => {
        // Relayout when dragging the node

        graph.layout();
        refreshDragedNodePosition(e); 
      });

      graph.on('node:click', (e: any) => {
        console.log("node clicked clicked")
        const nodeItem = e.item; // Get the clicked node item
        
        console.log(nodeItem.getModel().id)
        setSelectedNode(nodeItem.getModel().id);
      });
      
      graph.data({
        nodes: [
          {
            id: centerNode,
            img: centerPhotoURL,
            name:  `<div style="display:'flex',flex-direction:'row'"><img src=${centerPhotoURL} width="50" height="50" style="border-radius: 50%;"/> <div style="margin-bottom: 16"> ${centerNodeName} (center node) </div> </div>`,
            color: "#000",
            size: SizeExp['center'],
            style:  {
              fill: ColorExp[centerEntityType],
            },
          }
        ]
      });

      graph.render();
      console.log("graph created with centernode",  centerNode )
      setGraph(graph);
}


