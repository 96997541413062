import { doc, setDoc, getDoc, Timestamp } from "firebase/firestore"; 


export const setNewUserState = async (db: any, userId: string) => {
    console.log('new user', userId)
    const userRef = doc(db, "userState", userId);
    await setDoc(userRef, {
        accountCreated: Timestamp.now(),
        status: 'newUser',
        seenOnboarding: false,
    });
};

export const setSeenOnboarding = async (db: any, userId:string) => {
    console.log("seen")
    const userRef = doc(db, "userState", userId);
    await setDoc(userRef, {
        seenOnboarding: true,
    }, {merge: true});
}

export const getUserState = async (db: any, userId: string) => {
    const userRef = doc(db, "userState", userId);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
        return userDoc.data();
    } else {
        return null;
    }
}

