import React from "react";
import {  Modal, Button } from "antd";
import EntitySummary from "../EntitySummary/EntitySummary";
import getAllDocsFromCollection from "../../hooks/firestoreFns/getAllDocsFromCollection";
import { useAppState } from "../../state";
import PaymentSummary from "../Payment/PaymentSummary";
import { PlusCircleOutlined } from "@ant-design/icons";
const TransparencyReport = (props:{
    entityID: string,
    isFirstDegreeAdmin?: boolean
}) => {
    const [visible, setVisible] = React.useState(false);

    const { db} = useAppState();
    const [payments, setPayments] = React.useState<any[]>([]);
    React.useEffect(() => {
        console.log('props.entityID', props.entityID);
        getAllDocsFromCollection( db, `support/${props.entityID}/payment`).then((docs) => {
            setPayments(docs);
        }).catch((error) => {
            console.log(error);
        });
    }, [props.entityID, db]);

    return <React.Fragment>
        <Modal open={
                    visible
                } onCancel={
                    () => setVisible(false)
                }
                closable={false} footer={[]}>

            <h4> Transparency report </h4>
                <EntitySummary onlyName entityID={props.entityID}/> recieves 0 SEK is montly support.
                <br/><br/>
                { props.isFirstDegreeAdmin && <Button size="small" disabled icon={<PlusCircleOutlined />}> amount raised externally </Button>}
                <br/>
                <br/>
                {
                    payments.map((payment) => {
                        console.log('payment', payment);
                        return <React.Fragment>
                            <PaymentSummary paymentID={payment.id} sourceEntity={props.entityID}/>
                        </React.Fragment>
                    })
                }
                <br/>
                <br/>
                Links to expense report / documents of how 0 SEK is spent every month should have been attached as a thing entity called "support expense report". 
                <br/>
                <br/>
        </Modal>
            <Button size='small' type='text' onClick={
                () => setVisible(true)
            } >
                View Transparency Report 
            </Button>
    </React.Fragment>
}
export default TransparencyReport;
