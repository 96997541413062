import React from "react";
import NavGlobal from "../NavGlobal/NavGlobal";

const TiesPage = () => {
    return <React.Fragment> 
        <NavGlobal />
        Ties Page 
    </React.Fragment>
}

export default TiesPage;