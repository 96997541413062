
const buildSearchKeywords = (name: string, description: string) => {
    let searchKeywords = name?.split(' ')
    let descriptionKeywords = description?.split(' ').filter((word) => word[0] === '#');
    searchKeywords = searchKeywords?.concat(descriptionKeywords || []);
    searchKeywords = searchKeywords?.map((word) => word.toLowerCase());
    searchKeywords = searchKeywords?.filter((word) => word.length > 2);
    return searchKeywords;
}

export default buildSearchKeywords;
