import { Tabs, Card, Button, Typography } from "antd";
import React from "react";
import NavGlobal from "../NavGlobal/NavGlobal";
import RenderNotification from "./RenderNotification";
import setClicked from "../../hooks/notifications/setClicked";
import { useAppState } from "../../state";
import { MessageOutlined } from "@ant-design/icons";

const NotificationPage = () => {
   const {notifications, userEntity, db}  = useAppState();
   const {Text} = Typography;

    return <div>
         <NavGlobal />
         <Card style={{
               marginLeft: 32,
               marginRight: 32,
               marginTop: 8,
            }}
            bordered={false}
            actions={[

            ]}
         >
            <Text mark> notifications will soon be moved inside comms <MessageOutlined />  </Text>
            <Tabs 
               items={
                  [
                     {"label" : "all", "key": "all", "children": <div>
                        <Button 
                           size='small'
                           type='default'
                           onClick = {
                              () => {
                                 notifications.forEach((notification:any) => {
                                    userEntity && setClicked( db, userEntity.id, notification.id);
                                 })
                                 window.location.reload();
                              }
                           }
                        > mark all as read </Button>
                        {notifications?.map((notification:any) => {
                           return <RenderNotification notification={notification}/>
                        }
                        )}
                     </div>},
                     {
                        "label" : "ties", "key": "connections", "children":  <div>
                           {  
                              notifications?.filter(
                                 (notification:any) => notification.eventType ==="tie" )
                                 .map((notification:any) => {
                                    return <RenderNotification notification={notification}/>
                                 }
                              )
                           }
                        </div>
                     },
                     {"label" : "sponsor", "key": "sponsor", "children": <div> 
                     {notifications?.filter(
                        (notification:any) => notification.eventType ==="support" )
                        .map((notification:any) => <RenderNotification notification={notification}/>)
                     }
                     </div>
                     },
                     {"label" : "chats & insights", "key": "messages", "children": <div>       
                        {
                           notifications?.filter(
                              (notification:any) => notification.eventType ==="chat" )
                              .map((notification:any) =>  <RenderNotification notification={notification}/>)
                        }
                     </div>}
                  ]
               }
            />
         </Card>
      </div>
}

export default NotificationPage;
