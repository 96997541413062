import React, { useEffect } from "react";
import { Card, Spin } from "antd";

const LoadingPage = () => {
    useEffect(() => {
        console.log("test")
    setTimeout(() => {
        console.log("test 2")
        window.location.href = "/";
    }, 1000);
    }, []);
    return (<Card>
        <Spin size="large"/>
    </Card>

    );
};

export default LoadingPage;