import React from "react";
import { useAppState } from "../../state";
import { DirectedConnectionInterface } from "../../types";
import { collection, query, where, onSnapshot  } from "firebase/firestore";

import { createOtherID } from "./useGetEntityConnections";



export default function useGetCreatedPendingConnections() {
    
    const { db, userEntity } = useAppState();
    const userID = userEntity?.id;

    const [entityConnections, setEntityConnections] = React.useState<DirectedConnectionInterface[]|undefined>();


    React.useEffect(() => {
        if  (userID === undefined ){
            return undefined;
        }
        const q = query(collection(db, "connections"),  where( "creatorID", "==", userID));
        const unsub = onSnapshot(q, (data:any) => {
            let newData = data.docs.filter((doc:any) => {
                const docData = doc.data()
                if (docData === undefined){
                    return false;
                }
                const entityIDs = docData.entityIDs;

                if (entityIDs.length !== 2){
                    return false;
                }

                return ((docData[entityIDs[0]] === false) || (docData[entityIDs[1]] === false));

            }).filter(
                (doc:any) => {

                    console.log("evaluating onbehalf ID ", doc.data());
                    console.log("evaluating onbehalf ID : filter ", doc.data().createdOnBehalfID  ) 
                    return  (doc.data()?.createdOnBehalfID !== undefined)
                }
            ).map((doc:any) => {

                
                console.log("evaluating onbelf ID : inside map ", { 
                    id: doc.id,
                    entityIDs: doc.data()?.enitityIDs,
                    otherEntityID:createOtherID( doc.data()?.entityIDs, doc.data()?.createdOnBehalfID),
                    ...doc.data()
                } );
                return { 
                id: doc.id,
                entityIDs: doc.data()?.enitityIDs,
                otherEntityID: createOtherID( doc.data()?.entityIDs, doc.data()?.createdOnBehalfID),
                ...doc.data()
            } 
        });

            console.log("evaluating onbelf ID ; outside map", newData)
            setEntityConnections(newData);
        });

        return unsub;
    
    }, [userID, db]);

    return entityConnections;
}