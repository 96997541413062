import React from "react";
import { Card, Space, Button, Select, Tooltip } from "antd";
import { EntityInterface } from "../../types";
import { MessageOutlined } from "@ant-design/icons";
import EntitySummary from "../EntitySummary/EntitySummary";
import HelpButton from "../Concepts/HelpButton";

const Comms = (props:{
    entityID: string| undefined,
    entityDetails : EntityInterface | undefined,
    admin: boolean | undefined,
}) => {

    return <React.Fragment> 
    { 
        ( 
            props.entityDetails?.entityType === 'context' 
            || 
            props.entityDetails?.entityType ==='relationship' 
        ) &&
        <Card style={{
            marginLeft: 32,
            marginRight: 32,
            marginTop: 4
        }}>
            <Space> 
                <MessageOutlined /> 
                Comms in <EntitySummary entityID={props.entityID} onlyName />
                <HelpButton helpKey={'comms'}/>
            </Space>
            <br/>
            <br/>
            <Space direction="vertical"> 
            {
                props.admin && 
                <>

              <Tooltip
                placement="topLeft" 

                title={ <>
                    <MessageOutlined 
                    size={16} 
                    style={{
                        marginRight: 4
                    }}/> 
                     Internal comms is an admin only channel for Insight & Notifications alongside chat messages from other admins.                
                    </>}>
               
            <Button size="small" type="default" onClick={
                () => {
                    window.open(`${window.location.origin}/chat/${props.entityID}`, '_blank')
                }
            }> internal comms </Button>
            </Tooltip>
            </>
        }

            
        {
            props.entityDetails?.externalCommsID ?
            <EntitySummary onlyName entityID={props.entityDetails?.externalCommsID} />:
            "no external communication channel set by admins yet."
        }

        {
            props.admin && !(props.entityDetails?.externalCommsID) && <>
                <br/>
                set a thing entity as communication channel for all members : 
                <Select size='small' value={'under implementation'}> </Select>
            </>

        }
            </Space>
            <br/>
        </Card>
    }
    </React.Fragment>
    

};

/*
                <Tooltip 
                placement="topLeft" 
                title={ <>
                    <MessageOutlined 
                    size={16} 
                    style={{
                        marginRight: 4
                    }}/> 
                     Internal comms is an admin only channel for Insight & Notifications alongside chat messages from other admins.                
                    </>}>
                <InfoCircleOutlined/>
            </Tooltip>
*/
export default Comms;