import React from "react";
import { Space } from "antd";
import { useAppState } from "../../state";
import useGetIsAdmin from "../../hooks/getDetails/useGetIsAdmin";
import VisibilityToggle from "../Visibility/VisibiltyToggle";
import useGetConnectionDetailsFromId from "../../hooks/getDetails/useGetConnectionDetailsFromId";
import SeparateButton from "../TieButtons/SeparateButton";
import AcceptButton from "../TieButtons/AcceptButton";
import AdminToggleButton from "../AdminToggle/AdminToggle";


const EditConnection = (props: {
    connectionID: string;
    sourceID: string | undefined;
    targetID: string | undefined;
}) => {
    const { connectionID, sourceID, targetID } = props;
    const { userEntity } = useAppState();
    const isAdmin = useGetIsAdmin(sourceID, userEntity?.id, true);
    const connObj = useGetConnectionDetailsFromId(connectionID)

    const pending = sourceID 
        && targetID
        && connObj
        && (
            connObj[sourceID] !== true 
            || 
            connObj[targetID] !== true
        );
    
    if (sourceID === undefined) {
        return <></>;
    }

    if (!isAdmin) {
        return <>user does not seem to have right privileges </>;
    }

    if (pending) {
        return  <Space size='large' style={{marginLeft: 8}}>
        <SeparateButton 
            connectionID={connectionID}
            yourID={sourceID}
            otherID={targetID}
        />
        <AcceptButton 
            connectionID={connectionID}
            yourID={sourceID}
        />
    </Space>
    }
    /*
            <ConnectionPinToggle 
                connectionID={connectionID}
                pinToEntityID={sourceID}
            /> 
    */
    return <Space size='large' style={{marginLeft: 8}}>


            <VisibilityToggle 
                key={connObj?.id}
                collectionName='connections'
                docID={connectionID}
                docDetails={connObj}
            />
            <AdminToggleButton 
                connectionID={connectionID}
                sourceID={sourceID}
                targetID={targetID}
            />
            <SeparateButton 
                connectionID={connectionID}
                yourID={sourceID}
                otherID={targetID}
            />
            <AcceptButton 
                connectionID={connectionID}
                yourID={sourceID}
            />
        </Space>;
};

export default EditConnection;