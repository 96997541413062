import React from "react";
import { useAppState } from "../../state";
import addToSource from "../../hooks/buildRecipies/createAndAddEntity";
import { Button, List, Spin, Typography } from "antd";
import EntitySummary from "../EntitySummary/EntitySummary";
// import addChainToSource from "../../hooks/buildRecipies/createAndAddChain";
import useGetEntityConnections from "../../hooks/getConnections/useGetEntityConnections";
import addConnection from "../../hooks/buildFns/addConnection";
import { PlusCircleOutlined } from "@ant-design/icons";

const ParsedEntity = (props: {
    item: any,
    sourceID: string,
}) => {

    const { Text } = Typography;
    const { item, sourceID } = props;
    const { db, userEntity } = useAppState();
    const [adding, setAdding] = React.useState<boolean>(false);
    const firstDegreeConnections = useGetEntityConnections(sourceID, {
        entityTypes: ['context'],
        adminOnly: true,
        removeHidden: false,
        removePending: true
    });

    // console.log(firstDegreeConnections);

    let contextTags = item.contextTags ? item.contextTags : [];
    contextTags = contextTags.filter((tag:any) => tag.length > 2);
    let contextTagIDMaps = {}
    firstDegreeConnections?.filter((connection:any) => {
        console.log('name',connection.otherEntityDetails.name, connection.otherEntityDetails.name in contextTags)
        return contextTags.includes(connection.otherEntityDetails.name) ;
    }).forEach((connection:any) => {
        contextTagIDMaps = {
            ...contextTagIDMaps,
            [connection.otherEntityDetails.name]: connection.otherEntityID
        }
    })
    const newContexts = contextTags.filter((tag:any) => {
        console.log('contextTagIDMAps keys', Object.keys(contextTagIDMaps));
        return !Object.keys(contextTagIDMaps).includes(tag);
    })
    
        const addSingleContext = (contextTag: string) => {
            userEntity?.id && addToSource(
                sourceID,
                {
                    name: contextTag,
                    description: '...',
                    email: '',
                    webLink : '',
                    entityType: 'context',
                    id: 'unassigned',
                    photoURL: 'unassigned',
                    creatorID: userEntity?.id,
                },
                userEntity?.id ,
                true,
                db,
                ()=>{}
            )
        } 
    
    const addIntermediateContexts = () => {
        newContexts.forEach((contextTag:any) => {
            userEntity?.id && addToSource(
                sourceID,
                {
                    name: contextTag,
                    description: '...',
                    email: '',
                    webLink : '',
                    entityType: 'context',
                    id: 'unassigned',
                    photoURL: 'unassigned',
                    creatorID: userEntity?.id,
                },
                userEntity?.id ,
                true,
                db,
                ()=>{})
            })
    }


    /*
    const addToContextTagIDMaps = (newID: string, name: string) => {
        contextTagIDMaps = {
            ...contextTagIDMaps,
            [name]: newID
        }
    }  
    */

    console.log('contextTags', contextTags);
    console.log('contextTagIDMaps', contextTagIDMaps);
    console.log('newContexts', newContexts);

    const [addedEntity, setAddedEntity] = React.useState<{
        entityID: string,
        entityExists: boolean,
        connectionExists: boolean
      }|undefined>(undefined);

    const afterAdding = (newID: string, entityExists: boolean, connectionExists: boolean ) => {
        console.log("afterAdding", newID, entityExists, connectionExists);
        setAddedEntity({
            entityID: newID,
            entityExists: entityExists,
            connectionExists: connectionExists
        });
        setAdding(false);
    }

    if (adding) {
        return <List.Item
                    key={item.name}
                    style={{ padding: 16 }}
                >
                    <Spin />
                </List.Item>
    }
    
    if (addedEntity !== undefined ){
        return  <List.Item>
            <EntitySummary entityID={addedEntity.entityID} lean 
            onClick={
                () => {
                    window.open(`/entity/${addedEntity.entityID}/list`, '_blank');
                }
            }
            />
        {addedEntity.entityExists && <Text code> entity exists </Text>}
        {addedEntity.connectionExists && <Text code> already connected </Text>}
        </List.Item>
    }
    
    return <>
        <List.Item
            key={item.name}
            style={{ padding: 16 }}
            actions={[ 
                        ] }
          >
            <Text code>name</Text> : <Text code>{item.name}</Text> <br/>
              <Text code>description</Text> : <Text code>{item.description}</Text> <br/>
              <Text code>entityType</Text> : <Text code>{item.entityType}</Text> <br/>
             {item.email ? <> <Text code>email</Text> : <Text code>{item.email}</Text> </> : ''}
             {item.webLink ? <> <Text code>link</Text> : <Text code>{item.webLink}</Text> </> : ''}

             <br/>    
             { 
              contextTagIDMaps && Object.keys(contextTagIDMaps).length > 0 &&<>
                  <Text  code >
                  contextTags 
                </Text>:
              {Object.keys(contextTagIDMaps).map((key:any) => {
                return <Text code>
                    {key}
                </Text> 
              })}
              </>
            }
            <br/>

            { 
                newContexts && Object.keys(newContexts).length > 0 &&<>
                        pending intermediate contexts:

    
                    {newContexts.map((key:any) =>                    <Button
                    size='small'
                    style={{
                        margin:4
                    }}
                    onClick={() => addSingleContext(key)}
                    icon={<PlusCircleOutlined/>}
                    >
                                {key}
                    </Button>   )
                    }
                </>
            }
            {
                newContexts && Object.keys(newContexts).length > 1 &&<>
                <Button icon={<PlusCircleOutlined/>}
                onClick={() => addIntermediateContexts()}
                size='small'
                style={{
                    margin:4
                }}
                > add all</Button></>
            }
            <br/><br/>
                <Button 
                    type='default'
                    disabled= {newContexts && Object.keys(newContexts).length !== 0 }
                        
                    onClick={ () => {
                        
                        console.log("before onclick", userEntity?.id);
                        console.log("before onclick", sourceID);
                        const addAsAdmin = item.entityType === 'person' ? false : true; 

                        if(Object.values(contextTagIDMaps).length === 0 ){
                            userEntity?.id  && addToSource(
                                sourceID,
                                {
                                    name: item.name,
                                    description: item.description,
                                    email: item.email ? item.email : '',
                                    webLink : item.link ? item.webLink : '',
                                    entityType: item.entityType,
                                    id: 'unassigned',
                                    photoURL: 'unassigned',
                                    creatorID: userEntity?.id,
                                },
                                userEntity?.id,
                                addAsAdmin,
                                db,
                                afterAdding
                            )
                        }
                        
                        if(Object.values(contextTagIDMaps).length > 0 ){
                            const contextTagID = Object.values(contextTagIDMaps)[0];
                            userEntity?.id && contextTagID && addToSource(
                                contextTagID,
                                {
                                    name: item.name,
                                    description: item.description,
                                    email: item.email ? item.email : '',
                                    webLink : item.link ? item.link : '',
                                    entityType: item.entityType,
                                    id: 'unassigned',
                                    photoURL: 'unassigned',
                                    creatorID: userEntity?.id,
                                },
                                userEntity?.id,
                                addAsAdmin,
                                db,
                                (newID: string, entityExists: boolean, connectionExists: boolean ) => {
                                    afterAdding(newID, entityExists, connectionExists );
                                    const remainingContextTags = Object.values(contextTagIDMaps).slice(1);
                                    console.log('remainingContextTags', remainingContextTags);
                                    remainingContextTags.forEach((tagID:any) => addConnection(
                                            db,
                                            tagID,
                                            true,
                                            newID,
                                            addAsAdmin,
                                            userEntity?.id,
                                            true                                
                                        )
                                    )
           

                                }
                            )
                        }



                            // fetch non-exsitent context tags
                                
                            // create them
                            // add them to source . and get the map updated.
                            // iterate through the maps and add connections.
                            // if contextTagIDMaps
                        
                        /*
                        userEntity?.id && addToSource(
                            sourceID,
                            {
                                name: item.name,
                                description: item.description,
                                email: item.email ? item.email : '',
                                webLink : item.link ? item.link : '',
                                entityType: item.entityType,
                                id: 'unassigned',
                                photoURL: 'unassigned',
                                creatorID: userEntity?.id,
                            },
                            userEntity?.id,
                            addAsAdmin,
                            db,
                            afterAdding
                        )
                        setAdding(true); */
                    }
                }> create and tie. </Button>
                 {newContexts && Object.keys(newContexts).length !== 0 && 
                    <Text mark> add intermediate contexts above to create this entity</Text>
                  }
                

          </List.Item>
    </>

}

export default ParsedEntity;