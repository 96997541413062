import React from 'react';
import { Form, Input, Typography } from 'antd';
import { useAppState } from '../../state';
import { Card, Row, Col, Button } from 'antd';
import NavGlobal from '../NavGlobal/NavGlobal';
import Footer from '../NavGlobal/Footer';
import useSendMessage from '../../hooks/monitoring/useSendMessage';

const SearchGalaxyImg = "./images/search_telescope.png";

const ForgotPage: React.FC = () => {

  const { Text } = Typography;
  const { changePassword,db } = useAppState();
  const [ emailSent, setEmailSent ] = React.useState(false);
  const [error , setErrorInfo] = React.useState("");
  useSendMessage(db, {"message": "forgot page loaded"});

  const onFinish = (values: any) =>{
    console.log("form finish values", values);
    changePassword(values.email).then((res:any) => {
      console.log("changePassword", res);

        setErrorInfo("");
        setEmailSent(true);

    }).catch((err:any) => {
      console.log("changePassword", err);
      setErrorInfo(err.message);
    });

  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    setErrorInfo(errorInfo);
  };
  
  return (<div style={{
    padding: 8,
    height: '100vh',
  }}>
    <NavGlobal />
    <Card style={{
        marginTop: 8,
        marginRight:  32,
        marginLeft: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      bordered={false}
      >
      <Row>
      <Col span={300} style={{
        margin: 24
      }}>
      <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="email"
        name="email"
        rules={[{ 
          required: true, 
          message: 'Please input your email!' 
        }]}
      >
        <Input />
      </Form.Item>
       <Text type="danger" >{error}</Text> 
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        { emailSent ? <Button disabled htmlType="submit">
                      reset link has been sent 
                    </Button>
                    :<Button type="primary" htmlType="submit">
                    email reset link
                  </Button> 
        }
      </Form.Item>

    </Form>

      </Col>
      <Col span={300}>
      <img
        src={SearchGalaxyImg}
        alt="Thing"
        width={300}
        style={{
            cursor: 'pointer',
            margin: 4,
            borderRadius: 4
        }}
        />
      </Col>
    </Row>
    </Card>
      <Footer/>
    </div>
  );
};



export default ForgotPage;

