import { getDocs, collection } from "firebase/firestore"

const getAllDocsFromCollection = async (db: any, collectionPath: any) => {

    const collectionRef = collection(db, collectionPath);
    return await getDocs(collectionRef).then((querySnapshot) => {
        let docs: any[] = [];
        querySnapshot.forEach((doc) => {
            docs.push(doc);
        });
        return docs;
    })

 

}

export default getAllDocsFromCollection;