import React from 'react';
import { Tabs } from 'antd';
import { RulesCard } from "./RulesModal";
import Intro from './ConceptTabs/Intro';
import EntitiesAndTies from './ConceptTabs/EntitiesAndTies';
import Stochasticity from './ConceptTabs/Stochasticity';
import Communities from './ConceptTabs/Communities';

const onChange = (key: string) => {
  console.log(key);
};

const tabChildren = {
  "intro":  <Intro />,

"ties": <>
<b>Build Ties</b>
<br/>
Ties are the links between entities. 
Building these ties requires the user to follow certain rules.
<br/>
Click on the different entity types below to see the relevant rules.

<RulesCard showSelector entityType='relationship'/>
</>,
"entities":<EntitiesAndTies />,
"stochasticity": <Stochasticity />,
"communities": <Communities />,
"invite": <img
width={280}
src={"/images/invite.png"}
alt="Invite"
/>,
"insights": <img
width={280}
src={"/images/insights.png"}
alt="Insights"
/>,
"support": <img
width={280}
src={"/images/support.png"}
alt="Support"
/>,
"hide": <img
width={280}
src={"/images/hide.png"}
alt="Hide"
/>,
"admin": <img
width={280}
src={"/images/admin.png"}
alt="Admin"
/>,
"notifications": <img
width={280}
src={"/images/notifications.png"}
alt="Notifications"
/>,
"search": <img
width={280}
src={"/images/search.png"}
alt="Search"
/>,
"graph_view": <img
width={280}
src={"/images/graph_view.png"}
alt="graph view"
/>,


}

const ConceptTabs: React.FC = () => (
  <Tabs
    onChange={onChange}
    tabPosition='left'
    items={
      [
        {"label" : "intro & philosophy", "key": "intro", "children": tabChildren["intro"]},
        {"label" : "platform basics", "key": "entities", "children": tabChildren["entities"]},
        {"label" : "discovery engine", "key": "stochasticity", "children": tabChildren["stochasticity"]},
        {"label" : "ecology of ideas", "key": "support", "children": tabChildren["communities"]}
      ]
  }/>
);

/*
        {"label" : "intro & philosophy", "key": "intro", "children": tabChildren["intro"]},
        {"label" : "building entities & ties", "key": "entities", "children": tabChildren["entities"]},
        {"label" : "build ties", "key": "ties", "children": tabChildren["ties"]},
        {"label" : "import & invite", "key": "invite", "children": tabChildren["invite"]},
        {"label" : "hide",  "key": "hide", "children": tabChildren["hide"]},
        {"label" : "1°, 2° admins", "key": "admin", "children": tabChildren["admin"]},
        {"label" : "logical types & stochasticity", "key": "graph view", "children": tabChildren["graph_view"]},
        {"label" : "insights, search & graph view", "key": "graph view", "children": tabChildren["graph_view"]},
        {"label" : "search & insight engines", "key": "insights", "children": tabChildren["insights"]},
        {"label" : "search", "key": "search", "children": tabChildren["search"]},
        {"label" : "engagement & notifications", "key": "notifications", "children": tabChildren["notifications"]},
        {"label" : "community supported", "key": "support", "children": tabChildren["support"]},
*/

/*
  <Tabs
    onChange={onChange}
    tabPosition='left'
    items={
      [{ "label" : "intro", "key": "intro", "children": tabChildren["intro"]},
      { "label" : "entities", "key": "entities", "children": tabChildren["intro"]},
      { "label" : "rules", "key": "rules", "children": tabChildren["rules"]},
      { "label" : "hide entity", "key": "hide_entity", "children": tabChildren["intro"]},
      { "label" : "hide tie", "key": "hide_tie", "children": tabChildren["intro"]},
      { "label" : "1°, 2° admins", "key": "admin", "children": tabChildren["intro"]},
      { "label" : "invite link", "key": "invite_link", "children": tabChildren["intro"]},
      { "label" : "insights", "key": "invite_code", "children": tabChildren["intro"]},
    ]
  }/>
*/


export default ConceptTabs;