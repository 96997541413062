import React from "react";
import { RulesCard } from "../RulesModal";
import { Tabs } from "antd"

const onChange = (key: string) => {
  console.log(key);
};

const tabChildren = {
  "entities":    <img 
  width={280}
  src={"/images/entities.png"} 
  alt="Entities"
  />,
"ties": <>
<b>Build Ties</b>
<br/>
Ties are the links between entities. 
Building these ties requires the user to follow certain rules.
<br/>
Click on the different entity types below to see the relevant rules.
</>,
"invite": <img
width={280}
src={"/images/invite.png"}
alt="Invite"
/>,
"insights": <img
width={280}
src={"/images/insights.png"}
alt="Insights"
/>,
"rules": <RulesCard showSelector entityType='relationship'/>
,
"support": <img
width={280}
src={"/images/support.png"}
alt="sponsor"
/>,
"comms": <img
width={280}
src={"/images/comms.png"}
alt="comms"
/>,
"hide": <img
width={280}
src={"/images/hide.png"}
alt="Hide"
/>,
"admin": <img
width={280}
src={"/images/admin.png"}
alt="Admin"
/>,
"notifications": <img
width={280}
src={"/images/notifications.png"}
alt="Notifications"
/>,
"search": <img
width={280}
src={"/images/search.png"}
alt="Search"
/>,
"graph_view": <img
width={280}
src={"/images/graph_view.png"}
alt="graph view"
/>
}



const EntitiesAndTies = () => {

  return  <>
    <Tabs
    onChange={onChange}
    tabPosition='left'
    items={[
      {"label" : "entities", "key": "entities & ties", "children": tabChildren["entities"]},
      {"label" : "rules", "key": "rules", "children": tabChildren["rules"]},
      {"label" : "invite", "key": "invite", "children": tabChildren["invite"]},
      {"label" : "hide", "key": "intro", "children": tabChildren["hide"]},
      {"label" : "admin", "key": "admin", "children": tabChildren["admin"]},
      {"label" : "sponsor", "key": "support", "children": tabChildren["support"]},
      {"label" : "comms", "key": "comms", "children": tabChildren["comms"]},
    ]
    }>
    </Tabs>

  </>
};

export default EntitiesAndTies;