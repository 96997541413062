import { getDocs, query, collection, where } from "firebase/firestore";
import validateEmail from "../utils/validateEmail";

const getEmailDoc = async (db: any, email: string | undefined) => {
    
    if (!validateEmail(email)) {
        return;
    }

    //@ts-ignore
    const q = query(collection(db, "emails"), where( "email", "==", email));
    const emailDoc =  await getDocs(q).then(
        (data: any) => {
            const querySnapshot = data.docs;
            console.log(querySnapshot);
            if (querySnapshot.size === 0){
                return null;
            }
            if (querySnapshot.size > 1){
                console.log("Error: multiple entityIDs for email");
                return null;
            }
            return querySnapshot[0];
        }
    ).catch(err => {
        console.log(err);
    });
    
    console.log(emailDoc);
    return emailDoc;
    
}

export default getEmailDoc;