import { addDoc } from "firebase/firestore"

const addNewDoc = async (collectionRef: any, data: any) => {
    const docRef = await addDoc(collectionRef, data).catch((e:any) => {
        console.error("Error adding document: ", e);
    });
    return docRef;
}

export default addNewDoc;
