import React from "react"
import { Button, Modal } from "antd"
import { 
  CompassOutlined, 
  InfoCircleOutlined, 
  QuestionCircleOutlined 
} from "@ant-design/icons"

const helpDict:any = {
  admin: {
    title: "admin",
    child : <img
    width={280}
    src={"/images/admin.png"}
    alt="Invite"
    />
  },
  sponsor: {
    title: "sponsor",
    child : <img
    width={280}
    src={"/images/support.png"}
    alt="sponsor"
    />
  },
  comms: {
    title: "comms",
    child : <img
      width={280}
      src={"/images/comms.png"}
      alt="comms"
    />
  },
  logical_types: {
    title: "logical_types",
    child : <img
      width={280}
      src={"/images/logical_types.png"}
      alt="logical_type"
    />
  },
  hintConcept: {
    title: "hintConcept",
    child : <div>
      click on the <CompassOutlined/> button in the navigation bar.
    </div>
  },
  hintRequest: {
    title: "hintRequest",
    child : <div>
      coming soon.
    </div> 
  },
  hintRelationship: {
    title: "hintRelationship",
    child : <div>
      coming soon.
    </div>
  },
  hintContext: {
    title: "hintContext",
    child : <div>
      coming soon.
    </div>
  },
  hintThing: {
    title: "hintThing",
    child : <div>
      coming soon.    
    </div>
  },
  hintChat: {
    title: "hintChat",
    child : <div>
      coming soon.
    </div>
  },
  hintPerson: {
    title: "hintPerson",
    child : <div>
      coming soon.
    </div>
  },
  hintSearch: {
    title: "hintSearch",
    child : <div>
      coming soon.
    </div>
  }
}

const HelpButton = (
  props:{
    helpKey: string,
    slim?: boolean,
    hint?: boolean
  }
) => {
  const [helpOpen, setHelpOpen] = React.useState(false);

  const buttonText = props.slim ? "" : helpDict[props.helpKey].title;
    return <React.Fragment>
      <Modal open={helpOpen} 
      onCancel={
        () => setHelpOpen(false)
      }
      destroyOnClose={true}
      footer={null}
      >
          {helpDict[props.helpKey].child} 
  </Modal>

  { props.hint ?  <Button 
      type='text' 
      onClick={() => setHelpOpen(true)}    
      size="small"
      icon={<InfoCircleOutlined/>}
       > hint </Button> 
      : <Button size="small" 
        type={props.slim ? 'text' : 'default'}
      style={{
        marginLeft: props.slim ? 0 : 16,
      }}
      onClick={() => setHelpOpen(true)}    
      >
        <QuestionCircleOutlined/> {buttonText}
      </Button> 
    
    }
    </React.Fragment>
}

export default HelpButton
