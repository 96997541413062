import { DirectedConnectionInterface, EntityInterface } from '../../types';
import { ColorExp, SizeExp } from './settings';

const addNewConnections = (graph: any, db: any, newConnections: DirectedConnectionInterface[]) => {
    // if (graph !== null) {
    console.log("inside add new connection", newConnections);
    newConnections?.forEach( async (conn: any) => { 
      console.log("conn", conn)
      const otherEntityDetails: EntityInterface = conn.otherEntityDetails;
      console.log("otherEntityDetails", otherEntityDetails);
      /*const new_node = {
        id: conn?.otherEntityID,
        name: otherEntityDetails?.name,
        x: Math.random() * 300,
        y: Math.random() * 300,

      } */

      const new_node = {
        id: conn?.otherEntityID,
        //img: otherEntityDetails?.photoURL,
        size: SizeExp[otherEntityDetails?.entityType],
        name:  `<div style="display:'flex',flex-direction:'row'"><img src=${otherEntityDetails?.photoURL} width=${SizeExp[otherEntityDetails?.entityType]} height=${SizeExp[otherEntityDetails?.entityType]} style="border-radius: 50%;"/> <div style="margin-bottom: 16"> ${otherEntityDetails?.name} </div> </div>`,
        x: Math.random() * 300,
        y: Math.random() * 300,
        color: "#000",
        style: {
          fill: ColorExp[otherEntityDetails?.entityType],
        }
      }
  
      // console.log('adding new node', new_node);
  
      graph.addItem(
        'node', 
        new_node
      );
  
      const new_edge = {
        source: conn?.entityIDs[0],
        target: conn?.entityIDs[1],
      }
      console.log("adding new edge", new_edge, "to graph");
      graph.addItem(
        'edge',
        new_edge
      );
    });
  //  }
    
  
  }  

  export default addNewConnections;