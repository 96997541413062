import { arrayUnion, updateDoc } from "firebase/firestore";



const addStringToArrayField = (docRef:any, fieldName: any, value:string) => {
    console.log("addStringToArrayField")
    return updateDoc(docRef, {
        [fieldName]: arrayUnion(value)
    });
}

export default addStringToArrayField;

