import React from "react";
import ModalConfirm from "./ModalConfirm";
import { revokeAdmin } from '../../hooks/buildFns/modifyAdmin';
import { useAppState } from "../../state";

const RevokeAdminButton = (props: {
    connectionID: string
}) => {

    const { db } = useAppState();
    const {connectionID} = props;
    // TODO : ensure is admin - before makeadmin happens.
    return (
        <ModalConfirm 
            key={connectionID}
            ButtonContent={ "revoke admin" }
            ModalContent={"You are about to revoke admin access to this entity."}
            onConfirm={() => revokeAdmin(db, connectionID)}
        />
    )
}

export default RevokeAdminButton;