import React from "react";
import { Modal } from 'antd';
import ConceptTabs from "./ConceptTabs";
const ConceptModal = (
  props: {
    open: boolean,
    onClose: any,
    firstTimeUser: boolean
  }) => {

  const {open, onClose} = props;
  // console.log('first time user', firstTimeUser);
  
  return <Modal 
    open={open} 
    destroyOnClose={true}
    onCancel={onClose}
    closable={false}
    footer={null}
    width={800}
    >
    <ConceptTabs />
    
  </Modal>

}

export default ConceptModal;

