const validateEmail = (email: string| undefined) => {
    if ( email === undefined) {
        return false;
    }
    const emailArray = email.split('@');
    if (emailArray.length === 2) {
        return true;
    }
    return false;
}

export default validateEmail;