import getEntityIDfromEmail from "../getDetails/getEntityIDfromEmail";
import createEntity from "../buildFns/createEntity";
import {getConnectionDetails} from "../getDetails/useGetConnectionDetails";
import addConnection from "../buildFns/addConnection";
import {setTempEntityID} from "../email/linkEmailAndEntityID";
const applyAndWaitToJoin = async(
    applierEmail: string,
    applierName: string,
    targetID: string | undefined,
    db: any,
    afterAdding: any,
) => {

    if (targetID === undefined) {
        throw new Error('targetID is undefined');
    }

    let newlyCreated = false;
    let applierID = await getEntityIDfromEmail(db, applierEmail);
    if (!applierID) {
        console.log('creating a new entity');

        applierID = await createEntity(db, {
            name: applierName,
            entityType: 'person',
            description: '',
            email: applierEmail,
            photoURL: '',
            creatorID: '',
            id: 'unassigned'
        });
        
        const selfInvited = true;
        await setTempEntityID(db, applierEmail, applierID, selfInvited);

        newlyCreated = true;

    }

    let newlyApplied = true;

    const connDetails = await getConnectionDetails(
        db, 
        applierID, 
        targetID
    );

    let connID = '';

    if (connDetails) {
        newlyApplied = false;
        connID = connDetails.id;
    }
    else {
        console.log('adding a new connection');

        const connDoc = await addConnection(
            db, 
            applierID, 
            true, 
            targetID, 
            false, 
            applierID, 
            false
        );
        connID = connDoc.id;
    }
    afterAdding(connID, newlyApplied, newlyCreated);
}

export default applyAndWaitToJoin;
