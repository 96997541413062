import React from "react";
import { useAppState } from "../../state";
import { ConnectionInterface, DirectedConnectionInterface, FilterConnection } from "../../types";
import { collection, query, getDocs, orderBy  } from "firebase/firestore";
import { getEntityDetails } from "../getDetails/useGetEntityDetails";
import unsubscribeQuerySnapshot  from "../firestoreFns/unsubscribeQuerySnapshot";


export const createOtherID = (idPairs: string[], thisID: string) => {
                
    const index = idPairs.indexOf(thisID);
    if (index === 1) {
        return idPairs[0];
    }
    else if (index === 0) {
        return idPairs[1];
    }
    return idPairs[0];

};  


export const isPending = (data: any) => {
    return (data[data.entityIDs[0]] === false) || (data[data.entityIDs[1]] === false) ? true : false;
}


const transformDocData = async (db:any,  data: any, sourceID : string) => {

    const entityIDs = data?.entityIDs;
    const otherEntityID = createOtherID(entityIDs, sourceID);
    const pending = isPending(data);
    const hide = data.hide ? true : false;
    const otherEntityDetails = await getEntityDetails(db, otherEntityID);

    let connection: DirectedConnectionInterface = {
        id: data.id,
        entityIDs,
        otherEntityID,
        pending,
        admin: data.admin,
        otherEntityDetails,
        hide
    }

    // console.log("transformed data", connection)
    return connection;
}

const filterDocData =  (data:any, filter : FilterConnection | undefined) => {

    // console.log('filter inside filter stage ', filter, data)
    
    if (!filter){
        return true;
    }

    const { entityTypes, adminOnly, removePending, removeHidden } = filter;

    if (entityTypes.length > 0){
        if (!entityTypes.includes(data.otherEntityDetails.entityType)){
            return false;
        }
    }

    if (adminOnly){
        if (data.admin !== true){
            return false;
        }
    }

    if (removePending){
        if (data.pending){
            return false;
        }
    }

    if (removeHidden){
        console.log("remove hidden")
        console.log(data)
        if (data.hide){
            return false;
        }
    }
    return true;

}

export default function useGetEntityConnections(entityID: string| undefined, filter?: FilterConnection ) {
    
    const { db } = useAppState();
    const [entityConnections, setEntityConnections] = React.useState<ConnectionInterface[]>([]);
    const [directedConnections, setDirectedConnections] = React.useState<DirectedConnectionInterface[]>([]);
    const [filteredConnections, setFilteredConnections] = React.useState<DirectedConnectionInterface[]>([]);
    
    React.useEffect(() => {

        if  (entityID === undefined ){
            return undefined;
        }
        const q = query(
            collection(db, "connections"),  
            orderBy( entityID, "desc")
        );

        const unsub = unsubscribeQuerySnapshot(
            q, 
            setEntityConnections
        );

        return unsub; 
        
    }, [ db, entityID]);



    React.useEffect(() => {
        const updateConnections = async (transformedDocs: any) => {
            if (entityID === undefined){
                return;
            }
            const result = await Promise.all(transformedDocs);
            setDirectedConnections(result);
        }
    
        if (entityID === undefined){
            return;
        }

        const transformed = entityConnections.map(

             (doc:any) =>   {
                let data = doc;
                return transformDocData( db, data, entityID)
            }
        );

        console.log("transformed", transformed, typeof transformed);
        updateConnections(transformed);

    }, [entityConnections, db, entityID ]);

    React.useEffect(() => {

        if (directedConnections.length === 0 ||filter === undefined){
            setFilteredConnections(directedConnections);
            return;
        }
        console.log("filtered", directedConnections, filter)
        const filtered = directedConnections.filter(
            (doc:any) => filterDocData(doc, filter)
        )
        setFilteredConnections(filtered);
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [directedConnections]);

    /*
        .filter(
            (doc:any) => filterDocData(doc, filter)
        )
    */

    return filteredConnections;
}



/*
const unsub = onSnapshot(q, async (snapshot:any) => {
    let connections : DirectedConnectionInterface[] = [];
    console.log("snapshot", snapshot);
    connections = snapshot.docs.map(
            (doc:any) =>   {
            console.log("doc", doc);
            let data = doc.data();
            console.log("doc.data()", data);
            return transformDocData( data )
        }
    ).filter(
        (doc:any) => filterDocData(doc, filter)
    );
    setEntityConnections(connections);
}); 
*/


export const getEntityConnections = async (
    db: any, 
    entID: string,
    adminOnly? : boolean
) => {

    const q = query(collection(db, "connections"),  orderBy( entID, "desc"));

    return await getDocs(q).then((querySnapshot: any) => {
        let connections: DirectedConnectionInterface[] = [];
        querySnapshot.forEach((doc: any) => {
            // console.log("data doc", doc.data());
            let connection: DirectedConnectionInterface = {
                id: doc.id,
                entityIDs: doc.data()?.enitityIDs,
                otherEntityID: createOtherID(doc.data()?.entityIDs, entID),
                ...doc.data()
            }
            if (adminOnly){
                if (connection.admin === true){
                    connections.push(connection);
                }
            }
            else {
                connections.push(connection);
            }
        });

        // console.log("returning connections", connections);

        return connections;
    }
);  
}