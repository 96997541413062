import React, { useEffect } from "react";
import { Switch, Typography } from "antd";
import updateDoc from "../../hooks/firestoreFns/updateDoc";
import { doc } from "firebase/firestore";
import { useAppState } from "../../state";
import {  EntityInterface } from "../../types";
// This can be refactored so much ! .. 
const SupportToggle = (props: {
    docID: string | undefined;
    collectionName: string ;
    docDetails:  EntityInterface | undefined;
}) => {

    const { Text } = Typography;
    const { docID, collectionName, docDetails } = props;
    const { db } = useAppState();
    const [support, setSupport] = React.useState( false);

    useEffect(() => {
        if (docDetails?.support !== undefined) {
            setSupport(docDetails?.support);
        }
    }, [docDetails]);

    // const [hide, setHide] = React.useState(entityDetails?.hide);
    
    const updateSupport = async (sprt: boolean) => {
        if (docID === undefined || docDetails === undefined ) {
            return;
        }
        setSupport(sprt); 
        updateDoc( doc(db, collectionName, docID), { support : sprt}).then(() => {
            console.log("document details updated ... ", docID);
        }).catch((error) => {
            console.log("error updating invite enabled");
            console.log(error);
            setSupport(!sprt);
        });
    }
    if (docDetails?.entityType !== 'context') {
        return <></>
    }
    return <React.Fragment>
            <Switch 
                size="small"
                onChange={updateSupport}
                checked={support}
            /> 
            <Text 
                type='secondary'
                style={{
                    marginLeft: 4,
                    marginRight: 4
                }}
            > 
                Enable Sponsorship
            </Text> 
        </React.Fragment> 
}

export default SupportToggle;
