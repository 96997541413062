import { collection, addDoc, serverTimestamp } from "firebase/firestore";

// function to add a new chat to the database
export function addNewChat(db:any, connID:string, message: string, entityID: string ) {
  const messageRef = collection(db, `chats/${connID}/messages`);
  const newMessage = {
    message,
    entityID,
    timestamp: serverTimestamp()
  }
  return addDoc(messageRef, newMessage)
}


/*
export const addNewChat =  (db:any, connID:string, message: string, entityID: string ) => {
    const messageRef = collection(db, `chats/${connID}/messages`);
    const newMessage = {
      message,
      entityID,
      timestamp: serverTimestamp()
    }
    return addDoc(messageRef, newMessage)
  };
*/