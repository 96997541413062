import { EntityInterface } from "../../types";
import getEntityIDfromEmail from "../getDetails/getEntityIDfromEmail";
import { getConnectionDetails } from "../getDetails/useGetConnectionDetails";
import createEntity from "../buildFns/createEntity";
import addConnection from "../buildFns/addConnection";
import { setTempEntityID } from "../email/linkEmailAndEntityID";

const addToSource = async (
        sourceID : any, 
        entityDetails: EntityInterface, 
        creatorID:string,
        isAdmin :boolean,
        db: any,
        afterAdding: any
    ) => {  

    console.log('after onclick', sourceID, entityDetails,creatorID,isAdmin,db,afterAdding);

    if (entityDetails.entityType !== 'person' ){
        let entityExists = false;
        let connExists = false;
        const entityID = await createEntity(db, entityDetails);
        await addConnection(db, sourceID, true, entityID, true, creatorID, isAdmin);
        afterAdding(entityID, entityExists, connExists);
        return;
    }
    
    // handling person ... 

    const personID = await getEntityIDfromEmail(db, entityDetails.email);
    console.log("personID", personID);
    // let connExists = false;
    if (personID) {

        const  entityExists = true;
        const connDetails = await getConnectionDetails(db, sourceID, personID);
        if (connDetails){
            const connExists = true;
            console.log("connExists", connExists);
            afterAdding(entityExists, entityExists, connExists);
            return;
        } else {
            const connExists = false;
            await addConnection(
                db, 
                sourceID, 
                true, 
                personID, 
                false, 
                creatorID, 
                isAdmin
            );
            afterAdding(personID, entityExists, connExists);
            return;
        }
 
    } else {

        if (entityDetails.email === undefined) {
            throw new Error('Email is undefined');
        }

        const newPersonID = await createEntity(db, entityDetails);
        console.log("new person ID", newPersonID);
        await setTempEntityID(db, entityDetails.email, newPersonID);
        await addConnection(
            db, 
            sourceID, 
            true, 
            newPersonID, 
            false, 
            creatorID, 
            isAdmin
        );
        
        const entityExists = false;
        const connExists = false;
        afterAdding(newPersonID, entityExists, connExists);
        return;

    }


}

export default addToSource;
