import React from "react";
import { useParams } from 'react-router-dom';
import useGetEntityDetails from "../../hooks/getDetails/useGetEntityDetails";
import { Result } from "antd";
import Graph  from "../Graph/Graph";
import Footer from "../NavGlobal/Footer";
import ApplyToJoin from "./ApplyToJoin";

const EntitySignupPage = () => {
    const { entityID } = useParams();
    const [ EID, setEID ] = React.useState(entityID);

    // const entityID = "0x000"
    // const entityDetails ;
    console.log("entityID", entityID);
    const entityDetails = useGetEntityDetails(EID);
    console.log("entityDetails", entityDetails);
    // TODO : check for unlisted and hidden entities. 
    // TODO : security on what can be seen from the outside.

    if (!entityDetails) {
        return   <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />;
    }


    return (
        <React.Fragment>
            <ApplyToJoin entityID={EID} />
            { 
                EID &&  <Graph 
                setEntityID={setEID}
                entityID={EID} 
                filter={{
                    entityTypes: ['context'],
                    adminOnly: false,
                    removePending: true,
                    removeHidden: true
                }}
                />
            }
           <Footer />
              
        </React.Fragment>
    );
    
};

export default EntitySignupPage;