import React from "react";
import { useAppState } from "../../state";
import {doc } from "firebase/firestore";
import unsubscribeRefSnapshot from "../firestoreFns/unsubscribeRefSnapshot";



export default function useGetUserState( entityID : string| undefined) {


    const { db } = useAppState();
    const [userState, setUserState] = React.useState<any>();

    React.useEffect(() => {

        if (entityID) {

            const connectionRef = doc(db, "userState", entityID);
            console.log("have set connection ref");
            const unsubscribe = unsubscribeRefSnapshot(connectionRef, setUserState );
            return unsubscribe;

            
    

        }
    }, [entityID, db]);

    return userState;
}