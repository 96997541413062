import React from "react";
import {  Card, Space } from "antd";
import { GoldOutlined } from "@ant-design/icons";
import EntitySummary from "../EntitySummary/EntitySummary";
import TransparencyReport from "./TransparencyReport";
import SupportAsYourselfModal from "./SupportAsYourselfModel";
import SupportAsYourContextModal from "./SupportAsYourContext";
import useGetEntityDetails from "../../hooks/getDetails/useGetEntityDetails";
import SupportContextSettings from "./SupportContextSettings";
import { useAppState } from "../../state";
import useGetIsAdmin from "../../hooks/getDetails/useGetIsAdmin";
import SupportToggle from "./SupportToggle";
import HelpButton from "../Concepts/HelpButton";

const SupportComponent = ( props: {
    entityID: string
}) => {
    const entityDetails = useGetEntityDetails(props.entityID);
    const { userEntity } = useAppState();
    const isFirstDegreeAdmin = useGetIsAdmin(userEntity?.id, props.entityID, false);

    return <React.Fragment> 
            { 
                entityDetails?.entityType === 'context' &&
                
                <Card style={{
                    marginLeft: 32,
                    marginRight: 32,
                    marginTop: 4
                }}>
                    <Space> 
                        <GoldOutlined /> 
                        Sponsor <EntitySummary entityID={props.entityID} onlyName />
                        { 
                            entityDetails?.support && 
                            <>
                               <TransparencyReport entityID={props.entityID} isFirstDegreeAdmin={isFirstDegreeAdmin}/>
                                { isFirstDegreeAdmin && <SupportContextSettings entityID={props.entityID} /> }
                            </>       
                        }
                        <HelpButton helpKey="sponsor"/>
                    </Space>
                    <br/>
                    <br/>
                    {
                        isFirstDegreeAdmin &&
                        <SupportToggle
                        docID={props.entityID}
                        collectionName="entities"
                        docDetails={entityDetails}
                      />

                    }
                    <Space>
                    { 
                            entityDetails?.support ?<>
                            <SupportAsYourselfModal entityID={props.entityID} /> 
                        <SupportAsYourContextModal entityID={props.entityID} />
                            </>
                     :"sponsorship not enabled by admins."
                }</Space>

                    <br/>
                </Card>
            }
    </React.Fragment>
}

export default SupportComponent;