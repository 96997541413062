import {doc} from 'firebase/firestore';
import updateDoc from '../firestoreFns/updateDoc';

export const acceptConnection = async (db:any, connectionID : string, acceptorID:string) => {
    
    console.log('Accepting connection with ID:', connectionID, 'and acceptorID:', acceptorID);
    const connectionRef = doc(db, 'connections', connectionID);
    
    await updateDoc(connectionRef, {
        [acceptorID]: true
    });
    console.log('Connection accepted');
    
}

// generate a random string of length 10  ... 

// donate on random string 