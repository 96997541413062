import React from "react";
import { Typography, Badge } from 'antd';
import YourAdminStatus from "../Concepts/HelpButton";

const AdminStatus = (props: {
    isAdmin: boolean | undefined;
    isFirstDegreeAdmin: boolean | undefined;
    
}) => {
    
        const { isAdmin, isFirstDegreeAdmin } = props;
        const { Text } = Typography;

        console.log(isAdmin, isFirstDegreeAdmin)
        return (
            <React.Fragment>
        {
          ( isAdmin && !isFirstDegreeAdmin ) 
          &&  <Text code> 
          <Badge 
            color={'red'} 
            text={""} 
            style={{marginRight: 8, marginTop: 4}} /> 
            you are tied as a 2° admin.
          </Text>

        }

        {
          (isFirstDegreeAdmin) && <Text code> 
            <Badge 
                color={'red'} 
                text={""} 
                style={{marginRight: 8, marginTop: 4}} />
            you are tied as an admin.
            </Text> 
        }            

        {
          isAdmin && <YourAdminStatus helpKey="admin" />
        }

        </React.Fragment>
        );
    
};

export default AdminStatus;