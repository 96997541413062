import { addDoc, collection } from "firebase/firestore";
import { EntityInterface } from "../../types";
import  buildSearchKeywords  from "../search/buildSearchKeywords";


/*
const image_options = [
    'https://firebasestorage.googleapis.com/v0/b/vellby-trio.appspot.com/o/entityImages%2Fwith_friends.png_1676907842206?alt=media&token=afb8ff75-72df-479f-ab62-beef3634d292',
    'https://firebasestorage.googleapis.com/v0/b/vellby-trio.appspot.com/o/entityImages%2Fentity_types.png_1676907824308?alt=media&token=3d542f5d-bfc6-45d0-93f6-3d9b9a475b1c',
    'https://firebasestorage.googleapis.com/v0/b/vellby-trio.appspot.com/o/entityImages%2Fcontext_structures.png_1676907809517?alt=media&token=af47ff4d-832a-4396-a09c-eac2e06355e2',
    'https://firebasestorage.googleapis.com/v0/b/vellby-trio.appspot.com/o/entityImages%2Falone_galaxy.png_1676907790606?alt=media&token=ef5f2ca8-0403-416f-bf2a-bc1e1d46ae08'
    ]; 
*/

const image_options_map_type = {
    "person": [
        'https://firebasestorage.googleapis.com/v0/b/vellby-trio.appspot.com/o/entityImages%2Falone_galaxy.png_1676907790606?alt=media&token=ef5f2ca8-0403-416f-bf2a-bc1e1d46ae08',
        'https://firebasestorage.googleapis.com/v0/b/vellby-trio.appspot.com/o/images%2Fsearch_constellations.png?alt=media&token=cc09a894-5be3-41ba-b62d-7642729b4712',
        'https://firebasestorage.googleapis.com/v0/b/vellby-trio.appspot.com/o/images%2Fsearch_galaxy.png?alt=media&token=7fa71dc2-7bf9-42f7-935f-6244f8205728'
    ],
    "context": [
        'https://firebasestorage.googleapis.com/v0/b/vellby-trio.appspot.com/o/images%2Femergence.png?alt=media&token=fd05b13e-6e20-484c-a923-b79e1f96ef6b',
        'https://firebasestorage.googleapis.com/v0/b/vellby-trio.appspot.com/o/images%2Fyour_contexts.png?alt=media&token=6b27ba44-4f2b-4c07-b4a8-e4d36a13fbbb'

    ],
    "thing": [
        'https://firebasestorage.googleapis.com/v0/b/vellby-trio.appspot.com/o/images%2Fgarden_landscape.png?alt=media&token=2dcc7f4f-9f90-4072-85a5-b6fd2d4d887d',
        'https://firebasestorage.googleapis.com/v0/b/vellby-trio.appspot.com/o/images%2Fwalking_in_forest.png?alt=media&token=7a4aec60-b58a-48fb-84e2-4f073ad30a94',

    ],
    "relationship": [    
        'https://firebasestorage.googleapis.com/v0/b/vellby-trio.appspot.com/o/entityImages%2Fwith_friends.png_1676907842206?alt=media&token=afb8ff75-72df-479f-ab62-beef3634d292',
        'https://firebasestorage.googleapis.com/v0/b/vellby-trio.appspot.com/o/images%2Fwith_two_friends.png?alt=media&token=6fdabf18-6726-4040-82f2-13d7f717cae8',
        'https://firebasestorage.googleapis.com/v0/b/vellby-trio.appspot.com/o/images%2Fsearch_with_friend.png?alt=media&token=351c1309-61fb-400a-a7c1-54a6eabc7712'
    ]
}


const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * max);
};

const createEntity = async (db:any, entityDetails: EntityInterface) => {
    console.log('creating entity', entityDetails);
    const { name, description, email, entityType, creatorID, webLink } = entityDetails;
    const searchKeywords = buildSearchKeywords(name, description);
    const image_options = image_options_map_type[entityType];

    console.log('adding entity to db ',{
        name,
        description,
        email : email || '',
        photoURL: image_options[getRandomInt(image_options.length)],
        entityType,
        searchKeywords,
        creatorID,
        webLink: webLink || '',
        createdAt: new Date(),
    })
    const docRef = await addDoc(collection(db, "entities"), {
        name,
        description,
        email : email || '',
        photoURL: image_options[getRandomInt(image_options.length)],
        entityType,
        searchKeywords,
        creatorID,
        webLink: webLink || '',
        createdAt: new Date(),
    });
    
    // console.log("Entity written with ID: ", docRef.id);
    return docRef.id;
}

export default createEntity;

