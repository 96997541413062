import React, { useEffect } from "react";
import { Switch, Typography } from "antd";
import updateDoc from "../../hooks/firestoreFns/updateDoc";
import { doc } from "firebase/firestore";
import { useAppState } from "../../state";
import {  DirectedConnectionInterface, EntityInterface } from "../../types";
// This can be refactored so much ! .. 
const VisibilityToggle = (props: {
    docID: string | undefined;
    collectionName: string ;
    docDetails: DirectedConnectionInterface | EntityInterface | undefined;
}) => {

    const { Text } = Typography;
    const { docID, collectionName, docDetails } = props;
    const { db } = useAppState();
    const [hide, setHide] = React.useState( false);

    useEffect(() => {
        if (docDetails?.hide !== undefined) {
            setHide(docDetails?.hide);
        }
    }, [docDetails]);

    // const [hide, setHide] = React.useState(entityDetails?.hide);
    
    const updateHide = async (hidden: boolean) => {
     
        if (docID === undefined || docDetails === undefined ) {
            return;
        }
        setHide(hidden); 
        updateDoc( doc(db, collectionName, docID), {hide : hidden }).then(() => {
            console.log("document details updated ... ", docID);
        }).catch((error) => {
            console.log("error updating invite enabled");
            console.log(error);
            setHide(!hidden);
        });

    }

    return <React.Fragment>
            <Switch 
                size="small"
                onChange={updateHide}
                checked={hide}
            /> 
            <Text 
                type='secondary'
                style={{
                    marginLeft: 4,
                    marginRight: 4
                }}
            > 
                Hide  
            </Text> 
        </React.Fragment>
}

export default VisibilityToggle;
