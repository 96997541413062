import React from "react";
import { useAppState } from "../../state";
import useGetEntityPendingConnections from "../../hooks/getConnections/useGetEntityPendingConnections";
import PendingConnection from "./PendingConnection";
import { Divider } from "antd";
import useGetCreatedPendingConnections from "../../hooks/getConnections/useGetCreatedPendingConnection";

const PendingConnectionList = () => {
    const { userEntity } = useAppState();
    const incomingOnly  = true;

    const pendingConnections = useGetEntityPendingConnections(userEntity?.id, incomingOnly);
    const createdPendingConnections = useGetCreatedPendingConnections();

    if ( pendingConnections?.length === 0 && createdPendingConnections?.length  === 0) {
        return <></>
    }

    return <React.Fragment>
    
    <Divider 
        orientation="left"
        plain
        style={{
            marginTop: 64,
        }}
      >
       tie requests
      </Divider>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
            }}>

            {createdPendingConnections?.map((connection) => {
                return <PendingConnection 
                    key={connection.id} 
                    connection={connection}
                    yourID={connection?.createdOnBehalfID}
                    onBehalfOf={true}
                />

            })}

            {pendingConnections?.map((connection) => {
            return <PendingConnection 
                key={connection.id} 
                connection={connection}
                yourID={userEntity?.id}
            />

        })}


        </div>   


    </React.Fragment>;
    };

export default PendingConnectionList;
