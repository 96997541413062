import React from "react";
import { Button, Layout, Typography, Card } from 'antd';
import { useAppState } from '../../state';
import StepsTimeline from "./StepsTimeline";
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import useGetUserState from '../../hooks/getDetails/useGetUserState';
import HelpButton from "../Concepts/HelpButton";

const { Sider } = Layout;
const { Text } = Typography;

const siderStyle: React.CSSProperties = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#6CCFFF',
};

// the three paths.

const AllOnboardItems = {
  concept : {
    title: "Find concepts",
    description: <Text>
        Know where to find the core concepts of Vellby.
        <HelpButton helpKey="hintConcept" hint/>
      </Text>,
  },
  request : {
    title: "Tie requests",
    description: <Text>
      You have pending requests from others, to tie their entities to you. Resolve one of them by either accepting or denying.
      <HelpButton helpKey="hintRequest" hint/>
    </Text>
  },
  relationship : {
    title: "Represent a relationship",
    description: <Text>
    Represent a relationship in your life by creating a new relationship entity and tie it to yourself.
    <HelpButton helpKey="hintRelationship" hint/>
  </Text>
  },
  context : {
    title: "Introduce a context",
    description:<Text>
    Relationships exist in multiple contexts. Pick one of those contexts, and represent it with a new context entity and tie it to the relationship.
    <HelpButton helpKey="hintContext" hint/>
  </Text>
  },
  chat: {
    title: "create a chat",
    description: <Text>
      Entities of type Relationship and Context get a chat space. Try creating your first chat.
    <HelpButton helpKey="hintChat" hint/>
  </Text>
  },
  thing : {
    title: "Web link as a thing.",
    description: <Text>
      Add a link where members can communicate with each other - perhaps an external site like a messenger group or even to the default chat space here in vellby . 
    <HelpButton helpKey="hintThing" hint/>
  </Text>
  },
  person : {
    title: "Invite a person",
    description: <Text>
      Who else is in the relationship? Invite them by creating a new person entity and tie it to the relationship.
    <HelpButton helpKey="hintPerson" hint/>
  </Text>
  },
  search: {
    title: "Search",
    description: <Text>
      Search for other relationships, contexts, people and things. This will help you learn more about how entities can be represented and tied together.
    <HelpButton helpKey="hintSearch" hint/>
  </Text>
  },
  pending: {
    title: "build ties with others",
    description: <Text>
      You can request for yourself or entites tied to you, to be tied to entities created by others. Try requesting a tie to an entity you find in the search or by exploring the graph.
    <HelpButton helpKey="hintRequest" hint/>
  </Text>
  }
}


const prepareItemsSider = (allSteps: any, completedSteps: any) => {

  const items = Object.keys(allSteps).map((key) => {
    return {
      key: key,
      completed: completedSteps.includes(key),
      title: allSteps[key].title,
      description: allSteps[key].description,
      
    }
  })

  const completedItems = items.filter((item) => item.completed);
  const incompletedItems = items.filter((item) => !item.completed);


  return [...completedItems, ...incompletedItems];

}
const OnboardSider = () => {
    const { userEntity } = useAppState();
    const userId = userEntity?.id;
    const [collapsed, setCollapsed] = React.useState(true);
    const userState = useGetUserState(userId);
    console.log('userState', userState);
    const onboardedSteps = userState?.onboardedSteps || [];
    // const [onboardedSteps, setOnboardedSteps] = React.useState([]);
    const items = prepareItemsSider(AllOnboardItems, onboardedSteps);


   /* useEffect(()=> {

      if (userId){
        getUserState(db, userId).then((res:any) => {

          console.log('res - onboardedsteps : ', res);
          if (res.onboardedSteps){
            setOnboardedSteps(res.onboardedSteps);
          }

        }).catch((err:any) => {
          console.log('err', err);
        })
      }
    }, [userId, db])
    */

    if (!userId) {
      return null;
    }

    return <Sider 
    width={286}
    style={siderStyle} 
    collapsed={collapsed} 
    collapsedWidth="56">

      <Button 
        size="small"
        type="text"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        icon={ collapsed?  <RightCircleFilled style={{
          color:"#fff"
        }}/>: 
          <LeftCircleFilled style={{
            color:"#fff"
          }}/>
        }
        >
          {!collapsed && "welcome onboard"}
        </Button>
    
          
     { !collapsed  && <Card size="small" bordered={false} 
    style={{
      width:'100%',
      backgroundColor: '#6CCFFF',
    }}>
      <Text>Here is a guided path to give you an overview.</Text>
    </Card>}
      <div
      style={{
        marginRight:24
      }}>




    <StepsTimeline IsCollapsed={collapsed} items={items}/>

    </div>
  
      { !collapsed  &&  <Card size="small" bordered={false} 
    style={{
      width:'100%',
      backgroundColor: '#6CCFFF',
    }}> <Text> Once you complete the above steps in this basic guided path, we will have a few more advanced guided paths ready for you.</Text>
    </Card> }
    </Sider> 
    }

export default OnboardSider;