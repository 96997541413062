import React from "react";
import { List, Typography } from 'antd';
import ParsedEntity from "./ParsedEntity";
import {  StopOutlined } from "@ant-design/icons";



const ListParsedEntities = (props: {
    parsedEntities: any,
    afterAdding: any,
    sourceID: string,
    allowedEntityTypes: any,

}) => {   
    const { 
        parsedEntities,
        sourceID,
        allowedEntityTypes
    } = props;

    const { Text } = Typography;
    console.log("allowedEntityTypes", allowedEntityTypes)

    const allowedTypes = allowedEntityTypes.filter( 
        (item:any) => item.disabled !== true 
        ).map( 
            (item:any) => item.label
        );
    console.log("allowedTypes", allowedTypes)

    return <List pagination={{ 
        position : 'bottom',
        pageSize: 5,
        onChange: (page) => {
            console.log(page);
        }
    }}
    
    >

        {parsedEntities.map((item: any) => {

            if(allowedTypes.includes(item.entityType)) {
                console.log("item ", item);
                return <ParsedEntity 
                    item={item}
                    sourceID={sourceID}
                />
            }

           if ( ['context', 'thing', 'person', 'relationship'].includes(item.entityType)) {
            return <List.Item
            key={item.name}
            style={{ padding: 16 }}
            >
                <StopOutlined color="#a00"/> <Text code>{item.name} </Text> is of type <Text code>{item.entityType}</Text> and cannot be tied here. Only { allowedTypes.map((key:any)=><Text code>{key}</Text> ) } entities can be tied to this entity.
              </List.Item>
            }

            return <List.Item
                key={item.name}
                style={{ padding: 16 }}
                > 
                    format error in {item.name} - {item.entityType}
                </List.Item>
             
        })}
         
    </List>
    
}

export default ListParsedEntities;