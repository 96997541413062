const check_if_array_contains_item = (array: any[], item: any) => {
    return array.indexOf(item) > -1;
}

const get_index_of_item_in_array = (array: any[], item: any) => {
    return array.indexOf(item);
}


const pre_validation = (header: any[]) => {
    return (
        check_if_array_contains_item(header, 'entitytype') &&
        check_if_array_contains_item(header, 'name') &&
        check_if_array_contains_item(header, 'description')
    )
}

const validate_item = (item: any[], required_indexes: number[]) => {
    return required_indexes.every((index: number) => {
        return index> -1 && item[index] !== undefined && item[index] !== null && item[index] !== '';
    })
}

export const parse_uploaded_csv_data = (
    inputdata: any[]
) => {

    const error = {
        error: false,
        message: ''
    };

    console.log("result", inputdata);
    let header = inputdata[0];

    // lower case and remove spaces in header
    header = header.map((item: string) => item.toLowerCase().replace(/\s/g, ''));
    let pre_validation_result: boolean  = pre_validation(header);

    if (!pre_validation_result) {
        error.error = true;
        error.message = 'Wrong format';
        return { importedData: [], error};
    }


    const entityTypeIndex = get_index_of_item_in_array(header, 'entitytype');
    const nameIndex = get_index_of_item_in_array(header, 'name');
    const descriptionIndex = get_index_of_item_in_array(header, 'description');

    const emailIndex = get_index_of_item_in_array(header, 'email');
    const linkIndex = get_index_of_item_in_array(header, 'link');
    const contextTagsIndex = get_index_of_item_in_array(header, 'contexttags');

    let importedData = inputdata.slice(1)
      .filter( (item:any) => {
        if (entityTypeIndex === -1) {
            return false;
        }
        if (item[entityTypeIndex] === "" || item[entityTypeIndex] === null || item[entityTypeIndex] === undefined )
        {
            return false;
        }
        return true;

      }).map((item: any) => {


      console.log(item)
      const entityType = item[ entityTypeIndex ].toLowerCase().replace(/\s/g, '');
      const default_item = {
        entityType: 'unparsed',
        name: 'unparsed',
        description: 'unparsed',
      }
      const base_item = contextTagsIndex > -1 ? {
        contextTags: item[contextTagsIndex].split(',')
      } : {}
      if ( entityType === 'person') {
        let person_validated:Boolean  = validate_item(item, [ entityTypeIndex, nameIndex, descriptionIndex, emailIndex ])
        let person_item : any = person_validated ?  {
            ...base_item,
          entityType,
          name: item[nameIndex],
          description: item[descriptionIndex],
          email: item[emailIndex].toLowerCase().replace(/\s/g, ''),
        } : {
            entityType: 'error',
            name: 'person entity',
            description: 'missing required fields for person entity',
        }

        console.log("person_item", person_item);
        return person_item;

      }

     if ( entityType === 'context') {

        let context_validated:Boolean  = validate_item(item, [ entityTypeIndex, nameIndex, descriptionIndex ])
        let context_item : any = context_validated ?  {
            ...base_item,
            entityType,
            name: item[nameIndex],
            description: item[descriptionIndex],
        } : {
            entityType: 'error',
            name: 'context entity',
            description: 'missing required fields for context entity'
        }

        return context_item;
        
    }

    if ( entityType === 'thing') {

        let thing_validated:Boolean  = validate_item(item, [ entityTypeIndex, nameIndex, descriptionIndex, linkIndex])
        let thing_item : any = thing_validated ?  {
            ...base_item,
            entityType,
            name: item[nameIndex],
            description: item[descriptionIndex],
            webLink: item[linkIndex]
        } : {
            entityType: 'error',
            name: 'context entity',
            description: 'missing required fields for context entity'
        }
        return thing_item;
    }
    if ( entityType === 'relationship') {

        let relationship_validated:Boolean  = validate_item(item, [ entityTypeIndex, nameIndex, descriptionIndex])
        let relationship_item : any = relationship_validated ?  {
            ...base_item,
            entityType,
            name: item[nameIndex],
            description: item[descriptionIndex],
        } : {
            entityType: 'error',
            name: 'context entity',
            description: 'missing required fields for context entity'
        }
        return relationship_item;
    }
    return default_item;
}); 

    console.log("imported data", importedData);

    return { importedData, error};


}
