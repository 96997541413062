import React from "react";
import EntitySummary from '../EntitySummary/EntitySummary';
import { Typography } from 'antd';

const ListAddedEntities = (props: {
    addedEntities: {
        entityID: string;
        entityExists: boolean ;
        connectionExists: boolean ;
    }[] | undefined; }) => {

    const { addedEntities } = props;
    const { Text } = Typography;
    
    return (
        <React.Fragment>
            {addedEntities?.map((item) => {
                return <React.Fragment>
                <EntitySummary entityID={item.entityID} lean/>
                    {item.entityExists && <Text code> entity exists </Text>}
                    {item.connectionExists && <Text code> already connected </Text>}
                </React.Fragment>
                }
            )}
        </React.Fragment>
    );
};

export default ListAddedEntities;
