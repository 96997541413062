import { Button, Input, Form } from "antd";
import React, {useEffect, useState, useCallback} from "react";
import { EntityType } from "../../types";
import useGetEntityDetails from "../../hooks/getDetails/useGetEntityDetails";
import { useAppState } from "../../state";
import { collection, doc, setDoc } from "firebase/firestore";
import { useDropzone } from 'react-dropzone';
import { uploadBytes, getDownloadURL, ref } from "firebase/storage";
import VisibilityToggle from "../Visibility/VisibiltyToggle";
import updateDoc from "../../hooks/firestoreFns/updateDoc";
import SupportToggle from "../Sponsor/SupportToggle";

//TODO refactor (edit summmary + edit image)  

const EditSummary = (props: { 
  entityID: string | undefined,
  setIsOpen: any
}) => {


    const [name, setName] = useState<string|undefined>();
    const [description, setDescription] = useState<string|undefined>();
    const [webLink, setWebLink] = useState<string|undefined>();
    //const [photoURL, setPhotoURL] = useState<string|undefined>();
    const [entityType, setEntityType] = useState<EntityType|undefined>();
    const [uploadedURL, setUploadedURL] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const { storage } = useAppState();
    const { entityID } = props;
    const {db} = useAppState();
    const entityDetails = useGetEntityDetails(entityID);
    const onDrop = useCallback(
        async (acceptedFiles:any) => {
          // @ts-ignore
          const uploadFromBlobAsync = async ({ blobUrl, blobName }) => {
            if (!blobUrl || !blobName) return null;


            try {
              const blob = await fetch(blobUrl).then((r:any) =>{
                console.log("blob is fetched");
                console.log(r);
                return r.blob();
              } );

              console.log(blob);
              console.log('about to set storage ref'); 
              const storageRef = ref(storage, `entityImages/${blobName}`)
              console.log("storage ref is set");

              await uploadBytes( storageRef, blob)
                console.log("uploadBytes is done");

              return await getDownloadURL(storageRef);

            } catch (er:any) {
                console.error(er);
              throw er;
            }
          };
    
          const file = acceptedFiles?.[0];
          if (!file) {
            return;
          }
          setIsLoading(true);
          setError(null);
          setMessage(null);
    
          try {
            let responseURL = await uploadFromBlobAsync({
              blobUrl: URL.createObjectURL(file),
              blobName: `${file.name}_${Date.now()}`,
            });
            if (responseURL){
                setUploadedURL(responseURL);
            }
    
            await setDoc(doc(collection(db, 'entities'), entityID), {
                photoURL: responseURL,
            }, { merge: true });

          } catch (e:any) {
            setIsLoading(false);
            setError(e);
            return;
          }
          setIsLoading(false);
          // @ts-ignore
        },
        [storage, db, entityID]
      );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    useEffect(() => {
        if (entityDetails) {
            setName(entityDetails.name);
            setDescription(entityDetails.description);
            setUploadedURL(entityDetails.photoURL);
            setWebLink(entityDetails.webLink);
            setEntityType(entityDetails.entityType);
        }
    }, [entityDetails]);
    

    // TODO: refactor - move inside hooks folder
    const updateEntity = async () => {
        console.log('updateEntity : ', entityID, name, description, uploadedURL, entityType);
        if (!entityID) return;

        let searchKeywords = name?.split(' ') 
        let descriptionKeywords = description?.split(' ').filter((word) => word[0] === '#').map((word)=> word.slice(1));
        searchKeywords = searchKeywords?.concat(descriptionKeywords || []);

        const entityRef = doc(db, 'entities', entityID);
        updateDoc(entityRef, {
            name,
            description,
            searchKeywords }
        );

        if (webLink) {
            updateDoc(entityRef, {webLink});
        }

        props.setIsOpen(false);
    }

    return ( <React.Fragment>
        <Form onFinish={ () => {
              updateEntity();
        }}>
        <Form.Item label="Name">
          <Input value={name} onChange={(e:any) => setName(e.target.value)}/>
        </Form.Item>
        <Form.Item 
          label="Description"                 
          extra="#hashtags in description will be searchable" >
          <Input value={description} onChange={(e:any) => setDescription(e.target.value)}/>
        </Form.Item>

        { entityType === 'thing' && <Form.Item label="Web Link">
          <Input value={webLink} onChange={(e:any) => setWebLink(e.target.value)}/>
          </Form.Item> 
        }

        <Form.Item >
          <Button type="default" size="small" htmlType="submit">save name and description </Button>
        </Form.Item>

        <Form.Item >
          <VisibilityToggle 
            docID={entityID}
            collectionName="entities"
            docDetails={entityDetails}
          />
        </Form.Item>
        <Form.Item >
          <SupportToggle 
            docID={entityID}
            collectionName="entities"
            docDetails={entityDetails}
          />
        </Form.Item>

        <Form.Item label="Pic">
        {isLoading && <span> Loading ... </span>}
          {error && <span> {error} </span>}
          {message && <span> {message} </span>}
        {uploadedURL === '' ? (
            <div
              {...getRootProps({ className: 'dropzone' })}
              style={{
                borderWidth: 'thick',
                width: 160,
                height: 160,
                margin: 8,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Upload a picture for your relationship.</p>
              ) : (
                <p style={{ color: '#dfdfdf' }}>Click here or Drag 'n' drop your profile picture </p>
              )}
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img
                src={uploadedURL}
                alt={'Uploaded URL'}
                width={160}
                height={160}
                style={{ margin: 8, objectFit: 'cover' }}
              />
              <Button
                type='text'
                onClick={() => setUploadedURL('')}
                style={{
                  width: 160,
                  margin: 8,
                }}
              >
                remove
              </Button>
            </div>
          )}
        </Form.Item>
      </Form>
    </React.Fragment>
    );

};

export default EditSummary;