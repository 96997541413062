import React, { useEffect } from "react";
import { useAppState } from "../../state";
import useGetEntityConnections, { getEntityConnections } from "./useGetEntityConnections";

const useGetEntityConnectionsSecondDegree = ( entityID : string | undefined, adminOnly? : boolean) => {

    const {db} = useAppState();
    // console.log("entityID", entityID);
    const firstDegreeConnections = useGetEntityConnections(entityID, {
        entityTypes: [],
        adminOnly : adminOnly? adminOnly : false,
        removePending: true,
        removeHidden: false
    });
    // console.log("firstDegreeConnections", firstDegreeConnections);

    const [ secondDegreeConnections , setScondDegreeConnections ] = React.useState<any[] >([]);


    useEffect ( () => {
    if (firstDegreeConnections) {

        setScondDegreeConnections(firstDegreeConnections);

        firstDegreeConnections.forEach((connection) => {
            // console.log("otherEntityID", connection.otherEntityID)
            getEntityConnections(db, connection.otherEntityID, adminOnly).then(
             (connections) =>  {

              // console.log('concating' , connections);
                setScondDegreeConnections((prev) => {
                    return [...prev, connections]
                }
                )
             
            }) 
        })
    }
} , [firstDegreeConnections?.length, firstDegreeConnections, db, adminOnly])

    return secondDegreeConnections.flat();
}

export default useGetEntityConnectionsSecondDegree;