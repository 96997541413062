import React from 'react';
import { useAppState } from '../../state';
import { query, collection, getDocs, onSnapshot, where} from 'firebase/firestore';
import {ConnectionInterface} from '../../types';


export const getConnectionDetails= async (db:any, sourceID: string, targetID: string) =>{
    const connectionRef = collection(db, 'connections')
    const stEntityIDs = [sourceID, targetID];
    const tsEntityIDs = [targetID, sourceID];

    const q1 = query(connectionRef ,  where("entityIDs", "in",  [ stEntityIDs, tsEntityIDs ]) );
    // const q2 = query(connectionRef ,  where("entityIDs", "==",  tsEntityIDs ) );
    try {
        const querySnapshot = await getDocs(q1)
        if (querySnapshot.docs.length === 1) {
            return querySnapshot.docs[0].data();
        }
    } catch (e) {
        console.log('error getting connection details', e);
    }

    return undefined;

}

// TODO - this can be done without a query.

const useGetConnectionDetails = (sourceID: any, targetID: any) => {

    const {db} = useAppState();
    const [connectionDetails, setConnectionDetails] = React.useState<ConnectionInterface>();
    
    // console.log("useGetConnectionDetails", sourceID, targetID);

    React.useEffect(() => {
        if (sourceID && targetID) {
            const connectionRef = collection(db, 'connections')
            const q = query(connectionRef , where( "entityIDs" , "in", [[sourceID, targetID], [targetID, sourceID]]) );

            const unsub = onSnapshot(q, (data:any) => {
                let newData: any = data.docs 
                if (newData.length === 1) {
                    let connDoc: ConnectionInterface = newData[0].data();
                    setConnectionDetails(
                        { 
                            ...connDoc,
                            id: newData[0].id
                        });
                }
                else{
                    setConnectionDetails(undefined);
                }
            });
            return unsub;
        }

    }, [sourceID, targetID, db]);
    return connectionDetails;

}


export default useGetConnectionDetails;