import React from 'react'
import {List, Space} from "antd";
import useGetChats from '../../hooks/chat/useGetChats';
import { ChatMessageInterface } from '../../types';
import EntitySummary from '../EntitySummary/EntitySummary';

const PreviousChats = (props: {connID: string}) => {
    const { connID } = props;
    const chats: ChatMessageInterface[] | undefined = useGetChats(connID);

    return <div style={{
        height: '60vh',
        overflow: 'scroll',
        overflowX: 'hidden',
    
    }}>
                {chats && chats?.map(  (chat : any) => {
            return <List.Item 
                key={chat.id}
            >
             <Space>
              <EntitySummary onlyName entityID={chat.entityID}/>  {chat.message}

             </Space>

            </List.Item>
            })
        }
    </div>
}

export default PreviousChats;