import React from "react";
import useGetConnectionDetailsFromID from "../../hooks/getDetails/useGetConnectionDetailsFromId";
import { Typography, Badge } from "antd";
import EntitySummary from "../EntitySummary/EntitySummary";
// TODO : Handle Hide. 

const PendingBadge = (props: {pending: boolean}) => {
    const { pending } = props;
    return pending
    ? <Badge color={'cyan'} text={"pending"} style={{margin: 8}} />
    : <></>
}

const HiddenBadge = (props: {connObj: any}) => {
    const { Text } = Typography;
    const { connObj } = props;
    return  connObj?.hide 
    ? <Text code> hidden </Text>
    : <></>
}


const AdminBadge = (props: {connObj: any}) => {
    const { connObj } = props;
    return connObj?.admin
    ? <Badge color={'red'} text={"admin"} style={{margin: 8}} />
    : <></>
}


const ConnectionSummary = (props: {
    connID: string;
    sourceID: string | undefined;
    targetID: string | undefined;
    onClick? : () => void;
}) => {
    const connDetails = useGetConnectionDetailsFromID(props.connID);
    const { sourceID, targetID, onClick } = props;
    const pending = sourceID 
        && targetID 
        && connDetails
        && ( connDetails[sourceID] !== true || connDetails[targetID] !== true) ? true : false;
    console.log("connection Sumamry", connDetails);
    if (!connDetails) {
        return <></>
    }
    return <div style={
        {
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
        }
    }>
        <EntitySummary entityID={props.targetID} lean onClick={onClick}/>  
        <div
            style={{
                marginLeft: 'auto',
            }}
        >
            <PendingBadge pending={pending } />
            <HiddenBadge connObj={connDetails} />
            <AdminBadge connObj={connDetails} />

        </div>
    </div>;
};

export default ConnectionSummary;