import React from "react";
import { useAppState } from "../../state";
import {doc, getDoc } from "firebase/firestore";
import { EntityInterface } from "../../types";
import unsubscribeRefSnapshot from "../firestoreFns/unsubscribeRefSnapshot";

export const getEntityDetails = async (db: any, entID:string) => {

    const entRef = doc(db, "entities", entID);
    // console.log(entRef);
    const entDoc = await getDoc(entRef);
    let entDetails: EntityInterface ={
        id: entDoc.id,
        name: entDoc.data()?.name,
        entityType: entDoc.data()?.entityType,
        description: entDoc.data()?.description,
        photoURL: entDoc.data()?.photoURL,
        searchKeywords: entDoc.data()?.searchKeywords,
        inviteEnabled: entDoc.data()?.inviteEnabled,
        webLink: entDoc.data()?.webLink,
        email: entDoc.data()?.email,
        pinnedConnectionIDs: entDoc.data()?.pinnedConnectionIDs,
        pinnedGraphViews: entDoc.data()?.pinnedGraphViews,
        hide: entDoc.data()?.hide,
        support: entDoc.data()?.support,
    } 
    return entDetails;
    
}

export default function useGetEntityDetails( entityID : string| undefined) {


    const { db } = useAppState();
    const [entityDetails, setEntityDetails] = React.useState<EntityInterface>();

    React.useEffect(() => {

        if (entityID) {

            const connectionRef = doc(db, "entities", entityID);
            // console.log("have set connection ref");
            const unsubscribe = unsubscribeRefSnapshot(connectionRef, setEntityDetails);
            return unsubscribe;

            
            /*
            console.log("entityID", entityID);
            getEntityDetails(db, entityID).then((entDetails) => {
                setEntityDetails(entDetails);
                console.log(entDetails);
            }).catch((error) => {
                console.log('error in useGetEntityDetails:', error);
            })   
            */

        }
    }, [entityID, db]);

    return entityDetails;
}