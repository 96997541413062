import React from "react";
import {  Card, Space, Tooltip, Button } from "antd";
import { BuildOutlined, CopyOutlined, InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import AddYourself from "./AddYourself";
import AddYourExistingEntity from "./AddYourExistingEntity";
import AddNewEntity from "./AddNewEntity";
import ImportCSV from "../CSVBuilder/ImportCSV";
import { EntityType } from "../../types";
import { useAppState  } from "../../state";
import useGetIsAdmin from "../../hooks/getDetails/useGetIsAdmin";
import EntitySummary from "../EntitySummary/EntitySummary";
import RulesModal from '../Concepts/RulesModal';

// the three buttons are rendered within a Space component
const BuildEntity = (props:{
    entityType: EntityType| undefined;
    entityID : string | undefined;
    userID: string | undefined;
}) => {
    const { entityID, entityType,  userID } = props;
    const {userEntity } = useAppState();
    const [openRules, setOpenRules] = React.useState(false);
    const isAdmin = useGetIsAdmin(entityID, userEntity?.id, false);
    const [ copied, setCopied ] = React.useState(false);

    // console.log(isAdmin, entityID, userEntity?.id)
    return <React.Fragment>
        <RulesModal 
            open={openRules} 
            entityID={entityID}
            entityType={entityType}
            onClose={() => {
                setOpenRules(false);
             }} 
        />
        <Card 
            style={{
                marginLeft: 32, marginRight: 32,
                marginTop: 4
                }}>

            <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 8,
                    width: '60%'
                  }}>

            <BuildOutlined size={16} style={{
                marginRight: 8,
                marginTop: 4
            }} /> 
                Build ties on <EntitySummary entityID={entityID} onlyName />
            <Button
                    type="default"
                    size="small"
                    style={{
                        marginLeft: 16,
                    }}
                    onClick={() => {
                    setOpenRules(true);
                    }}
                    icon={<QuestionCircleOutlined/>}
                > 
                 rules 
            </Button>
            </div> 
            <Tooltip 
                placement="topLeft" 
                title={ <>
                    <BuildOutlined 
                    size={16} 
                    style={{
                        marginRight: 2
                    }}/> 
                    build options available for you on an entity varies - depending on your admin status.
                </>}>
                <InfoCircleOutlined/>
            </Tooltip>
        <Space size={'large'} wrap>

            { entityID && <AddYourself entityID={entityID} /> }
            { (userID !== entityID ) && <AddYourExistingEntity sourceID={entityID} sourceType={entityType} /> }
            { (isAdmin || entityID === userEntity?.id) 
                && <>
                <AddNewEntity sourceID={entityID} />
                <ImportCSV sourceID={entityID} />
            </>
            }
            
            { isAdmin && (userID !== entityID ) && <Button type={'text'} onClick={() => {
                navigator.clipboard.writeText(`https://vellby.com/entity/${entityID}`);
                setCopied(true);
            }} icon={<CopyOutlined />}>  {  copied ? "copied !": "Invite Link" } </Button> }
        </Space>
    </Card>
</React.Fragment>
}

export default BuildEntity;
