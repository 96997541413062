import React from "react";
import ModalConfirm from "./ModalConfirm";
import { makeAdmin } from '../../hooks/buildFns/modifyAdmin';
import { useAppState } from "../../state";

const MakeAdminButton = (props: {
    connectionID: string
}) => {

    const { db } = useAppState();
    const {connectionID} = props;
    // TODO : ensure is admin - before makeadmin happens. - perhaps it can be done insode build fns.
    return (
        <ModalConfirm 
        key={connectionID}
        ButtonContent={ "make admin" }
        ModalContent={"You are about to give admin access to this entity."}
        onConfirm={() => makeAdmin(db, connectionID)}
    />
    )
}

export default MakeAdminButton;

