import { useEffect } from "react";
import addNewDoc from "../firestoreFns/addNewDoc";
import { collection,} from "firebase/firestore";
const useSendMessage = (db: any, data: any) => {

    const collectionRef = db ? collection(db, "monitoring"): null;
    useEffect(() => {
        if (collectionRef) {
            addNewDoc(collectionRef, data);
        }
    }, [collectionRef, data]);
};

export default useSendMessage;
