import { setDoc } from "firebase/firestore";


const updateDoc = async( docRef : any, data : any) => {
    await setDoc(docRef, data, {
        merge: true
    }).catch((e : any) => {
        console.error("Error updating document: ", e);
    });
}

export default updateDoc;
