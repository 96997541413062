import React from "react";
import { Card } from "antd";
import NavGlobal from  "../NavGlobal/NavGlobal";
import getAllDocsFromCollection from "../../hooks/firestoreFns/getAllDocsFromCollection";
import { useAppState } from "../../state";
import PaymentSummary from "../Payment/PaymentSummary";
import useGetEntityConnections from "../../hooks/getConnections/useGetEntityConnections";
import TransparencyReport from "./TransparencyReport";
import EntitySummary from "../EntitySummary/EntitySummary";

// import AddPaymentButton from "../Payment/AddPaymentButton";
// import CustomerPortalButton from "../Payment/CustomerPortalButton";
/*
    This is a placeholder for the donate page
*/
const SupportPage = () => {
    const [ payments, setPayments ] = React.useState([]);
    const { db, userEntity} = useAppState();
    const firstDegreeAdminContexts = useGetEntityConnections(userEntity?.id, {
        entityTypes: ['context'],
        adminOnly: true,
        removePending: true,
        removeHidden: false
    });

    React.useEffect(() => {

        getAllDocsFromCollection( db, `support/${userEntity?.id}/payment`).then((docs:any) => {
            setPayments(docs);
        }).catch((error) => {
            console.log(error);
        });
    }, [userEntity?.id, db]);

    return <React.Fragment>
        <NavGlobal />
        <Card style={{
            margin: 32,
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
        }}>
            Contexts you are sponsoring.
            {
                payments.map((payment:any) => {
                    console.log('payment', payment);
                    return userEntity?.id && payment?.id && <PaymentSummary sourceEntity={userEntity?.id} paymentID={payment?.id}/>
                })
            }
        </Card>
        <Card style={{
            margin: 32,
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
        }}
        >
             Your contexts.
            {
               firstDegreeAdminContexts.map(context => {    
                    return <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: 8,
                    }}>
                        <EntitySummary 
                            onlyName 
                            entityID={context.otherEntityID} 
                        />
                        <TransparencyReport 
                            entityID={context.otherEntityID} 
                        />

                    </div>
            })}                   
        </Card>            
    </React.Fragment>
}

/*
    <AddPaymentButton />
    <CustomerPortalButton />
*/

export default SupportPage;
