import updateDoc from "../firestoreFns/updateDoc";
import { doc } from "firebase/firestore";

const setClicked = async (db: any, userID:string, notificationID: string) => {
    
        const docRef = doc(db, `notifications/${userID}/messages/${notificationID}`);
        const data = {
            clicked: true
        }
        await updateDoc(docRef, data);
}

export default setClicked;