import React from "react";
import addConnection from "../../hooks/buildFns/addConnection";
import useGetIsAdmin from "../../hooks/getDetails/useGetIsAdmin";
import { useAppState } from "../../state";
import ModalConfirm from "./ModalConfirm";
import { doc } from "firebase/firestore";
import removeStringFromArrayField from "../../hooks/firestoreFns/removeStringFromArrayField";
import addStringToArrayField from "../../hooks/firestoreFns/addStringToArrayField";


const TieTogetherButton = (props: {
    sourceID : string | undefined,
    targetID : string,
    text?: string
}) => {

    const { sourceID, targetID, text } = props;
    const { db, userEntity } = useAppState();


    const sourceAdmin = useGetIsAdmin(userEntity?.id, sourceID, true);
    const targetAdmin = useGetIsAdmin(userEntity?.id, targetID, true);

    return <ModalConfirm 
        key={sourceID + targetID}
        ButtonContent={text ? text:  "tie together" }
        ModalContent={ "You are about to tie two entities together"}
        onConfirm={ () => { 
            
            addConnection(
                db,
                sourceID,
                sourceAdmin,
                targetID, 
                targetAdmin,
                userEntity?.id,
                targetAdmin && sourceAdmin
            ) 
        
            if (userEntity?.id){
                let docRef = doc(db, 'userState', userEntity?.id);
                removeStringFromArrayField(docRef, 'onboardedSteps', 'pending');
                addStringToArrayField(docRef, 'onboardedSteps', 'pending');
              }
        }
        }
    />
    
}


export default TieTogetherButton;