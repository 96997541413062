import { onSnapshot } from "firebase/firestore";

const unsubscribeRefSnapshot = (ref:any, setData:any) => {

    const unsub = onSnapshot(ref, (docu: any) => {
        setData(docu.data());
    });
    return unsub;
}

export default unsubscribeRefSnapshot;