import React from "react";
import { useAppState } from "../../state";
import { doc } from "firebase/firestore";
import unsubscribeRefSnapshot from "../firestoreFns/unsubscribeRefSnapshot";

const useGetConnectionDetailsFromId = (connectionID: string | undefined) => {

    const {db} = useAppState();
    const [connectionDetails, setConnectionDetails] = React.useState<any>();
    
    
    React.useEffect(() => {

        // console.log("useGetConnectionDetailsFromId called", connectionID);
        if (connectionID) {

            const connectionRef = doc(db, "connections", connectionID);
            const unsubscribe = unsubscribeRefSnapshot(connectionRef, setConnectionDetails);
            return unsubscribe;

        }
    }, [connectionID, db]);
    // console.log("useGetConnectionDetailsFromId returning", connectionDetails);
    return connectionDetails;
}

export default useGetConnectionDetailsFromId;