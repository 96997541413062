import validateEmail from "../utils/validateEmail";
import getEmailDoc from "./getEmailDetails";

const getEntityIDfromEmail = async( db: any,email: string | undefined) => {
    console.log(db, email);

    // const emailArray = email.split('@');
    // return emailArray[0];
    if (!validateEmail(email)) {
        return;
    }

    const emailDoc = await getEmailDoc(db, email);
    if (emailDoc === null || emailDoc === undefined) {
        return null;
    }
    if (emailDoc.data()?.permanentEntityID !== undefined) {
        return emailDoc.data()?.permanentEntityID;
    }
    return emailDoc.data()?.tempEntityID;

}

export default getEntityIDfromEmail;