import React from "react";
import Donate from "../Sponsor/SupportPage";
import { Divider } from "antd";

const ReleaseNotes = () => {

    return <React.Fragment>
            <Donate />

            <Divider 
        orientation="left"
        plain
      >
        Whats New
      </Divider>
    </React.Fragment>
}

export default ReleaseNotes;