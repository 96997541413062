import React from 'react';
import { createRoot } from 'react-dom/client';
import AppStateProvider from './state';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './types';
import LoginPage from './components/AuthPages/LoginPage';
import SignupPage from './components/AuthPages/SignupPage';
import HomePage from './components/Home/HomePage';
import ChatPage from './components/Chat/ChatPage';
import ProtectedRoute from './ProtectedRoute';
import ForgotPage from './components/AuthPages/ForgotPage';
import LoadingPage from './components/Loading/LoadingPage';
import DonatePage from './components/Sponsor/SupportPage';
import ReleaseNotes from './components/Release/ReleaseNotes';
import EntitySignupPage from './components/EntitySignupPage/EntitySignupPage';
import ProtectedRouteEntityPage from './ProtectedRouteEntityPage';
import NotificationPage from './components/Notifications/NotificationPage';
import InsightPage from './components/Insights/InsightsPage';
import TiesPage from './components/TiesPage/TiesPage';
import { Layout } from 'antd';
import OnboardSider from './components/OnboardSider/OnboardSider';


createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Router>
    <AppStateProvider>
    <Layout style={{
      margin:0,
      padding:0,
      borderRadius: 8
    }}>
   <OnboardSider /> 
      <Layout>
      <Routes>
        <Route 
          path="/" 
          element={<ProtectedRoute><HomePage /></ProtectedRoute>}
        />
        <Route
            path="/loading"
            element={<ProtectedRoute><LoadingPage/>
            </ProtectedRoute>}
        />
        <Route 
            path="/signin" 
            element={<LoginPage />}
        />
        <Route 
            path="/tietogether/:entityID" 
            element={<EntitySignupPage />}
        />
        <Route 
          path="/sponsor"
          element={<ProtectedRoute><DonatePage /></ProtectedRoute>}
        />
        <Route
            path="/release"
            element={<ProtectedRoute><ReleaseNotes /></ProtectedRoute>}
        />
        <Route
            path="/notifications"
            element={<NotificationPage/>}
        />
        <Route
            path="/forgot"
            element={<ForgotPage />}
        />
        <Route 
            path="/signup" 
            element={<SignupPage />}
        />
        <Route
            path="/entity/:entityID/:view"
            element={<ProtectedRouteEntityPage />}
          />
        <Route
            path="/entity/:entityID"
            element={<ProtectedRouteEntityPage />}
          />
        <Route
            path="/chat/:connID"
            element={<ProtectedRoute> 
              <ChatPage />
            </ProtectedRoute>}
          />
        <Route
            path="/chat"
            element={<ProtectedRoute> 
              <ChatPage />
            </ProtectedRoute>
          }
        />
        <Route
            path="/insights"
            element={<ProtectedRoute> 
              <InsightPage />
            </ProtectedRoute>
          }
        />
        <Route
            path="/ties"
            element={<ProtectedRoute> 
              <TiesPage />
            </ProtectedRoute>
          }
        />
      </Routes>
      </Layout>
    </Layout>

    </AppStateProvider>

    </Router>
  </React.StrictMode>
);
