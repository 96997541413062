import React from "react";
import { Input, Button, Space, Select } from "antd";
import useSearchEntity from "../../hooks/search/useSearchEntity";
import { EntityInterface } from "../../types";
import EntitySummary from "../EntitySummary/EntitySummary";
import {  useNavigate } from "react-router-dom";
import ButtonGroup from "antd/es/button/button-group";
import { AppstoreOutlined, FilterOutlined } from "@ant-design/icons";
import InfoIconTooltip from "../Info/InfoIconTooltip";
// import { useAppState } from "../../state";
// import { httpsCallable } from "@firebase/functions";

const SearchEntity = (props: {
    setSearchOpen: any
}) => {
    const nav = useNavigate();
    // const { functions } = useAppState();
    
    const [qry, setQuery] = React.useState<string|undefined>();
    // const [paths, setPaths] = React.useState<any[]>();
    const result = useSearchEntity(qry);

    /*
    // https://us-central1-vellby-trio.cloudfunctions.net/ext-firestore-semantic-search-queryIndex 
    const search = httpsCallable(functions, `ext-firestore-semantic-search-queryIndex`);
    const callSearch = async (qry_input: string) => {
        try {
        const res:any = await search({query: [qry_input]});
        console.log("search result", res);
        setPaths(res.data);
        } catch (err) {
            console.log("error in search", err);
        }
      
       
    }
    useEffect(() => {

        // run search
        if (qry === undefined) return;

    }, [qry, search]);
    */

    return ( <React.Fragment>
        <Space direction="vertical" style={{
            width: '100%'
        }}>
        <Input 
            value={qry} 
            onChange={(e:any) => {
                setQuery(e.target.value)
            }
            }
            placeholder="Search for an entity"
        />
        <Space>        
            <Space style={{
                marginRight: 16
            }}>
                <AppstoreOutlined size={8} />
                <Select size="small" value={'seed entites'} disabled></Select>
            </Space>
            <Space style={{
                marginRight: 16
            }}>
                <FilterOutlined size={8} />
                <ButtonGroup size="small" >
                <Button disabled> entity </Button>
                <Button disabled> context </Button>
                <Button disabled> relationship </Button>
                <Button disabled> event </Button>
                </ButtonGroup>
            </Space>

            <Space style={{
                marginRight: 16
            }}>
                <InfoIconTooltip text="save your search as a context, so the platform can generate insights on that context"/>
                <Button disabled size="small" style={{
                    marginLeft: 'auto'
                }}> save as context </Button>
            </Space>
            
        </Space>


        </Space>
             {result?.map((entity: EntityInterface) => {
            return <EntitySummary entityID={entity.id} onClick={
                () => {
                    props.setSearchOpen(false);
                    nav(`/entity/${entity.id}`);

                }
            }/>
        })}
        </React.Fragment>
    );
};


export default SearchEntity;