import { collection} from 'firebase/firestore';
import { getConnectionDetails } from '../getDetails/useGetConnectionDetails';
import addNewDoc from '../firestoreFns/addNewDoc';

const addConnection = async (
    db:any, 
    sourceID:string | undefined, 
    userSourceAdmin: boolean | undefined,
    targetID:string | undefined,
    userTargetAdmin: boolean | undefined, 
    creatorID: string | undefined,
    isAdmin: boolean | undefined,
    ) : Promise<any> => {

    if (sourceID === undefined || targetID === undefined) {
      return;
    }

    if (userSourceAdmin === false && userTargetAdmin === false) {
      console.log('user is not admin of either entity');
      throw new Error('user is not admin of either entity');
    }

    const connectionExists = await getConnectionDetails(db, sourceID, targetID);
    if (connectionExists !== undefined) {
      console.log('connection already exists');
      return;
    }
    
    let newConnDoc = {
      entityIDs: [sourceID, targetID],
      [sourceID]: userSourceAdmin,
      [targetID]: userTargetAdmin,
      creatorID: creatorID,
      createdAt: new Date(),
      createdOnBehalfID: userSourceAdmin ? sourceID : targetID,
      admin: isAdmin,
      hide: false
    }
    
    const docRef = await addNewDoc(collection(db, 'connections'), newConnDoc);
    return docRef;


  };

  export default addConnection;