import React, { useEffect, useState } from "react";
import { useAppState } from "../../state";
import unlink  from "../../hooks/buildFns/deleteConnection";
import useGetConnectionDetailsFromId  from "../../hooks/getDetails/useGetConnectionDetailsFromId"
import { getIsAdmin } from "../../hooks/getDetails/useGetIsAdmin";
import ModalConfirm from "./ModalConfirm";
import { doc } from "firebase/firestore";
import removeStringFromArrayField from "../../hooks/firestoreFns/removeStringFromArrayField";
import addStringToArrayField from "../../hooks/firestoreFns/addStringToArrayField";


const SeparateButton = (props : {
    connectionID: string,
    otherID: string | undefined,
    yourID: string | undefined,
}) =>{  

    const {db, userEntity} = useAppState();
    const userID = userEntity?.id;
    const {connectionID, otherID, yourID} = props;

    const connectionDetails = useGetConnectionDetailsFromId(connectionID);
    const [theyArePending, setTheyArePending] = useState<Boolean>(true);

    // REFACTOR : you are pending needs to go insise useGetConnectionDetailsFromId
    // source is pending.
    useEffect(() => {

        // console.log("connectionDetails theyArePending", connectionDetails)
        if(connectionDetails && otherID){

            //console.log("otherID theyArePending", otherID);
            // console.log("theyArePending", connectionDetails?.[otherID])

            //@ts-ignore
            setTheyArePending(connectionDetails?.[otherID] === false)

            //@ts-ignore
            // console.log("setting you are pending to ", connectionDetails?.[otherID] === false);
            
        } else
        {
            setTheyArePending(true);
        }

    }, [connectionDetails?.id, connectionDetails, connectionID, otherID, yourID])

    // const [isAdmin , setIsAdmin ] = useState<Boolean>(yourID === userEntity?.id);

    if (yourID !== userEntity?.id) {

        getIsAdmin(db, userEntity?.id, yourID).then( (value) => {
            // setIsAdmin(value)
        });

    }
    const subject = yourID === userEntity?.id ? "yourself" : "";
    // console.log('isAdmin is disabled.', isAdmin)

    /*
    if (!isAdmin) {

        return <></>

    } 
    */


    // console.log("theyArePending", theyArePending);

    return  <ModalConfirm 
            key={connectionID}
            ButtonContent={ theyArePending ? "cancel request" : `separate ${subject}`  }
            ModalContent={ theyArePending ? "You are about to cancel your request to tie with this entity." : "You cannot undo this separate action. You will have to re-tie and an admin of this entity should grant you access again."}
            onConfirm={() => {
                unlink(db, connectionID)
                // console.log("cancel request")
                if (userID){
                    console.log("yourID", yourID)
                    let docRef = doc(db, 'userState', userID);
                    removeStringFromArrayField(docRef, 'onboardedSteps', 'request');
                    addStringToArrayField(docRef, 'onboardedSteps', 'request');
        
                }
            
            }}
        />
}

export default SeparateButton;