import React from "react";
import { Tabs } from "antd"

const onChange = (key: string) => {
  console.log(key);
};


const tabChildren = {
    "meeting_challenges": <img 
    width={280}
    src={"/images/meeting_challenges.png"} 
    alt="characterestics"
    />,
    "community_supported": <img 
    width={280}
    src={"/images/community_supported.png"} 
    alt="community supported"
    />,
    "ecologies": <img
    width={280}
    src={"/images/ecologies.png"}
    alt="ecologies"
    />,
}



const Communities = () => {

  return  <>
    <Tabs
    onChange={onChange}
    tabPosition='left'
    items={[
      {"label" : "caring communties", "key": "vitality", "children": tabChildren["meeting_challenges"]},
      {"label" : "ecologies and emergences", "key": "ecologies", "children": tabChildren["ecologies"]},
      {"label" : "community sponsored", "key": "admin", "children": tabChildren["community_supported"]},
    ]
    }>
</Tabs>

  </>
};

export default Communities;