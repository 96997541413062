import React from "react";
import {List} from "antd";
import {useNavigate} from 'react-router-dom';
import EntitySummary from "../EntitySummary/EntitySummary";
import useGetEntityConnections from "../../hooks/getConnections/useGetEntityConnections";
import {useAppState} from '../../state';
import {DirectedConnectionInterface} from '../../types';

const ConnectionList = (props: { selectedID : string | undefined}) => {
    const { selectedID } = props;
    const { userEntity } = useAppState();
    const nav = useNavigate();
    const allConnections = useGetEntityConnections(userEntity?.id, {
      adminOnly: true,
      removePending: true,
      entityTypes: ['relationship', 'context'],
      removeHidden: false
    } );
    
    return <List size='small'>
    { 
    allConnections &&
      allConnections.map((conn: DirectedConnectionInterface) => {
        return (
          <List.Item
           style={{ 
            cursor: 'pointer',
            backgroundColor: selectedID === conn.otherEntityID ? '#e6f7ff' : 'white',
            padding: '0.5rem',
            margin: '0.5rem', 
          }} 
            key={conn.otherEntityID}

           onClick={() => {
            nav(`/chat/${conn.otherEntityID}`);
          }} >
            
            <EntitySummary
              entityID={conn.otherEntityID}
              lean
              onClick={() => {
                nav(`/chat/${conn.otherEntityID}`);
              }}
            />
            
          </List.Item>
        );
      })
    }
  </List>
}

export default ConnectionList;