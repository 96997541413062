import { onSnapshot } from "firebase/firestore";

const unsubscribeQuerySnapshot = (query:any, setData:any) => {

    const unsub = onSnapshot(query, (data:any) => {
        setData(data.docs.map((doc:any) => {
            return { 
                id: doc.id,
                ...doc.data()
            }
        }));
    });
    
    return unsub;

}

export default unsubscribeQuerySnapshot;