import React from "react";
import { Avatar, Segmented, Space, Card, Modal } from 'antd';
import { EntityType } from "../../types";
import EntitySummary from "../EntitySummary/EntitySummary";
import EntityChip from "../EntitySummary/EntityChip/EntityChip";

const description = {
    "relationship": {
        "title": "Relationship",
        "description": <img 
        width={280}
        src={"/images/relationship_rules.png"} 
        alt="Relationship Rules"
        />
    },        
    "relationship2": {
        "title": "Relationship",
        "description": "We all have our relationships in our life. Our parents, frineds, family. More importantly we have relationships with ourselves. can be a friendship, a romantic relationship, a family relationship, or a professional relationship. It can also be a connection between two things, such as a relationship between a person and a place, or a relationship between a person and an idea.",
        "anti_description": "A relationship is a connection between two people. It can be a friendship, a romantic relationship, a family relationship, or a professional relationship. It can also be a connection between two things, such as a relationship between a person and a place, or a relationship between a person and an idea"
    },
    "context": {
        "title": "Context",
        "description": <img
        width={280}
        src={"/images/context_rules.png"} 
        alt="Context Rules"
        />
    },
    "context2": {
        "title": "Context",
        "description": "Context is like the weather that changes that changes the how things in an environment. It can be a physical place, a time, or a state of mind. It can also be a situation or environment in which something happens, such as a relationship between a person and a place, or a relationship between a person and an idea.",
        "anti_description": "A context is a situation or environment in which something happens. It can be a physical place, a time, or a state of mind. It can also be a situation or environment in which something happens, such as a relationship between a person and a place, or a relationship between a person and an idea."
    },
    "person": {
        "title": "Person",
        "description": <img
        width={280}
        src={"/images/person_rules.png"} 
        alt="Person Rules"
        />
    },
    "person2": {
        "title": "Person",
        "description": "A person is an entity that represents a human. A person can have a relationship and exist in a contact. ",
        "anti_description": "A person is a human being. It can be a friend, a family member, a colleague, or a stranger. It can also be a human being, such as a friend, a family member, a colleague, or a stranger."
    },
    "thing": {
        "title": "Thing",
        "description": <img
        width={280}
        src={"/images/thing_rules.png"} 
        alt="Thing Rules"
        />
    },
    "thing2": {
        "title": "Thing",
        "description": "A thing is an object or idea. It can be a physical object, such as a book, a chair, or a computer. It can also be an idea, such as a concept, a belief, or a value.",
        "anti_description": "A thing is an object or idea. It can be a physical object, such as a book, a chair, or a computer. It can also be an idea, such as a concept, a belief, or a value."
    }
}

export const RulesCard =  (
    props: {
        entityType: EntityType | undefined,
        showSelector?: boolean
    }
) =>{

    const [selected, setSelected] = React.useState<EntityType>("relationship");
    React.useEffect(() => {
        if (props.entityType){
            setSelected(props.entityType);
        }
    }, [props.entityType])

    return  <Card 
    bordered={false}
    style={{
        display: "flex"
    }}>
        <Space direction="vertical">
           {
           props.showSelector && <Segmented
                options={[
                    {
                        label: (
                            <div style={{
                                padding: 4
                            }}>
                                <Avatar 
                                    size={'small'} 
                                    src="/images/relationship.png" 
                                 />

                            <div> relationship </div>
                            </div>
                        ),
                        value: 'relationship',
                    },
                    {
                        label: (
                            <div style={{ padding: 4 }}>
                            <Avatar size={'small'} src="/images/context.png" />
                            <div>context</div>
                            </div>
                        ),
                        value: 'context',
                    },
                    {
                        label: (
                            <div style={{ padding: 4 }}>
                                <Avatar size={'small'} src="/images/person.png"   />
                            <div>person</div>
                            </div>
                        ),
                        value: 'person',
                    },
                    {
                        label: (
                            <div style={{ padding: 4 }}>
                                <Avatar size={'small'} src="/images/thing.png" />
                            <div>thing</div>
                            </div>
                        ),
                        value: 'thing',
                    }
                ]}
                defaultValue={selected}
                onChange={(value: any) => {
                    console.log('value', value);
                    setSelected(value);
                }}
            />
            }
        
        { description[selected]["description"] }
        </Space>
  </Card>
}

const RulesModal = (props: {
    open: boolean, 
    onClose: any,
    entityType: EntityType | undefined
    entityID: string | undefined
}) => {

    const {open, onClose, entityID, entityType} = props;
    return <Modal open={open} footer={null} onCancel={onClose} closable={false}>
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <EntitySummary 
                entityID={entityID} 
                onlyName 
            /> is of type {entityType}
            <EntityChip 
                entityType={entityType}
                onClick={() => {}}
            />
        </div>
        <RulesCard entityType={entityType} />
    </Modal>
}

export default RulesModal;
