import React from "react";
import useGetEntityConnections from "../../hooks/getConnections/useGetEntityConnections";
import useGetEntityDetails from "../../hooks/getDetails/useGetEntityDetails";
import { createGraph, } from "./setGraph";
import addNewConnections from "./addNewConnections";

import { useAppState } from "../../state";
import { Badge, Button, Space } from "antd";
import EntitySummary from "../EntitySummary/EntitySummary";
import { ColorExp } from './settings';
import { FilterConnection } from "../../types";
import {  
  MinusCircleOutlined, PlusCircleOutlined
} from "@ant-design/icons";
import InfoIconTooltip from "../Info/InfoIconTooltip";
import YellowMessage from "../Info/YellowMessage";
import ModalEntitySummary from "../EntitySummary/ModalEntitySummary";
import HelpButton from "../Concepts/HelpButton";


const randomString = (length: number) => {  
  let result:string = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const Graph = (props: { 
  entityID: string, 
  filter: FilterConnection,
  setEntityID: (entityID: string) => void,
  }) => {

  const { entityID, filter } = props;
  const { db } = useAppState();
  const graphID = randomString(10);

  const entityDetails = useGetEntityDetails(entityID);
  const [msg, setMsg] = React.useState<string>('');
  const [selecetedNode, setSelectedNode] = React.useState<string|undefined>(undefined);
  const [expandOnNode, setOnExpandNode] = React.useState<string|undefined>(entityID ? entityID : undefined);

  // const expandedConnections = useGetEntityConnections(expandOnNode, filter);
  const firstDegreeConnections = useGetEntityConnections(expandOnNode, filter);

  const [gph, setGph ] = React.useState<any>(null);
  
  const addConnectionToGraph = (conns:any ) => {
    // console.log('renderGraph', conns);
    setMsg('updating ' + conns?.length + ' ties in the graph');

    if (gph){
      console.log("allConnections", conns, entityDetails?.id);

      if (conns?.length ) {
        console.log("calling addNewConnections")
        addNewConnections(gph, db, conns);

        let members: string[] = []

        firstDegreeConnections?.forEach((conn :any) => {
          members.push(conn.otherEntityID);
        })

        if (entityID){
          members.push(props.entityID);
        } 
        // addHull(gph, members);
        
        setTimeout(() => {
          if (gph){
            gph.updateLayout();
          }
        }, 500); 

      }
    }
  }

  const refreshGraph = () => {
    if (entityID && entityDetails?.entityType){

      if (gph)  {
        console.log("destroying graph")
        setMsg('refreshing graph');
        gph.destroy();
        setGph(null);
        setSelectedNode(undefined);
        setOnExpandNode(undefined);
      }

      console.log("adding center node.", entityID);
      createGraph(graphID, entityID, entityDetails?.name, entityDetails?.entityType, entityDetails?.photoURL, setSelectedNode, setGph);
      // setSelectedNode(entityID);
      setOnExpandNode(entityID);
      
    }
  }

  React.useEffect(() => {

    console.log("useEffect entityID", entityID);
    setMsg('emptying connections and refresing graph.');
    // setAllconnections([]);
    refreshGraph();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityID, entityDetails?.entityType, entityDetails?.name ]);
  
  React.useEffect(() => {

    console.log("useEffect firstDegreeConnections", firstDegreeConnections);
    // message.info("adding first degree conns for : " +  expandOnNode)
    
    if (firstDegreeConnections?.length){
      // console.log("adding new connections" + firstDegreeConnections[0]);
      // setAllconnections(a => [...a, ...firstDegreeConnections]);
      addConnectionToGraph(firstDegreeConnections);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDegreeConnections]);

  React.useEffect(() => {
    console.log("useEffect first degree and entity ID ");

    addConnectionToGraph(firstDegreeConnections);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDegreeConnections, entityID, gph]);

  //
  return <React.Fragment>
    <div 
    style={{
      marginLeft: 32,
      display: 'flex',
      flexDirection: 'row'
    }}>
      <div style={{
        margin: 8,
        display: 'flex',
        flexDirection: 'row'
      }}>
      <InfoIconTooltip text={"shows on which entity the graph was first expanded from."}/>
        centered on 
      <EntitySummary entityID={entityID} onlyName/>
      </div>

      <div style={{
        marginLeft: 'auto'
      }}>
         { Object.keys(ColorExp).map(
            (key: string) =>  
            <Badge key={key} color={ColorExp[key]} text={key} style={{margin: 4}} />
          )
        }
      </div>
    </div> 
    <div style={{
        marginLeft: 32
      }}>
      <YellowMessage text={ msg } />



      <ModalEntitySummary 
        entityID={selecetedNode} 
        setMsg={setMsg} 
        onExpand={() => {
          if (selecetedNode === entityID){
            setMsg('center node is already expanded');
          }
          console.log(selecetedNode);
          console.log(firstDegreeConnections);
          setOnExpandNode(selecetedNode);
        }}/>

      </div>

      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}>

      <Space direction="vertical" 

      style={{
        marginTop: 48,
      }}>
      <InfoIconTooltip text={'control abstraction levels (logical typings)'}/>
        <Button type='text' size='small' icon={<PlusCircleOutlined/>} 
        onClick={
          () => {
            setMsg('controlling logical types is not yet implemented');
          }
        } 
        style={{
          marginLeft: 2
        }}/>
        <Button type='text' size='small' icon={<MinusCircleOutlined/>} 
         style={{
          marginLeft: 2
        }}
        onClick={
          () => {
            setMsg('controlling logical types is not yet implemented');
          }
        } 
        />
      <HelpButton slim helpKey="logical_types" />

      </Space>
      <div 
        id={graphID}
        key={entityID}
    />
      </div>

  </React.Fragment>

};
export default Graph;