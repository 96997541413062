import React from "react";
import { Image, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import useGetEntityDetails from "../../hooks/getDetails/useGetEntityDetails";
import EntityChip from "./EntityChip/EntityChip";

const { Text, Link } = Typography;

const EntitySummary = (props: { 
  entityID: string | undefined; 
  onClick?: any,
  lean?: boolean,
  onlyName?: boolean,
  extended?: boolean 
}) => {
    const { entityID, onClick, lean, onlyName, extended } = props;

    const nav = useNavigate();
    
    const entityDetails = useGetEntityDetails(entityID);
    if (!entityID) {
        return <></>
    }
    if (entityDetails === undefined) {
        return <></>
    }
    // console.log('entityDetails : ', entityID, entityDetails); 

    if(onlyName) return ( <div onClick={onClick ? onClick : () => nav(`/entity/${entityID}`)} > 
        <Text keyboard> {entityDetails?.name} </Text> 
    </div>
    )

    if (lean) {
      return <div style={{
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer'
      }} onClick={( onClick ) ?  onClick : () => nav(`/entity/${entityID}`) }> 
        <Image 
          width={32} 
          height={32}
          src={entityDetails?.photoURL} 
          style={{borderRadius:8, margin: 8}}
          preview={false}
        /> <Text style={{margin: 12}}> {entityDetails?.name}  </Text>
        <div style={{marginTop: 12}}>
        <EntityChip 
          entityType={entityDetails?.entityType}
          onClick={onClick ? onClick : () => nav(`/entity/${entityID}`)}
        />
        </div>

        </div>

    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 8
        }}
      >
        

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
          >

          <div 
            style={{
              borderRadius: 8,
              margin: 16,
              cursor: 'pointer'
            }}
            onClick={onClick ? onClick : () => nav(`/entity/${entityID}`)}
            >
              <Image 
                  width={64} 
                  height={64}
                  src={entityDetails?.photoURL} 
                  style={{borderRadius:8}}
                  preview={false}
              />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              cursor: 'pointer',
              margin: 16
            }}
          >

            <div style={{               display: 'flex',
 flexDirection: 'row'}} onClick={onClick ? onClick : () => nav(`/entity/${entityID}`)} > 
            <Text> {entityDetails?.name} </Text>         
            <EntityChip 
              entityType={entityDetails?.entityType}
              onClick={onClick ? onClick : () => nav(`/entity/${entityID}`)}
            />
            </div>
                <div onClick={onClick ? onClick : () => nav(`/entity/${entityID}`)} >
                  <Text type="secondary"> 
                    {entityDetails?.description}  
                  </Text> 
                </div>
         
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}>
                  {entityDetails?.searchKeywords?.map(
                    (keyword: string, index: number) => (
                      <Text key={index} code>
                        {" "}
                        {keyword}
                        {" "}
                      </Text>
                  )) 
                  }
                </div>
             </div>  


          </div>
          { extended ? <div style={{
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                margin: 16,
                marginTop: 8,
                marginBottom: 8,
              }}>
                <div onClick={onClick ? onClick : () => nav(`/entity/${entityID}`)} >
                  <Link type="secondary" onClick={ () => {
                    if(entityDetails?.webLink) {
                      window.open(entityDetails?.webLink, "_blank")
                    }
                  }}>
                    {entityDetails?.webLink}
                  </Link>
                </div>
                <div onClick={onClick ? onClick : () => nav(`/entity/${entityID}`)} >
                 <Text type={"secondary"} code>
                    {entityDetails?.hide ? "hidden" : "visible"}
                  </Text> 
                </div>
              </div> : <></>

             } 
      </div>

    );
  };
  
  export default EntitySummary;
